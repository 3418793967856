import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchStorys } from '../../../store/about/story';
import { useEffect } from 'react';
import LazyLoad from 'react-lazy-load';


const SecDescription = () => {

  const dispatch = useDispatch();
  const { storylist } = useSelector(state => state.storys);

  useEffect(() => {
    dispatch(fetchStorys({ status:true, keyword: "catering" }))

  }, [dispatch]);

  //console.log(storylist);

  return (
    <section className='description-section'>
    <div className='container'>
      <div className='row justify-content-between'>
        <div className='col-lg-6 order-md-1 order-2 order-sm-1'>
          <div className='description-content'>
            <div className='left-section-header'>
              <h2 className='left-section-title'>{storylist[0]?.title} <span>{storylist[0]?.subtitle}</span></h2>
            </div>
            <div className='content'>
              <div className='description'>
              <div dangerouslySetInnerHTML={{__html: storylist[0]?.description}} />
                {/* <p>Nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
              <div className='description'>
                <p>Nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-5 order-md-2 order-1 order-sm-2'>
          <div className='description-img'>
            {storylist[0]?.image && <LazyLoad>
              <img src={storylist[0]?.image} alt='Logo'/>
            </LazyLoad>}
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default SecDescription;