import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchBlogs } from "../../../store/blog/blog";
import moment from "moment/moment";

const BlogList = ({ limit }) => {
  const dispatch = useDispatch();
  const { bloglist } = useSelector((state) => state.blogs);

  useEffect(() => {
    dispatch(
      fetchBlogs({
        status: true,
        limit,
        sortByField: "ordering",
        sortByValue: 1,
      })
    );
  }, [dispatch, limit]);

  return (
    <>
      <div className="blog-lists">
        {bloglist.length > 0 ?
          bloglist.map((item, index) => {
            function createMarkup() {
              return { __html: item?.title };
            }

            const createdAtTime = item
              ? new Date(item.created_at).toLocaleString("en-US", {
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })
              : "";

            return (
              <Link
                to={`/blog-details/${item.alias}`}
                className="blog-item"
                key={item._id}
              >
                <div className="blog-image">
                  <img src={item?.image} alt="" />
                </div>
                <div className="blog-info">
                  <div className="blog-data">
                    <div className="date">
                      <div className="icon">
                        <i className="fa-regular fa-calendar"></i>
                      </div>
                      <div className="text">
                        {moment(new Date(createdAtTime)).format("DD MMM YYYY")}
                      </div>
                    </div>
                    <div className="author">
                      <div className="icon">
                        <i className="fa-regular fa-circle-user"></i>
                      </div>
                      <div className="text">
                        By {item?.created_by_name.name}
                      </div>
                    </div>
                    <div className="tag">
                      <div className="tag-text">
                        {item.category?.name || ""}
                      </div>
                    </div>
                  </div>
                  <div className="blog-details">
                    <h3 className="blog-text">
                      <div dangerouslySetInnerHTML={createMarkup()} />
                    </h3>
                  </div>
                </div>
              </Link>
              // </div>
            );
          }) : 
          <div className="section h3">No Data</div>
          }
      </div>
    </>
  );
};

export default BlogList;
