// import React, { useEffect, useState } from 'react';
import { useState, useEffect, useContext } from 'react';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import BASE_URL from '../../../config/host';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { updateCartList } from "../../../store/cartlist/cartlist";
import { useNavigate } from 'react-router-dom';
import { updateApplyCuopon } from '../../../store/apply-cuopon/applyCuopon';
import { SocketContext } from "../../../socket/socketio";


const DeliveryPickup = ({getData}) => {
  const socket = useContext(SocketContext);
  const history = useNavigate();
  const dispatch = useDispatch();

  const { appliedCuoponCode } = useSelector((state) => state.getUpdateApplyCuopon);

  const { cartlistCount } = useSelector((state) => state.cartList);
  if(cartlistCount < 1){
    history('/cart');
  }
  
  const [prickUpTimeDataset, setPrickUpTimeDataset] = useState([]);
  useEffect(() => {
      axios.post(`${BASE_URL}take-of-time/list`,{status:true, limit:9999999, sortByField:"ordering", sortByValue : 1})
      .then(function (response) {
        if(response.data.status === 200 || response.data.status === 201) {
          setPrickUpTimeDataset(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },[]);


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [house_no, setHouse_no] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [residence, setResidence] = useState('');
  const [kitchenNote, setKitchenNote] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [pickupTimeDisplay, setPickupTimeDisplay] = useState('');
  const [defaultAddress, setDefaultAddress] = useState(false);


  const delevaryTypeOption = sessionStorage.getItem("delevaryTypeOption");
  const sessionCartId = localStorage.getItem('sessionCartId');

  const [isCreating, setCreating] = useState(false)

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  }

  useEffect(() => {
    setName(getData?.userProfileData?.name ? getData?.userProfileData?.name : '')
    setEmail(getData?.userProfileData?.email ? getData?.userProfileData?.email : '')
    setPhone(getData?.userProfileData?.phone ? getData?.userProfileData?.phone : '')
    setAddress(getData?.addressData?.address ? getData?.addressData?.address : '')
    setHouse_no(getData?.addressData?.house_no ? getData?.addressData?.house_no : '')
    setZipcode(getData?.addressData?.zipcode ? getData?.addressData?.zipcode : '')
  },[getData]);


  const handlePaymenyMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleDefaultAddress = (event) => {
    setDefaultAddress((current) => !current);
  };

  const handleprickUpTime = (e) => {
    const defaultDate = new Date().toDateString();
    const IsoDateTime = new Date(`${defaultDate} ${e}`).toISOString()
    setPickupTime(IsoDateTime);
    setPickupTimeDisplay(e)
    document.getElementById("dispalyTime").innerHTML = e;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);
    const data = {
          cart_id : sessionCartId,
          order_type : Number(delevaryTypeOption),
          name : name,
          email:email,
          phone : phone,
          address_id : getData?.addressData?._id,
          address: address,
          house_no: house_no,
          zipcode: zipcode,
          residence : residence,
          kitchenNote : kitchenNote,
          payment_method_id : paymentMethod,
          pickup_time : pickupTime,
          is_daufelt : defaultAddress,
          coupon_code : appliedCuoponCode,
    }

    axios
      .post(`${BASE_URL}checkout/complete`, {
        ...data
      }, {headers: headers})
      .then(function (response) {
        console.log(response);

        if (response.data.status === 400) {
           toast.error(response.data.message, { theme: "colored" });
           setCreating(false);
        }
        if (response.data.status === 200 || response.data.status === 201) {

          socket.emit('orderNotification', {
            _id: response.data.data._id,
            order_id: response.data.data.order_id,
          });

          toast.success(response.data.message, { theme: "colored" });
          setCreating(false);
          const tempCartData = {
            data : [],
            count : 0,
          }
          dispatch(updateCartList(tempCartData));
          const tempData = {
            cuoponDiscountAmount : 0,
            appliedCuoponCode : '',
            isAppliedCuopon : false,
          }
          dispatch(updateApplyCuopon(tempData));
          console.log(response.data.data.payment,typeof response.data.data.payment );
          setTimeout(() => {
            if(response.data.data.payment === null) {
              window.location.href = `/order-details/${response.data.data._id}`;
            } else {
              window.location.href = response.data.data.payment._links.checkout.href;
            }
          },'2000')
        }else{
          setCreating(false);
        }
      })
      .catch(function (error) {
        toast.error(error.message, { theme: "colored" });
      });
  };

  let currentTime = [];
  var nowTime = new Date();
  currentTime = [nowTime.getHours(),':', nowTime.getMinutes()].join('');

  return (
    <>
      <ToastContainer theme="colored" />
      <div className='inner-option-view'>
        <form className='form-content' onSubmit={handleSubmit}>
          <div className='form-row'>
            <div className='col-md-12'>
              <div className="input-style">
                <div className='form-label'>Name*</div>
                <input type="text" className="form-control" placeholder='Enter Your Name' name="name" value={name}  onChange={e => setName(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="input-style">
            <div className='form-label'>E-mail*</div>
            <input type="email" className="form-control" placeholder='Enter Your Email' name="email" value={email}  onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="input-style">
            <div className='form-label'>Phone*</div>
            <input type="number" className="form-control" placeholder='Enter Your Phone' name="phone" value={phone}  onChange={e => setPhone(e.target.value)} />
          </div>

          <div className='form-inner-title'>To take off</div>
          <div className="input-style">
            <div className='form-label'>Time*</div>
            <div className="dropdown time-dropdown">
              <button className="dropdown-toggle" type="button" data-toggle="dropdown" id="dispalyTime">{pickupTimeDisplay ? pickupTimeDisplay : 'Time'}</button>
              <div className="dropdown-menu">
                <div className="dropdown-time">
                  {/* <input type="text" className="form-control" placeholder="Search" /> */}
                </div>
                <div className="dropdown-scroll" id="time-category">
                  {
                    prickUpTimeDataset && prickUpTimeDataset.map((item, i)=>{
                      if(currentTime < item?.time){
                        return(
                          <button type="button" className="dropdown-item" onClick={(e)=>handleprickUpTime(item?.time)}>{item?.time}</button>
                        )
                      }
                    })
                  }
                </div>
              </div>
            </div>
          </div>


          {/* <div className='form-inner-title'>Your Address</div>
          <div className='form-row'>
            <div className='col-md-6'>
              <div className="input-style"> 
                <div className='form-label'>Postal Code</div>
                <input type="text" className="form-control" name="zipcode" placeholder='Enter Postal Code' value={zipcode}  onChange={e => setZipcode(e.target.value)} />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="input-style">
                <div className='form-label'>House number</div>
                <input type="text" className="form-control" name="house_no" placeholder='Enter House number' value={house_no}  onChange={e => setHouse_no(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="input-style">
            <div className='form-label'>Street</div>
            <input type="text" className="form-control" name="address" placeholder='Enter Street' value={address}  onChange={e => setAddress(e.target.value)} />
          </div>
          <div className="input-style">
            <div className='form-label'>Residence</div>
            <input type="text" className="form-control" name="residence" placeholder='Enter Residence' value={residence}  onChange={e => setResidence(e.target.value)} />
          </div>
          <div className='shipping-item'>
            <div className="custom-checkbox">
              <input 
              type="checkbox" 
              className="custom-checkbox-input" 
              name="address"
              onChange={handleDefaultAddress}  
              />
              <label className="custom-checkbox-label">
                <div className='text'>Save my (address) details for your next order.</div></label>
            </div>
          </div> */}

          <div className='form-inner-title'>Payment</div>
          <div className="input-style">
            <div className='form-label'>Payment Method*</div>

            {getData?.paymentMethodData && getData?.paymentMethodData.map((item, i)=> (
              <div className='shipping-item' key={item?._id}>
                <div className="custom-radio">
                  <input 
                  type="radio" 
                  className="custom-radio-input" 
                  name="address" 
                  value={item?._id}
                  onChange={handlePaymenyMethodChange} 
                  />
                  <label className="custom-radio-label">
                    <div className='text'>{item?.title}</div>
                    <div className="icon"><i className={item?.icon}></i></div>
                  </label>
                </div>
              </div>
            ))}



          </div>

          <div className="input-style">
            <div className='form-label'>Notes for Kitchen</div>
            <textarea type="text" rows="12" className="form-control Comments" placeholder="Notes for Kitchen" onChange={e => setKitchenNote(e.target.value)}></textarea>
          </div>
          <div className='form-submit'>
            <button 
            type='submit' 
            className='btn-submit' 
            // data-toggle="modal" 
            // data-target="#orderModal" 
            disabled = {isCreating}
            >
            {isCreating && (
              <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
            )}
            Place an order
            </button>
          </div>
        </form>
        <div className='delivery-image'>
          <img src='assets/img/man-with-card.svg' alt='' />
        </div>
      </div>
    </>
  );
};

export default DeliveryPickup;
