import React from 'react';

const ThanksOrder = () => {
    return (
        <>
            <div className="modal modal-thanks-order fade" id="orderModal" data-backdrop="static" data-keyboard="false" >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5 className="modal-title">New Address</h5>
          </div> */}
          <div className="modal-body">
          <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-6'>
                    <div className='thanks-img'>
                        <img src='assets/img/man-with-card-2.svg' alt='' />
                    </div>
                    </div>
                    <div className='col-lg-6'>
                    <div className='thanks-content'>
                        <div className='thanks-title'>Thanks For <span>Order</span></div>
                        <div className='thanks-text'>Subscribe to our newsletter and get upto 40% off on our Desi Food. </div>
                            <div className="search">
                                <form className="search-input">
                                    <input className="form-control" type="search" placeholder="Email Address" name="keyword" value="" />
                                    <button className="btn-search" type="submit">SUBSCRIBE</button>
                                </form>
                            </div>
                            <button type='button' className='skip-btn'>
                                    No, I will skip this time.
                            </button>
                            <div className='media-title'>Trusted by Top Leaders and Organisations</div>
                            <div className='media-list'>
                                <div className='media-icon'><img src='assets/img/google.svg' alt='' /></div>
                                <div className='media-icon'><img src='assets/img/facebook-2.svg' alt='' /></div>
                                <div className='media-icon'><img src='assets/img/microsoft.svg' alt='' /></div>
                                <div className='media-icon'><img src='assets/img/apple-2.svg' alt='' /></div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
          <button type='button' className='cancel-btn' data-dismiss="modal" aria-label="close"><i className="fa-light fa-xmark"></i></button>
          </div>
        </div>
      </div>
        </div>
        </>
    );
};

export default ThanksOrder;