import React, { useState, useEffect } from 'react';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BASE_URL from '../../../config/host';
import axios from 'axios';
import { convertToBase64 } from "../../../helpers/image_helper";
import EditAddress from './EditAddress';
import { useNavigate } from 'react-router-dom';

const ProfileDetails = () => {
  const navigate = useNavigate();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  }

const [editAddewssData, setEditAddewssData] = useState(null);
const [info, setData] = useState();
const [profileData, setProfileData] = useState();
const [image, setBannerImage1] = useState();
const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
const [isRequestSending, setRequestSending] = useState(false)
// const [fname, setFname] = useState('');
// const [lname, setLname] = useState('');
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
const [currentPassword, setCurrentPassword] = useState('');
const [newPassword, setNewPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
  
  useEffect(() => {
    axios.post(`${BASE_URL}user-address/list`,{ },{headers: headers} )
    .then(function (response) {
    setData(response.data.data);
  })
  .catch(function (error) {
    console.log(error);
  });

  axios.post(`${BASE_URL}profile-details`,{  },{headers: headers} )
    .then(function (response) {
    setProfileData(response.data.data);
  })
  .catch(function (error) {
    console.log(error);
  });
  
}, [])


useEffect(() => {
  // setFname(profileData?.first_name)
  // setFname(profileData?.first_name)
  setName(profileData?.name)
  setEmail(profileData?.email)
  setPhone(profileData?.phone)
},[profileData]);


function handleSubmit(e) {
  e.preventDefault();
  setRequestSending(true);
  const data = {
    // first_name: fname,
    // last_name: lname,
    name: name,
    //email : email,
    phone : phone,
    current_password : currentPassword,
    new_password : newPassword,
    confirm_password : confirmPassword,
    image:image?image:null,
  }

  axios
  .post(BASE_URL + "profile-update", {...data},{headers: headers})
  .then(function (response) {
    if (response.data.status === 400) {
      toast.error(response.data.message, { theme: "colored" });
    }

    if (response.data.status === 200) {
      toast.success(response.data.message, { theme: "colored" });
      setRequestSending(false)
      // setTimeout(() => {
      //   window.location.reload();
      // },'3000')
    }else{
      setRequestSending(false)
    }
  })
  .catch(function (error) {
    toast.error(error.message, { theme: "colored" });
    setRequestSending(false)
  });
}

const onImgChange = (e) => {
  if (e.target.files[0]) {
    const ext = e.target.files[0].type.split("/")[1];

    if (![...allowedExts].includes(ext)) {
      console.log("Please upload a valid image");
      toast("Please upload a valid image.");
    } else {
      var output = document.getElementById("imageOutput");
      output.src = URL.createObjectURL(e.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src); // free memory
      };
      convertToBase64(e.target.files[0], setBannerImage1);
    }
  }
};


const openEditModal = (data) => {
  setEditAddewssData(data)

}

const handleChange = (e, id, index1) => {
  setData((data) =>
    data?.map((list, index) =>
      index === index1 ? { ...list, is_default: true } : { ...list, is_default: false }
    )
  );

  axios
  .post(BASE_URL + "user-address/change-default", {id:id},{headers: headers})
  .then(function (response) {
    if (response.data.status === 400) {
      toast.error(response.data.message, { theme: "colored" });
    }

    if (response.data.status === 200) {
      toast.success(response.data.message, { theme: "colored" });
    }
  })
  .catch(function (error) {
    toast.error(error.message, { theme: "colored" });
  });
};

console.log(profileData);


  return (
    <>
      <ToastContainer theme="colored" />
      <section className='profile-details-section'>
        <div className='container'>

          <div className='row'>
            <div className='col-md-9'>
              <div className='section-header'>
                <h2 className='section-title'>Profile <span>Details</span></h2>
              </div>
              <div className='profile-form'>
                <div className='profile-image-edit'>
                  <input name="image" type="file" id="file-input" className="upload-file" onChange={onImgChange} />
                  <label className="file-input-label" htmlFor="file-input">
                    <div className='no-image'>
                      <img id="imageOutput" src={profileData?.image} alt='dp' />
                    </div>
                    <div className='icon'>
                      <i className="fa-light fa-pencil"></i>
                    </div>
                  </label>
                </div>
                <div className='profile-edit'>
                  <form 
                    className='form-content'
                    autoComplete="off"
                    onSubmit={e => handleSubmit(e)}

                  >
                    <div className='form-row'>
                      <div className='col-md-12'>
                        <div className="input-style">
                          <div className='form-label'>Name*</div>
                          <input type="text" className="form-control" placeholder="Name" value={name}  onChange={e => setName(e.target.value)} />
                        </div>
                      </div>
                      {/* <div className='col-md-6'>
                        <div className="input-style">
                          <div className='form-label'>First Name*</div>
                          <input type="text" className="form-control" placeholder="First Name" value={fname}  onChange={e => setFname(e.target.value)} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="input-style">
                          <div className='form-label'>Last Name*</div>
                          <input type="text" className="form-control" placeholder="Last Name" value={lname}  onChange={e => setLname(e.target.value)} />
                        </div>
                      </div> */}
                    </div>
                   {/*  <div className="input-style">
                      <div className='form-label'>E-mail*</div>
                      <input type="email" className="form-control" placeholder="Email" value={email}  onChange={e => setEmail(e.target.value)} />
                    </div> */}
                    <div className="input-style">
                      <div className='form-label'>Phone*</div>
                      <input type="number" className="form-control" placeholder="Phone" value={phone}  onChange={e => setPhone(e.target.value)} />
                    </div>
                    {!(['google', 'facebook'].includes(profileData?.social_login_type)) && <><div className="input-style">
                      <div className='form-label'>Current Password</div>
                      <input type="password" className="form-control" placeholder="Current Password"  onChange={e => setCurrentPassword(e.target.value)}/>
                    </div>
                    <div className="input-style">
                      <div className='form-label'>New Password</div>
                      <input type="password" className="form-control" placeholder="New Password"  onChange={e => setNewPassword(e.target.value)}/>
                    </div>
                    <div className="input-style">
                      <div className='form-label'>Repeat Password</div>
                      <input type="password" className="form-control" placeholder="Repeat Password"  onChange={e => setConfirmPassword(e.target.value)} />
                    </div></>}
                    <div className='save-all-data'>
                      <button 
                      className='btn-action' 
                      type='submit'
                      disabled = {isRequestSending}
                      >
                      {isRequestSending && (
                          <i class="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
                      )}
                      Save Changes
                      </button>
                      <button className='btn-action alt' type="button" onClick={() => {
                        navigate(-1)
                      }}>Cancel</button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
            <div className='col-md-3'>
              <div className='img-part'>
                <img src='assets/img/man-with-app.svg' alt='' />
              </div>
            </div>
          </div>
          <div className='row pt-5'>
            <div className='col-md-3'>
              <div className='shipping-img'>
                <img src='assets/img/deliver-man.svg' alt='' />
              </div>
            </div>
            <div className='col-md-9'>
              <div className='shipping-details-section'>
                <div className='section-header'>
                  <h2 className='section-title'>Shipping <span>Details</span></h2>
                </div>
                
                <div className='shipping-address'>
                  
                  {info?.map((item, index) => {
                    return(
                  <div className='shipping-item' key={item?._id}>

                    <div className="address-item">
                      <div className="address-content" onClick={() => openEditModal(item)}  data-toggle="modal" data-target="#editonsModal">
                        <span className="text-capitalize">{item?.address_type} Address :</span>    {`${item?.address} , ${item?.zipcode}, ${item?.house_no}`}
                      </div>
                    <div className="custom-radio">
                      <input 
                      type="radio" 
                      className="custom-radio-input" 
                      name="address"
                      value={item?._id}
                      checked={item?.is_default}
                      readOnly={true}
                      //checked={defaultAddress === true}
                      onClick={(e) => handleChange(e,item?._id,index)}
                       />
                      <label className="custom-radio-label"></label>
                    </div>

                    </div>

                    



                  </div>)
                })}
                  <div className='add-new-address'>
                  <button className='add-btn' type='button'  data-toggle="modal" data-target="#addonsModal">
                  <i className="fa-regular fa-fw mr-2 fa-plus"></i>Add new Address
                  </button>
                </div>
                </div>
             

              </div>
            </div>
          </div>
          {/* <div className='row pt-5'>
            <div className='col-md-9'>
              <div className='payment-details-section'>
                <div className='section-header'>
                  <h2 className='section-title'>Payment <span>Methods</span></h2>
                </div>


                <div className='payment-type'>
                  <div className='payment-item'>
                    <div className="custom-radio">
                      <input type="radio" className="custom-radio-input" name="payment" defaultChecked />
                      <label className="custom-radio-label">
                      <div className='icon'><i className="fa-brands mr-2 fa-cc-paypal"></i></div>
                        <div className='text'>Pay with PayPal</div></label>
                    </div>
                  </div>
                  <div className='payment-item'>
                    <div className="custom-radio">
                      <input type="radio" className="custom-radio-input" name="payment" />
                      <label className="custom-radio-label">
                      <div className='icon'><i className="fa-solid mr-2 fa-credit-card"></i></div>
                        <div className='text'>Pay with Credit Card</div></label>
                    </div>
                  </div>
                  <div className='payment-item'>
                    <div className="custom-radio">
                      <input type="radio" className="custom-radio-input" name="payment" />
                      <label className="custom-radio-label">
                      <div className='icon'><img src='assets/img/COD.svg' alt='' /></div>
                        <div className='text'>Cash On delivery</div></label>
                    </div>
                  </div>
                  <div className='add-new-type'>
                  <button className='add-btn' type='button'>
                  <i className="fa-regular fa-fw mr-2 fa-plus"></i>Add new payment method
                  </button>
                </div>
                </div>
             

              </div>


            </div>
            <div className='col-md-3'>
              <div className='shipping-img'>
                <img src='assets/img/man-with-card.svg' alt='' />
              </div>
            </div>
          </div> */}
        </div>
        
      </section>
      <EditAddress data={editAddewssData} />
    </>
  );
};

export default ProfileDetails;
