import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import BASE_URL from '../../../config/host';
import "react-toastify/dist/ReactToastify.css";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

 



export default function SignInSection({ setToken }) {
  const sessionCartId = localStorage.getItem('sessionCartId');


  const history = useNavigate();
  const [data, setData] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showHidePass, setShowHidePass] = useState('password');
  const [eyeIcon, setEyeIcon] = useState('fa-solid fa-eye-slash');
  const [rememberMe, setRememberMe] = useState(false);
  const [isCreating, setIsCreating] = useState(false);


  //Recived Get Parameters
  const urlParam = useLocation().search;
  const redirectURL=new URLSearchParams(urlParam).get("_trgt");





  const showHideHandle = (e) =>{
    if(e === 'password'){
      setShowHidePass('text')
      setEyeIcon('fa-solid fa-eye')
    }else{
      setShowHidePass('password');
      setEyeIcon('fa-solid fa-eye-slash')
    }
  }


  let rembEmail = localStorage.getItem('email');
  let rembPassword = localStorage.getItem('password');
  useEffect(() => {
    if (rembEmail || rembPassword) {
      setRememberMe(true);
      setEmail(rembEmail);
      setPassword(rembPassword);
    }
  }, [rembEmail, rembPassword]);


  function setToken(userToken) {
    localStorage.setItem("token", JSON.stringify(userToken));
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreating(true);
      axios
      .post(`${BASE_URL}auth/user-login`, {
        email: email,
        password: password,
        loginType:'user',
        cart_id : sessionCartId,
      })
      .then(function (response) {
        if (response.data.status === 400) {
          toast.error(response.data.message, { theme: "colored" });
          setIsCreating(false);
        }
        if (response.data.status === 200) {
          toast.success(response.data.message, { theme: "colored" });

          if(rememberMe === true){
            localStorage.setItem("email", email);
            localStorage.setItem("password", password);
          }else{
            localStorage.removeItem("email");
            localStorage.removeItem("password");
          }
          setToken(response.data.data.access_token);
          if(redirectURL){
            history(`/${redirectURL}`);
          }else{
            history("/account");
          }
          
          setIsCreating(false);
        }
      })
      .catch(function (error) {
        toast.error(error.message, { theme: "colored" });
      });

  };
  
  async function handleGoogleLogin() {
    await axios
      .post(`${BASE_URL}/google-login`, {})
      .then(function (response) {
        console.log(response)
        window.location.href = response.data.data
      })
  }

  async function handleFacebookLogin(res) {
    console.log(res);
    await axios
      .post(`${BASE_URL}/facebook-login`, {
        name: res.name,
        email: res.email,
        social_login_id: res.id,
        social_login_type: "facebook",
        login_type: 2,
      })
      .then(function (response) {
        console.log(response)
        if(response.data.is_success) {
          localStorage.setItem('token', JSON.stringify(response.data.access_token));
          window.location.href = "/account";
      } else{ 
          window.location.href = "/sign-in";
      }
        //window.location.href = response.data.data
      })
  }



  const config = {
    cWg: {
      en: 'Continue with Google',
      es: 'Continuar con Google',
      it: 'Continua con Google'
    },
    cWf: {
      en: 'Continue with Facebook',
      es: 'Continuar con Facebook',
      it: 'Continua con Facebook'
    }
  }


  return (
    <>
      <ToastContainer theme="colored" />
      <div id="fb-root"></div>
      <section className="sec-authentication">
        <div className="authentication-wrap">
          <div className="authentication-hader">
            <Link to="/" className="authentication-logo">
              <img src="assets/img/logo.svg" alt="Logo" />
            </Link>
            <div className="became-title">
              <div className="text">Became a member</div>
              <Link to="/sign-up" className="click-btn">
                Click here!
              </Link>
            </div>
          </div>
          <div className="authentication-container">
            <div className="authentication-box">
              <div className="box-header">
                <div className="title">Welcome Back</div>
                <div className="sub-title">Login into your account</div>
              </div>
              <div className="authentication-action">
                <button onClick={handleGoogleLogin} className="authentication-btn">
                  <div className="btn-icon">
                    <img src="assets/img/google.svg" alt="" />
                  </div>
                  <div className="btn-text">Google</div>
                </button>
                {/* <button onClick={handleLogin} className="authentication-btn">
                  <div className="btn-icon">
                    <img src="assets/img/facebook.svg" alt="" />
                  </div>
                  <div className="btn-text">Facebook</div>
                </button> */}
                <FacebookLogin
        appId={"751005573305442"}
        callback={(res) => {handleFacebookLogin(res);} }
        autoLoad={false}
        render={renderProps => (
          <button onClick={renderProps.onClick} className="authentication-btn">
                  <div className="btn-icon">
                    <img src="assets/img/facebook.svg" alt="" />
                  </div>
                  <div className="btn-text">Facebook</div>
                </button>
        )}
        fields="name, email, picture"
        scope="public_profile, email"
      />
              </div>
              <div className="continue-inner">
                <span>Or continue with</span>
              </div>
              <div className="form-wrap">
                <form onSubmit={handleSubmit}>
                  <div className="custom-form-group">
                    <input
                      type="email"
                      className="form-control"
                      autoComplete="on"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="custom-form-group">
                    <input
                      type={showHidePass}
                      className="form-control"
                      autoComplete="on"
                      placeholder="Password"
                      value={password}
                      minLength={5}
                      maxLength={16}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="icon" onClick={(e) =>{showHideHandle(showHidePass)}}>
                      <i className={eyeIcon}></i>
                    </div>
                  </div>
                  <div className="authentication-attr">
                    <div className="driver-notifications">
                      <div className="pricing-toggle">
                        <label className="switch">
                          <input type="checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}  />
                          <span className="slider round"></span>
                        </label>
                        <h6 className="toggle-text">Remember me</h6>
                      </div>
                    </div>
                    <a href="/forgot-password" className="btn-fogot-password">
                      Recover Password
                    </a>
                  </div>
                  <div className="log-action">
                    <button type="submit" className="action-btn" disabled={isCreating}>
                    {isCreating && <i className="fa-solid fa-circle-notch fa-spin mr-2"></i>}Log In
                    </button>
                  </div>
                </form>
                <div className="became-title responsive">
                  <div className="text">Become a member</div>
                  <Link to="/sign-up" className="click-btn">
                    Click here!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="authentication-img">
          <img src="assets/img/signin.jpg" alt="" />
        </div>
      </section>
    </>
  );
}
