
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";

export const storySlice = createSlice({
    name: 'storys',
    loading: false,
    initialState: {
        storylist: [],
        storyCount: 0
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.storylist ='';
            //console.log(action.payload);
        },
        listReceived: (state, action)=>{
            state.loading = false;
            state.storylist = action.payload.data?action.payload.data:[];
            state.storyCount = action.payload.count || 0
        }
    }
});


export const {listReceived,listRequested ,listRequestFailed } = storySlice.actions;
//console.log(body);
export const fetchStorys = (body)=> apiCallBegan({
    url: '/page/list',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type
 
});














