import React from 'react';
import './Css/Refer.css'
import ReferBanner from './Components/ReferBanner';
import ReferSection from './Components/ReferSection';

const ReferFriend = () => {
  return (
    <>
      <ReferBanner/>
      <ReferSection/>
    </>
  );
};

export default ReferFriend;