//import React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { fetchPages } from "../../../store/page/page";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";




const SecRange = () => {
  const dispatch = useDispatch();
  const [pageDetails, setpageDetails] = useState(null);


  useEffect(() => {
    const pageDetails = async() =>{
      try {
        const data = await fetchPages({slug: "wide-range", status: true,})
        setpageDetails(data)
      } catch (error) {
        console.log(error);
      }
    }
    pageDetails();
  }, [dispatch]);




  return (
    <section className='range-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7 offset-lg-5'>
            <div className='range-content'>
              <h2 className='range-title'>{pageDetails?.title}</h2>
              <div className='range-tag'>
                <h6 className='text'>{pageDetails?.subtitle}</h6>
              </div>
              <div className='range-img'><img src={pageDetails?.image} alt="" /></div>
              <div className='range-other-content'>
                <h6 className='sub-title'>TODAY’S BEST DEAL</h6>
                <div className='description' dangerouslySetInnerHTML={{__html:pageDetails?.description}}></div>
                <div className='range-btn'>
                  <Link to="/menu" className="menu">Menu</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecRange;