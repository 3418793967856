import axios from 'axios';
import React from 'react';
import { useState} from 'react';
import { useEffect } from 'react';
import BASE_URL from '../config/host';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
const Newsletter = () => {
  const [newsltr, setNewsltr] = useState()
  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }

    axios.post(BASE_URL + 'page/details',{ slug: "stay-updated" },{headers: headers} )
    .then(function (response) {
      setNewsltr(response.data.data);
      //console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
  }, [])

  //mailchimpSubscription
  const [isCreating, setCreating] = useState(false)
  const [email, setEmail] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);
    try{
      const response = await axios.post(`${BASE_URL}news-letter-subscription`, {email_id : email}, {})
      if (response.data.status == 200) {
        toast.success(response.data.title, { theme: "colored" });
        setCreating(false);
        setEmail(null);
      }
      if (response.data.status == 400) {
        let objMsg = JSON.parse(response.data.response.text);
        toast.error(objMsg.title, { theme: "colored" });
        setCreating(false);
        setEmail(null);
        console.log(response.data);
      }
    }catch(error){
      if (error.status == 400) {
        let objMsg = JSON.parse(error.data.response.text);
        toast.error(objMsg.title, { theme: "colored" });
        setCreating(false);
        setEmail(null);
      }
    }
  }
  
  return (
    <>
    
      <section className='section-newsletter'>
      <ToastContainer theme="colored" />
        <div className='newsletter-image'><img src={newsltr?.image} alt='' /></div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7'>
              <div className='newsletter-content'>
                <h2 className='newsletter-title'>{newsltr?.title} <span>{newsltr?.subtitle}</span></h2>
                <div className='newsletter-text'>
                <div dangerouslySetInnerHTML={{__html: newsltr?.description}} /> 
                </div>
                <form className='form-content' onSubmit={handleSubmit}>
                  <div className='input-style'>
                    <input type="text" className='form-control' name="email" placeholder='Email address' onChange={e => setEmail(e.target.value)} value={email?email:''} />
                  </div>

                  
                  <div className='input-action'>
                    <button 
                    type='submit'
                    className='btn-form-submit'
                    disabled = {isCreating}
                    >
                    {
                      (isCreating)
                      ?(<div className='icon'> <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i></div>)
                      :(<div className='icon'><i className='icon-send'></i></div>)
                    }
                      <div className='title'>Submit</div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Newsletter;