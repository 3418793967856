import React from 'react';
import { Link } from 'react-router-dom';
const AccountOptions = () => {
  return (
    <>
      <section className='account-option-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-6'>
              <Link to='/order-history' className='account-option'>
                <div className='acount-img'>
                  <img src='assets/img/account-order.svg' alt=''/>
                </div>
                <div className='account-content'>
                  <h6 className='text' style={{color: "white"}}>Orders</h6>
                </div>
              </Link>
            </div>
            <div className='col-6'>
              <Link to='/deals' className='account-option'>
                <div className='acount-img'>
                  <img src='assets/img/account-food.svg' alt=''/>
                </div>
                <div className='account-content'>
                  <h6 className='text' style={{color: "white"}}>Deals/new items</h6>
                </div>
              </Link>
            </div>
            <div className='col-6'>
              <Link to='/account-rewards' className='account-option'>
                <div className='acount-img'>
                  <img src='assets/img/account-reward.svg' alt=''/>
                </div>
                <div className='account-content'>
                  <h6 className='text' style={{color: "white"}}>Reward items</h6>
                </div>
              </Link>
            </div>
            <div className='col-6'>
              <Link to='/point-transaction-history' className='account-option'>
                <div className='acount-img'>
                  <img src='assets/img/points-history.jpg' alt=''/>
                </div>
                <div className='account-content'>
                  <h6 className='text' style={{color: "white"}}>Point Transaction History</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountOptions;