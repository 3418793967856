import React from 'react';
import AboutBanner from './Components/AboutBanner';
import SecCareer from './Components/SecCareer';
import SecFunfacts from './Components/SecFunfacts';
import SecHighlights from './Components/SecHighlights';
import SecMission from './Components/SecMission';
import SecStory from './Components/SecStory';
import SecTeam from './Components/SecTeam';
// import Cookies from '../../Components/Cookies';
import './Css/About.css';

const About = () => {
  return (
    <>
      <AboutBanner/>
      <SecStory/>
      <SecMission/>
      <SecTeam/>
      <SecHighlights/>
      <SecFunfacts/>
      <SecCareer/>
      {/* <Cookies/> */}
    </>
  );
};

export default About;