import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
// import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import { fetchTestimonials } from '../../../store/home/testimonial';
import { fetchPages } from '../../../store/page/page';
import { useState } from 'react';
import axios from 'axios';
// import { wait } from '@testing-library/user-event/dist/utils';
import BASE_URL from "../../../config/host";

const HomeTestimonial = () => {
  const dispatch = useDispatch();
  //const { testimoniallist } = useSelector(state => state.testimonials);
  const [testimonialHeading, setTestimonialHeading] = useState([]);
  
  useEffect(() => {
    //dispatch(fetchTestimonials({status:true}))
    const loadpage = async() => {
      try {
        setTestimonialHeading([]);
       //const featured =  props.featured?props.featured:false;
         const  list = await fetchPages({slug:"what-people-say"});
         setTestimonialHeading(list);
        } catch (error) {
         
        }
     }
    loadpage();
  }, [dispatch]);




  const [tripadvisorDataList, setTripadvisorDataList] = useState([]);
  useEffect(()=>{
    const hanleTripadvisor = async(e)=>{
      try{
        const tripadvisorDataList = await axios.get(`${BASE_URL}get-tripadvisor-testimonial`,{},{});
        if(tripadvisorDataList.data.status === 200){
          setTripadvisorDataList(tripadvisorDataList.data.data);
        }
      }catch(error){
        console.log(error);
      }
    }
    hanleTripadvisor();
  },[]);






  function createMarkup() {
    return { __html: testimonialHeading?.description };
  }

  return (
    <section className='section-testimonial'>
      <div className='container'>
        <div className='testimonial-header'>
          <h2 className='testimonial-title' ><div dangerouslySetInnerHTML={createMarkup()}></div>
           <div className='title-image'><img src={testimonialHeading?.image} alt='' /></div>
          </h2>
        </div>
        <div className='testimonial-slider-wrap'>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            navigation
            modules={[Navigation, Pagination]}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              480: {
                slidesPerView: 2,
              },
              990: {
                slidesPerView: 3,
              },
              1460: {
                slidesPerView: 4,
              },
            }}
            className="testimonial-slider"
          >
            {tripadvisorDataList.length > 0 && tripadvisorDataList.map((item,index) => {
              return ( <SwiperSlide key={index}>
                <div className='testimonial-item'>
                  <div className='testimonial-inner'>
                    <div className='testimonial-icon'><i className='icon-tripadvisor'></i></div>
                    <h2 className='testimonial-title'>{item?.user?.username}</h2>
                    <div className='testimonial-text'>
                      <h6>{item?.title}</h6>
                      <p>{item?.text}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>);
            })}
            </Swiper>
        </div>
      </div>
    </section>
  );
};

export default HomeTestimonial;