import React from 'react';
import { NavLink } from 'react-router-dom';

const CarrerBanner = () => {
  return (
    <>
       <section className='page-banner-section'>
      <div className='container'>
        <div className='banner-section'>
          <div className='breadcrumb-section'>
            <div className='link'>
              <NavLink to="/" className="breadcrumb-link">Home</NavLink>
            </div>
            <div className='seperator'>/</div>
            <div className='link'>
              <div className="breadcrumb-link">Careers</div>
            </div>
          </div>
          <div className='page-title'>
            <h1 className='title'>Careers</h1>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default CarrerBanner;