import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BASE_URL from "../../../config/host";
import moment from "moment/moment";
import { toast } from "react-toastify";

const OrderSection = () => {
  const { id } = useParams();
  const [orderInfo, setOrderInfo] = useState();

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };

    axios
      .post(BASE_URL + "order/details", { id: id }, { headers: headers })
      .then(function (response) {
        setOrderInfo(response.data.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);

  let today     = moment().format("DDMMYYYY");
  let tomorrow  = moment().add(1, 'days').format("DDMMYYYY");
  let givenDate = moment(new Date(orderInfo?.pickup_time)).format("DDMMYYYY");
  let day = '';
  switch (givenDate) {
    case today:
      day = "Today";
      break;
    case tomorrow:
      day = "Tomorrow";
      break;
    default:
       day = "inactive";
      break;
  }


  

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  }
  function handleCancel() {
    axios
      .post(`${BASE_URL}order/order-cancel`,{
        id: id,
        type: "user"
      }, {headers: headers})
      .then(function (response) {
        console.log(response);        
        if (response.data.status === 400) {
          toast.error(response.data.message, { theme: "colored" });
        }
        if (response.data.status === 200) {
          toast.success(response.data.message, { theme: "colored" });
        }
      })
      .catch(function (error) {
        console.log(error);        
        //toast.error(error.message, { theme: "colored" });
      });
  }

  console.log(orderInfo?.order_status);
  return (
    <>
      <section className="ordering-section">
        <div className="container">
          <div className="order-part">
            <div className="order-id">#{orderInfo?.order_id}</div>
            <div className="order-date">{moment(new Date(orderInfo?.created_at)).format("ddd, DD MMM, YYYY - hh:mm A")}</div>
            <div className="order-details-table">
              <div className="order-item-details">
                <div className="order-no">{orderInfo?.order_detail?.length} items</div>
                <div className="orders">
                  <div className="order-item">
                    {orderInfo?.order_detail?.map((item, i) => {
                      return(
                        <div className="order-list" key={i}>
                          <div className="order-text">
                            {item?.product_info[0].title}<span> x {item?.quantity}</span>
                            {
                              (item?.spice_level_info?.title)&&
                              (<div className="sub-text">{item?.spice_level_info?.title}</div>)
                            }
                          </div>
                          {(item?.buying_mode === 1)
                          ?(<div className="price">{'€'+(item?.price * item?.quantity).toFixed(2)}</div>)
                          :(<div className="price">{'Pts. '+(item?.point_amount * item?.quantity)}</div>)
                          }
                          
                        </div>
                      );
                      })}
                  </div>
                </div>
              </div>
              <div className="order-address">
                {
                  (orderInfo?.order_type === 1)
                  ?(<div className="title">Delivered to</div>)
                  :(<div className="title">Pickup from store</div>)
                }
                <div className="address">
                  <p>
                    <span className="d-block">{orderInfo?.user_info?.name}</span>
                    <span className="d-block">{orderInfo?.user_info?.email}</span>
                    <span className="d-block">{orderInfo?.user_info?.phone}</span>
                    {
                      (orderInfo?.order_type === 1)
                      ?(<div>
                        {orderInfo?.shipping_info.address+','} 
                        {orderInfo?.shipping_info.house_no}
                        {' - '+orderInfo?.shipping_info?.zipcode}
                        {
                          (orderInfo?.pickup_time)&&
                          (((day === 'inactive')
                            ?(<div>Delivery Time :  {moment(new Date(orderInfo?.pickup_time)).format("ddd, DD MMM, YYYY - hh:mm A")}</div>)
                            :(<div>Delivery Time :  {day+', ' + moment(new Date(orderInfo?.pickup_time)).format("hh:mm A")}</div>)
                            ))
                        }

                      </div>)
                      :((day === 'inactive')
                          ?(<div>Take off Time :  {moment(new Date(orderInfo?.pickup_time)).format("ddd, DD MMM, YYYY - hh:mm A")}</div>)
                          :(<div>Take off Time :  {day+', ' + moment(new Date(orderInfo?.pickup_time)).format("hh:mm A")}</div>)
                        )
                    }
                  </p>
                </div>
              </div>
              <div className="order-payment">
                <div className="title">Payment information</div>
                <div className="payment-type">Payment Method : {orderInfo?.payment_method?.title}</div>
                <div className="payment-type">Total : {(orderInfo?.cart_amount - orderInfo?.cart_discount_amount).toFixed(2)}</div>
                {
                  (orderInfo?.shipping_charge_amount > 0)&&
                  (<div className="payment-type">Delivery Charge : {orderInfo?.shipping_charge_amount.toFixed(2)}</div>)
                }
                {
                  (orderInfo?.coupon_discount_amount > 0)&&
                  (<div className="payment-type">Coupon Discount : {'-'+orderInfo?.coupon_discount_amount.toFixed(2)}</div>)
                }
                {
                  (orderInfo?.order_total_point > 0)&&
                  <div className="payment-type">Total Point Use : {'Pts.'+orderInfo?.order_total_point}</div>
                }
                {
                  (orderInfo?.point_to_amount_convert > 0)&&
                  <div className="payment-type">Amount for Point : {'€'+orderInfo?.point_to_amount_convert.toFixed(2)}</div>
                }
                <div className="payment-type">Paid Amount : {'€'+orderInfo?.order_total_amount.toFixed(2)}</div>
              </div>
            </div>
            <div className="order-progress-section">
              <div className="step-part">
              {orderInfo?.order_status.type_id >= 6 && <div className="step active"></div>}
              {orderInfo?.order_status.type_id >= 8 ? <div className="step active"></div> : <div className="step"></div>}
              {orderInfo?.order_status.type_id >= 9 ? <div className="step active"></div> : <div className="step"></div>}
              {orderInfo?.order_status.type_id >= 10 ? <div className="step active"></div> : <div className="step"></div>}
              {orderInfo?.order_status.type_id >= 11 ? <div className="step active"></div> : <div className="step"></div>}
              </div>
              <div className="step-result-part">
              {orderInfo?.order_status.type_id === 6 && <div className="step-result active">
                  <h2 className="step-title">Pending</h2>
                  <div className="step-image">
                    <img src="assets/img/order-accept-man.svg" alt="" />
                  </div>
                  <div className="description">
                    <p>
                    Your order is pending.
                    </p>
                    {/* {!(orderInfo?.cancel_request) && <button onClick={() => handleCancel()}>Cancel</button>} */}
                  </div>
                </div>}
                {orderInfo?.order_status.type_id === 8 && <div className="step-result active">
                  <h2 className="step-title">Accepted</h2>
                  <div className="step-image">
                    <img src="assets/img/prepare-food-man.svg" alt="" />
                  </div>
                  <div className="description">
                    <p>
                    Your order has been accepted.
                      
                    </p>
                  </div>
                </div>}
                {orderInfo?.order_status.type_id === 9 && <div className="step-result active">
                  <h2 className="step-title">Preparing Food</h2>
                  <div className="step-image">
                    <img src="assets/img/packing-food-man.svg" alt="" />
                  </div>
                  <div className="description">
                    <p>
                      Food is being prepared.
                    </p>
                  </div>
                </div>}
                {orderInfo?.order_status.type_id === 10 && <div className="step-result active">
                  <h2 className="step-title">Ready</h2>
                  <div className="step-image deliver">
                    <div className="img-wrap">
                      <img src="assets/img/deliver-man.svg" alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      Your order is ready.
                    </p>
                  </div>
                </div>}
                {orderInfo?.order_status.type_id === 11 && <div className="step-result active">
                  <h2 className="step-title">Out for delivery</h2>
                  <div className="step-image deliver">
                    <div className="img-wrap">
                      <img src="assets/img/deliver-man.svg" alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      Your order out for delivery.
                    </p>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderSection;
