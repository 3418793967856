import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchHighlights } from "../../../store/highlight/highlight";
import { fetchPages } from "../../../store/page/page";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ReactPlayer from "react-player/youtube";

const  SecHighlights = () => {
  const dispatch = useDispatch();
  const { highlightlist } = useSelector((state) => state.highlights);
  const [pageDetails, setpageDetails] = useState(null);

  useEffect(() => {
    dispatch(
      fetchHighlights({
        status: true,
        limit: 9,
        sortByField: "ordering",
        sortByValue: 1,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const pageDetails = async () => {
      try {
        const data = await fetchPages({ slug: "our-highlights", status: true });
        setpageDetails(data);
      } catch (error) {
        console.log(error);
      }
    };
    pageDetails();
  }, [dispatch]);

  //console.log(pageDetails);

  return (
    <section className="highlights-section">
      <div className="highlights-image">
        <div className="image-desktop">
          <img src={pageDetails?.image} alt="" />
        </div>
        <div className="image-mobile">
        <img src='assets/img/lady-responsive.svg' alt="" />
        </div>
      
      </div>
      <div className="container">
        <div className="section-header">
          <h2 className="section-title">
            Our<span> Highlights</span>
          </h2>
          <div className="highlight-description">
            <p
              dangerouslySetInnerHTML={{ __html: pageDetails?.description }}
            ></p>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-7 col-lg-6 col-md-12 mr-auto col-12">
            <div className="highlight-video-part">
              <div className="highlights-slider-wrap">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={0}
                  navigation
                  modules={[Navigation, Pagination]}
                  pagination={{
                    clickable: true,
                  }}
                  className="highlight-slider"
                >
                  {highlightlist.length > 0 &&
                    highlightlist.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          {/* <div className="highlight-item">
                            <div className="highlight-video">
                            </div>
                            <img src="assets/img/video.jpg" alt="" />

                            <div className="video-action">
                              <button type="button" className="btn-action">
                                <a
                                  href={item.video_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-solid fa-fw fa-play"></i>
                                </a>
                              </button>
                            </div>
                          </div> */}
                          <div className="highlight-item">
                            <ReactPlayer  width='100%' url={item?.video_url} />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecHighlights;
