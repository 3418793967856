import { combineReducers } from "redux";
import { stepSlice } from "./home/step";
import { bannerSlice } from "./home/banner";
import { blogSlice } from "./blog/blog";
import { teamSlice } from "./Team/team";
import { funfactSlice } from "./FunFact/funfact";
import { missionSlice } from "./Mission-Vission/Mission";
import { vissionSlice } from "./Mission-Vission/Vission";
import { categorySlice } from "./category/category";
import { serviceSlice } from "./home/service";
import { benefitSlice } from "./home/benefit";
import { testimonialSlice } from "./home/testimonial";
import { jobSlice } from "./career/job";
import { featureTeamSlice } from "./career/careerFutureTeam";
import { helpSlice } from "./contact/help-feature";
import { settingSlice } from "./shared/setting";
import { faqSlice } from "./contact-detail/faq";
import { signupSlice } from "./Auth/signup";
import { highlightSlice } from "./highlight/highlight";
import { allergySlice } from "./ingredient/allergies";
import { dietSlice } from "./ingredient/diets";
import { productcategorySlice } from "./productcategory/productcategory";
import { blogcommentSlice } from "./blog/blogcomment";


import { storySlice } from "./about/story";
import { aboutbannerSlice } from "./about/banner";


import { openingHourSlice } from "./shared/openinghour";

import { cartListSlice } from "./cartlist/cartlist";

import { deliveryChargeSlice } from "./delivery-charges/deliveryCharges";

import { userSelectedAddressSlice } from "./set-user-selected-address/userSelectedAddress";

import { updateApplyCuoponSlice } from "./apply-cuopon/applyCuopon";


export default combineReducers({
     steps: stepSlice.reducer,
     banners: bannerSlice.reducer,
     blogs: blogSlice.reducer,
     teams: teamSlice.reducer,
     funfacts: funfactSlice.reducer,
     missions: missionSlice.reducer,
     vissions: vissionSlice.reducer,
     categories: categorySlice.reducer,
     services: serviceSlice.reducer,
     benefits: benefitSlice.reducer,
     testimonials: testimonialSlice.reducer,
     jobs: jobSlice.reducer,
     featureTeam: featureTeamSlice.reducer,
     helps: helpSlice.reducer,
     settings: settingSlice.reducer,
     faqs: faqSlice.reducer,
     highlights: highlightSlice.reducer,
     signup:signupSlice.reducer,
     storys: storySlice.reducer,
     aboutbanners: aboutbannerSlice.reducer,
     allergies: allergySlice.reducer,
     diets: dietSlice.reducer,
     productcategories: productcategorySlice.reducer,
     blogcomments: blogcommentSlice.reducer,
     openinghour: openingHourSlice.reducer,
     cartList: cartListSlice.reducer,
     getDeliveryCharges: deliveryChargeSlice.reducer,
     getUserSelectedAddress: userSelectedAddressSlice.reducer,
     getUpdateApplyCuopon: updateApplyCuoponSlice.reducer,


    })
