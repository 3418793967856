import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchJobs } from "../../../store/career/job";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SecCareer = () => {
  

  const dispatch = useDispatch();
  const { joblist } = useSelector((state) => state.jobs);

  useEffect(() => {
    dispatch(
      fetchJobs({
        status: true,
        limit: 9,
        sortByField: 'ordering',
        sortByValue: 1,
        featured : true,
      })
    );
  }, [dispatch]);
// console.log(joblist);
  return (
    <section className="career-section">
      <div className="section-header">
        <h2 className="section-title">
          <span>career</span>
        </h2>
      </div>
      <div className="container">
        <Swiper
          slidesPerView={1}
          spaceBetween={60}
          navigation
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            760: {
              spaceBetween: 50,
              slidesPerView: 1,
            },
            990: {
              spaceBetween: 50,
              slidesPerView: 3,
            },
            1024: {
              spaceBetween: 50,
              slidesPerView: 2,
            },
            1400: {
              spaceBetween: 50,
              slidesPerView: 3,
            },
          }}
          className="career-slider"
        >
          {joblist.length > 0 &&
            joblist.map((item, index) => {
              function createMarkup() {
                return {__html: item.description};
              }
              return (
                <SwiperSlide key={index}>
                  <div className="career-item">
                    <div className="item-inner">
                      <div className="job-title">{item?.title}</div>
                      <div className="job-description">
                        <p><div dangerouslySetInnerHTML={createMarkup()} />
                        </p>
                      </div>
                      <div className="job-action">
                        <Link to={`/career-apply/${item?.slug}`} className="apply">
                          Apply Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </section>
  );
};

export default SecCareer;
