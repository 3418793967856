import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import { fetchServices } from "../../../store/home/service";
import { fetchPages } from "../../../store/page/page";
import { useState } from "react";

const HomeServices = () => {
  const dispatch = useDispatch();
  const { servicelist } = useSelector((state) => state.services);
  const [serviceHeading, setServiceHeading] = useState([]);
  useEffect(() => {
    dispatch(fetchServices({ sortByField: "ordering", sortByValue: 1 }));

    const loadpage = async() => {
      try {
        setServiceHeading([]);
       //const featured =  props.featured?props.featured:false;
         const  list = await fetchPages({slug:"Our-Services"});
         setServiceHeading(list);
        } catch (error) {
         
        }
     }
    loadpage();


  }, [dispatch]);

  function createMarkup() {
    return { __html: serviceHeading?.description };
  }

  //console.log(servicelist);
  return (
    <section className="section-services">
      <div className="container">
        <div className="section-header">
          <h2 className="section-title" dangerouslySetInnerHTML={createMarkup()}>
           </h2>
        </div>

        <Swiper
          slidesPerView={1}
          spaceBetween={50}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            760: {
              spaceBetween: 10,
              slidesPerView: 3,
            },
            990: {
              spaceBetween: 30,
              slidesPerView: 3,
            },
            1400: {
              spaceBetween: 50,
              slidesPerView: 3,
            },
          }}
          className="service-slider"
        >
          {servicelist.length > 0 &&
            servicelist.map((item, index) => {
              const getBadgeClasses = () => {
                let classes = "item-image ";
                classes += item.status === 2 ? "meal-box" : "";
                return classes;
              };
              if (item.status === 1 || item.status === 2) {
                return (
                  <SwiperSlide key={index}>
                    <a href={item?.link}>
                    <div className="service-item">
                      <div className="item-inner">
                        <div className={getBadgeClasses()}>
                          {item.status === 2 && (
                            <div className="coming-soon-text">Coming Soon</div>
                          )}
                          <img src={item.image} alt="" />
                        </div>
                        <h2 className="item-title">{item.title}</h2>
                        <div className="item-text">{item.description}</div>
                      </div>
                    </div>
                    </a>
                  </SwiperSlide>
                );
              }
            })}
        </Swiper>
      </div>
    </section>
  );
};

export default HomeServices;
