
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";

export const signupSlice = createSlice({
    name: 'signup',
    loading: false,
    initialState: {
        signuplist: [],
        signupCount: 0
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.signuplist ='';
            //console.log(action.payload);
        },
        listReceived: (state, action)=>{
            state.loading = false;
            state.signuplist = action.payload.data?action.payload.data:[];
            state.signupCount = action.payload.count || 0
        }
    }
});


export const {listReceived,listRequested ,listRequestFailed } = signupSlice.actions;
//console.log(body);
export const fetchSignup = (body)=> apiCallBegan({
    url: '/signup',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type
 
});














