import React from 'react';
import { Outlet } from 'react-router-dom';
import DefaultHeader from '../Shared/DefaultHeader';
import DefaultFooter from '../Shared/DefaultFooter';

const DefaultLayout = () => {
  return (
    <>
      <DefaultHeader />
      <Outlet />
      <DefaultFooter />
    </>
  );
};

export default DefaultLayout;