
import { createSlice, createAction } from "@reduxjs/toolkit";

export const updateApplyCuopon = createAction("updateApplyCuopon");
export const updateApplyCuoponSlice = createSlice({
    name: 'getUpdateApplyCuopon',
    initialState: {
        cuoponDiscountAmount : 0,
        appliedCuoponCode : '',
        isAppliedCuopon : false,
    },
    extraReducers: (builder) => {
    builder
        .addCase(updateApplyCuopon, (state, action) => {
            state.cuoponDiscountAmount = action.payload.cuoponDiscountAmount;
            state.appliedCuoponCode = action.payload.appliedCuoponCode;
            state.isAppliedCuopon =  action.payload.appliedCuoponCode;
        })
    },
});














