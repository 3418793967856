import React from 'react';
import './Css/DeliveryOrder.css'
import DeliveryOrderBanner from './Components/DeliveryOrderBanner';
import DeliveryProgress from '../DeliveryMethod/Components/DeliveryProgress';
import DeliveryPlace from './Components/DeliveryPlace';
import ThanksOrder from './Components/ThanksOrder';

const DeliveryOrder = () => {
  return (
    <>
      <DeliveryOrderBanner/>
      <DeliveryProgress/>
      <DeliveryPlace/>
      <ThanksOrder/>
    </>
  );
};

export default DeliveryOrder;