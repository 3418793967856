import React from 'react';
// import { NavLink } from 'react-router-dom';

const RewardsTab = ({rewardsTab, setRewardsTab, setActive, active}) => {

  const tabLink = (event) => {
    //setRewardsTab(event);
    console.log(event);
  }
  return (
    <>
       <section className='account-tab-section'>
        <div className='container'>
          <div className='tab-list'>
            <div className='tab-item'>
              <button type='button' className={'tab-link ' + (active === 1 || active === 2 || active === 3 ? 'active' : '') }  onClick={() => {tabLink('spendPoint'); setActive(1);}}>Spend Points</button>
            </div>
            <div className='tab-item'>
              <button type='button' className={'tab-link ' + (rewardsTab ==='mileStone'? 'active' : '') }  onClick={tabLink('mileStone')}>Mildstone Challenges</button>
            </div>
            <div className='tab-item'>
              <button type='button' className={'tab-link ' + (rewardsTab ==='howWorks'? 'active' : '') } onClick={tabLink('howWorks')}>How Rewards works</button>
            </div>
            <div className='tab-item'>
              <button type='button' className={'tab-link ' + (active === 4 ? 'active' : '') } onClick={() => {tabLink('pointConversion'); setActive(4);}}>Points Conversion</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RewardsTab;