import React from 'react';
import './Css/EditAccount.css'
import EditAccountBanner from './Components/EditAccountBanner';
import ProfileDetails from './Components/ProfileDetails';
import Newsletter from '../../Components/Newsletter';
import NewAddress from './Components/NewAddress';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';


const EditAccount = () => {
  const navigate = useNavigate();
  function goToLogin() {
    navigate('/sign-in');
  }
  if (localStorage.getItem('token')) {
  return (
    <>
      <EditAccountBanner/>
      <ProfileDetails/>
      <Newsletter/>
      <NewAddress/>
    </>
  );}
  else{
    return (
      <>
        {/* <EditAccountBanner/>
        <button onClick={goToLogin}>Sign In</button>
        <Newsletter/>
        <NewAddress/> */}
        <Navigate to='/sign-in' replace={true} />
      </>
    );}
  
};

export default EditAccount;