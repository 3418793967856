import React from 'react';
import moment from 'moment/moment';

const BlogDetailsContent = ({ detail }) => {
  const createdAtTime = detail
    ? new Date(detail.created_at).toLocaleString('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    : '';
console.log(detail);
  return (
    <section className='blog-details-section'>
      <div className='container'>
        <div className='blog-details-image'>
          {detail?.image && (
            <div className='img-wrap'>
              <img src={detail?.image} alt={detail?.title} />
            </div>
          )}
          <div className='blog-content'>
            <div className='blog-title'>{detail?.title || ''}</div>
            <div className='blog-time d-none'>
              <div className='icon'>
                <i className='fa-solid fa-clock'></i>
              </div>
              <div className='text'>Reading time 3 min</div>
            </div>
          </div>
        </div>
        <div className='blog-data'>
          <div className='date'>
            <div className='icon'>
              <i className='fa-regular fa-fe fa-calendar'></i>
            </div>
            <div className='text'>
              {moment(new Date(createdAtTime)).format('DD MMM YYYY')}
            </div>
          </div>
          <div className='author'>
            <div className='icon'>
              <i className='fa-regular fa-fw fa-circle-user'></i>
            </div>
            <div className='text'>by { detail?.created_by?.name }</div>
          </div>
          <div className='tag'>
            <div className='tag-text'>
              {detail ? detail.category?.name || '' : ''}
            </div>
          </div>
        </div>
        {detail?.long_description &&
          <div className='blog-details-content'
            dangerouslySetInnerHTML={{ __html: detail?.long_description }}
          ></div>}
          <div className='blog-details-content'
            dangerouslySetInnerHTML={{ __html: detail?.short_description }}
          ></div>
            {/* <div className='description'
              // dangerouslySetInnerHTML={{ __html: detail?.short_description || '' }}
            >
              <p>
                In eget turpis elit. Phasellus eleifend mattis felis, ac mollis
                sapien bibendum vel. Maecenas sed fringilla purus. Curabitur
                condimentum velit sem, non posuere purus dignissim ut. Donec
                tortor nisi, tempus sit amet accumsan ac, faucibus in massa. Nunc
                vel lobortis ante. Nullam aliquam tincidunt ligula, eu egestas
                diam sodales vitae.{' '}
              </p>
            </div> */}

            {/* <div className='description-points'>
              <div className='number-points'>
                <ul className='list'>
                  <li className='list-item'>
                    Phasellus sollicitudin quam urna, ut iaculis enim
                  </li>
                  <li className='list-item'>
                    Sed gravida, erat et porta sagittis, ante
                  </li>
                  <li className='list-item'>
                    Vestibulum tempus mi at nisi ullamcorper
                  </li>
                </ul>
              </div>
              <div className='number-points icon'>
                <ul className='list'>
                  <li className='list-item'>
                    Phasellus sollicitudin quam urna, ut iaculis enim
                  </li>
                  <li className='list-item'>
                    Sed gravida, erat et porta sagittis, ante
                  </li>
                  <li className='list-item'>
                    Vestibulum tempus mi at nisi ullamcorper
                  </li>
                </ul>
              </div>
            </div>

            <div className='description-image'>
              <div className='left-img'>
                <img src='/assets/img/blog-details-1.jpg' alt='' />
              </div>
              <div className='right-img'>
                <img src='/assets/img/img-cake.jpg' alt='' />
              </div>
            </div>
            <div className='description'>
              <p>
                In eget turpis elit. Phasellus eleifend mattis felis, ac mollis
                sapien bibendum vel. Maecenas sed fringilla purus. Curabitur
                condimentum velit sem, non posuere purus dignissim ut. Donec
                tortor nisi, tempus sit amet accumsan ac, faucibus in massa. Nunc
                vel lobortis ante. Nullam aliquam tincidunt ligula, eu egestas
                diam sodales vitae.{' '}
              </p>
            </div> */
        }
      </div>
    </section>
  );
};

export default BlogDetailsContent;
