import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import BASE_URL from '../../../config/host'

const BlogSlider = () => {
  const [featuredBlogs, setFeaturedBlogs] = useState([])

  useEffect(() => {
    const ac = new AbortController()

    const loadFeaturedBlogs = async () => {
      try {
        const res = await fetch(`${BASE_URL}blog/list`, {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ featured: true, status: true, limit: 10 }),
          signal: ac.signal
        })

        const resData = await res.json()

        if (resData.status === 200) {
          setFeaturedBlogs(resData.data)
        }
      } catch (err) {
        console.log(err.message)
      }
    }

    loadFeaturedBlogs()

    return () => ac.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='blog-slider-section'>
      <div className='container'>
        <Swiper
          slidesPerView={1}
          spaceBetween={80}
          navigation
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          className='blog-slider'
        >
          {featuredBlogs.map((blog) => (
            <SwiperSlide key={blog._id}>
              <div className='blog-item'>
                <div className='blog-slider-content'>
                  <div className='title'>{blog?.title}</div>
                  <div className='reading-time d-none'>
                    <div className='icon'>
                      <i className='fa-solid fa-clock'></i>
                    </div>
                    <div className='text'>Reading time 3 min</div>
                  </div>
                  <div className='link'>
                    <Link to={`/blog-details/${blog.alias}`} className='action'>
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className='blog-slider-image'>
                  <div className='img-wrap'>
                    <img src={blog.image} alt={blog.title} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          {/* <SwiperSlide>
            <div className='blog-item'>
            <div className='blog-slider-content'>
              <div className='title'>FOOD TRUCKS : WHERE TO STOCK UP ON INGREDIENTS</div>
              <div className='reading-time'>
                <div className='icon'><i className="fa-solid fa-clock"></i></div>
                <div className='text'>Reading time 3 min</div>
              </div>
              <div className='link'>
              <Link to="/" className="action">Learn More</Link>
              </div>
            </div>
            <div className='blog-slider-image'>
              <div className='img-wrap'>
                <img src='assets/img/blog.jpg' />
              </div>
            </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='blog-item'>
            <div className='blog-slider-content'>
              <div className='title'>FOOD TRUCKS : WHERE TO STOCK UP ON INGREDIENTS</div>
              <div className='reading-time'>
                <div className='icon'><i className="fa-solid fa-clock"></i></div>
                <div className='text'>Reading time 3 min</div>
              </div>
              <div className='link'>
              <Link to="/" className="action">Learn More</Link>
              </div>
            </div>
            <div className='blog-slider-image'>
              <div className='img-wrap'>
                <img src='assets/img/blog.jpg' />
              </div>
            </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </section>
  );
};

export default BlogSlider;
