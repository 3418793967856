import React from 'react';
import './Css/Career.css';
import CareerSearch from './Components/CareerSearch';
import CareerBanner from './Components/CareerBanner';
import CareerJobList from './Components/CareerJobList';
import CareerFuture from './Components/CareerFuture';

const Career = () => {
  return (
    <>
      <CareerBanner/>
      <section className='career-search-section'>
        <div className='container'>
          <div className='section-header'>
            <h2 className='section-title'>Join<span> Dutch Daba Wala</span></h2>
          </div>
          {/* <CareerSearch/> */}
          <CareerJobList/>
        </div>
      </section>
      <CareerFuture/>
     
    </>
  );
};

export default Career;