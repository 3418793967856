import React from 'react';
import { Link } from 'react-router-dom';

const DealsBanner = () => {
  return (
    <>
      <section className='page-banner-section order-details'>
        <div className='container'>
          <div className='banner-section'>
            <div className='breadcrumb-section'>
              <div className='link'>
                <Link to="/" className="breadcrumb-link">Home</Link>
              </div>
              <div className='seperator'>/</div>
              <div className='link'>
                <Link to="/" className="breadcrumb-link">Account</Link>
              </div>
              <div className='seperator'>/</div>
              <div className='link'>
                <div className="breadcrumb-link">Deals & New Items</div>
              </div>
            </div>
            <div className='page-title'>
              <h1 className='title'>Deals & New Items</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DealsBanner;