import React from 'react';
import './Css/MyAccount.css';
import MyAccountBanner from './Components/MyAccountBanner';
import Newsletter from '../../Components/Newsletter';
import UserProfile from './Components/UserProfile';
import AccountTab from './Components/AccountTab';
import AccountOptions from './Components/AccountOptions';
//import AccountBlog from './Components/AccountBlog';
import { Navigate } from 'react-router-dom';


const MyAccount = () => {
  if(localStorage.getItem('token')){
    return (
      <>
        <MyAccountBanner/>
          <UserProfile/>
          <AccountTab/>
          <AccountOptions/>
        <Newsletter/>
      </>
    );
  }
  else{
    return (
    <Navigate to='/sign-in' replace={true} />
    );
  }
    
  
};

export default MyAccount;