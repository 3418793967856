import React from 'react';
import { Navigate } from 'react-router-dom';
import './Css/OrderHistory.css'
import OrderHistoryBanner from './Components/OrderHistoryBanner';
import OrderList from './Components/OrderList';
import Newsletter from '../../Components/Newsletter';
const OrderHistory = () => {
  if(localStorage.getItem('token')){
    return (
      <>
        <OrderHistoryBanner/>
        <OrderList/>
        <Newsletter/>
      </>
    );
  }else{
    return (
      <Navigate to='/' replace={true} />
    );
  }
};

export default OrderHistory;