import React, { useState, useEffect } from "react";
import Pagination from "../../../Components/Pagination";

import { addedToCartApi } from "../../../helpers/cart_helper";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

// import MerchandiseItem from './MerchandiseItem';

import axios from "axios";
import { useMemo } from "react";
import BASE_URL from "../../../config/host";
import { useDispatch } from "react-redux";
import { updateCartList } from "../../../store/cartlist/cartlist";

const RewardsMerchandise = () => {
  const [storeId, setUserId] = useState("634e739a7732d77728294d89");
  const dispatch = useDispatch();
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };

    axios
      .post(
        BASE_URL + "products/list",
        { status: 1, product_type: 3 },
        { headers: headers }
      )
      .then(function (response) {
        setMerchProductData(response.data.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  let PageSize = 6;
  const [merchProductData, setMerchProductData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return merchProductData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, merchProductData]);

  const [isaddedToCartCreating, setaddedToCartCreating] = useState(false);
  const addedToCart = async (
    productId,
    itemType,
    buyingMode,
    storeId = null,
    productSpiceLevel = null,
    addedOnArray = null
  ) => {
    setaddedToCartCreating(true);
    const returnData = await addedToCartApi(
      productId,
      itemType,
      buyingMode,
      storeId,
      productSpiceLevel,
      addedOnArray
    );
    if (returnData.data.status === 400) {
      toast.error(returnData.data.message, { theme: "colored" });
      setaddedToCartCreating(false);
    }
    if (returnData.data.status === 200 || returnData.data.status === 201) {
      toast.success(returnData.data.message, { theme: "colored" });
      dispatch(updateCartList(returnData.data));
      setaddedToCartCreating(false);
    }
  };

  return (
    <>
      <ToastContainer theme="colored" />
      <section className="merchandise-section">
        <div className="container">
          <h2 className="merchandise-title">
            Merchandise <span>Collection</span>
          </h2>
          <div className="merchandise-list">
            {currentTableData &&
              currentTableData.map((item, i) => {
                return (
                  <div className="merchandise-item" key={i}>
                    <div className="merchandise-inner">
                      <div className="info-icon">
                        <i className="icon-i-icon"></i>
                      </div>
                      <div className="menu-image">
                        <img src={item?.image} alt="" />
                      </div>

                      <div className="description">
                        <div className="title">{item?.title}</div>
                        <div className="content-item">
                          <ul className="list">
                            <li className="item-text">
                              <div className="icon">
                                <i className="fa-light fa-circle-arrow-right"></i>
                              </div>
                              <div className="text">
                                {item?.long_description}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="menu-order-action">
                        <button className="add-to-cart" type="button">
                          <div className="points">{item?.price_point}</div>
                          <div className="text">Points</div>
                        </button>
                      </div>
                    </div>
                    <div className="cart-action">
                      {item.in_stock === 1 ? (
                        <button
                          className="cart-btn"
                          type="button"
                          onClick={(e) => {
                            addedToCart(
                              item?._id,
                              1,
                              2,
                              storeId
                            );
                          }}
                          disabled={isaddedToCartCreating}
                        >
                          Add to cart
                        </button>
                      ) : (
                        <button className="cart-btn" type="button">
                          {item?.in_stock === 2 ? (
                            <div className="text">Out of Stock</div>
                          ) : item?.in_stock === 3 ? (
                            <div className="text">Today out of Stock</div>
                          ) : (
                            ""
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          {/* <Pagination/> */}
        </div>
        {merchProductData && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={merchProductData?.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </section>
    </>
  );
};

export default RewardsMerchandise;
