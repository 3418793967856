import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
//import ProtectedRoute from '../src/Pages/ProtectedRoute';
import { ProtectedRoute } from './auth/ProtectedRoute';
import ScrollToTop from './ScrollToTop';
import DefaultLayout from './Layouts/DefaultLayout';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Terms from './Pages/Terms/Terms';
import Catering from './Pages/Catering/Catering';
import Blog from './Pages/Blog/Blog';
import BlogDetails from './Pages/BlogDetails/BlogDetails';
import Career from './Pages/Career/Career';
import CareerApply from './Pages/CareerApply/CareerApply';
import Contact from './Pages/Contact/Contact';
import FAQ from './Pages/FAQ/FAQ';
import ReferFriend from './Pages/ReferFriend/ReferFriend';
import ErrorPage from './Components/ErrorPage';
import SplashScreen from './Components/SplashScreen';
import LoyaltyProgram from './Pages/LoyaltyProgram/LoyaltyProgram';
import OrderDetails from './Pages/OrderDetails/OrderDetails';
import Menu from './Pages/MenuPage/Menu';
import OrderHistory from './Pages/OrderHistory/OrderHistory';
import EditAccount from './Pages/EditAccount/EditAccount';
import Deals from './Pages/Deals/Deals';
import MyAccount from './Pages/MyAccount/MyAccount';
import AccountRewards from './Pages/AccountRewards/AccountRewards';
import SignIn from './Pages/SignIn/SignIn';
import SignUp from './Pages/SignUp/SignUp';
import ForgetPassword from './Pages/ForgetPassword/ForgetPassword';
import Milestone from './Pages/Milestone/Milestone';
import DeliveryMethods from './Pages/DeliveryMethod/DeliveryMethods';
import DeliveryRegistration from './Pages/DeliveryRegistration/DeliveryRegistration';
import DeliveryOrder from './Pages/DerliveryOrder/DeliveryOrder';
import ContactDetails from './Pages/ContactDetails/ContactDetails';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import PointHistory from './Pages/AccountRewards/Components/PointHistory';
import { date } from 'yup';
import GetGoogleUserData from './Components/GetGoogleUserData';

const CreateRoutes = () => {

  //Set session Cart Id
  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  function generateString(length) {
    const newDate = new Date();
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result+newDate.getTime();
  }
  const newCartId = generateString(20)
  const checkCartId = localStorage.getItem('sessionCartId');
  if(!checkCartId){
    localStorage.setItem('sessionCartId', newCartId);
  }


  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<DefaultLayout />}>
            <Route path='/' exact element={<Home />} />
          </Route>
          <Route path='/about' element={<DefaultLayout />}>
            <Route path='/about' exact element={<About />} />
          </Route>
          <Route path='/terms-conditions' element={<DefaultLayout />}>
            <Route path='/terms-conditions' exact element={<Terms />} />
          </Route>
          <Route path='/privacy-policy' element={<DefaultLayout />}>
            <Route path='/privacy-policy' exact element={<PrivacyPolicy />} />
          </Route>
          <Route path='/catering' element={<DefaultLayout />}>
            <Route path='/catering' exact element={<Catering />} />
          </Route>
          <Route path='/blog' element={<DefaultLayout />}>
            <Route path='/blog' exact element={<Blog />} />
          </Route>
          <Route path='/blog-details/:alias' element={<DefaultLayout />}>
            <Route path='/blog-details/:alias' element={<BlogDetails />} />
          </Route>
          <Route path='/career' element={<DefaultLayout />}>
            <Route path='/career' exact element={<Career />} />
          </Route>
          <Route path='/career-apply/:slug' element={<DefaultLayout />}>
            <Route path='/career-apply/:slug' exact element={<CareerApply />} />
          </Route>
          <Route path='/contact' element={<DefaultLayout />}>
            <Route path='/contact' exact element={<Contact />} />
          </Route>

          <Route path='/error-page' >
            <Route path='/error-page' exact element={<ErrorPage />} />
          </Route>
          <Route path='/splash-screen' >
            <Route path='/splash-screen' exact element={<SplashScreen />} />
          </Route>
          <Route path='/faqs' element={<DefaultLayout />}>
            <Route path='/faqs' exact element={<FAQ />} />
          </Route>
          <Route path='/refer-friend' element={<DefaultLayout />}>
            <Route path='/refer-friend' exact element={<ReferFriend />} />
          </Route>
          <Route path='/loyalty-program' element={<DefaultLayout />}>
            <Route path='/loyalty-program' exact element={<LoyaltyProgram />} />
          </Route>
          <Route path='/order-details' element={<ProtectedRoute><DefaultLayout /></ProtectedRoute>}>
            <Route path='/order-details/:id' exact element={<OrderDetails />} />
          </Route>
          <Route path='/menu' element={<DefaultLayout />}>
            <Route path='/menu' exact element={<Menu />} />
          </Route>
          <Route path='/order-history' element={<ProtectedRoute><DefaultLayout /></ProtectedRoute>}>
            <Route path='/order-history' exact element={<OrderHistory />} />
          </Route>
          <Route path='/edit-account' element={<ProtectedRoute><DefaultLayout /></ProtectedRoute>}>
            <Route path='/edit-account' exact element={<EditAccount />} />
          </Route>
          <Route path='/account' element={<ProtectedRoute><DefaultLayout /></ProtectedRoute>}>
            <Route path='/account' exact element={<MyAccount />} />
          </Route>
          <Route path='/account-rewards' element={<ProtectedRoute><DefaultLayout /></ProtectedRoute>}>
            <Route path='/account-rewards' exact element={<AccountRewards />} />
          </Route>
          <Route path='/deals' element={<DefaultLayout />}>
            <Route path='/deals' exact element={<Deals />} />
          </Route>
          <Route path='/sign-in' >
            <Route path='/sign-in' exact element={<SignIn/>} />
          </Route>
          <Route path='/milestone' element={<DefaultLayout />}>
            <Route path='/milestone' exact element={<Milestone />} />
          </Route>
          <Route path='/sign-up' >
            <Route path='/sign-up' exact element={<SignUp/>} />
          </Route>
          <Route path='/forgot-password' >
            <Route path='/forgot-password' exact element={<ForgetPassword/>} />
          </Route>
          <Route path='/cart'  element={<DefaultLayout />}>
            <Route path='/cart' exact element={<DeliveryMethods/>} />
          </Route>
          <Route path='/delivery-registration'  element={<DefaultLayout />}>
            <Route path='/delivery-registration' exact element={<DeliveryRegistration/>} />
          </Route>
          <Route path='/order-delivery'  element={<DefaultLayout />}>
            <Route path='/order-delivery' exact element={<DeliveryOrder/>} />
          </Route>
          <Route path='/contact-details/:id'  element={<DefaultLayout />}>
            <Route path='/contact-details/:id' exact element={<ContactDetails/>} />
          </Route>
          <Route path='/point-transaction-history' element={<ProtectedRoute><DefaultLayout /></ProtectedRoute>}>
            <Route path='/point-transaction-history' exact element={<PointHistory />} />
          </Route>
          <Route path='/get-user-data' exact element={<GetGoogleUserData />} />
          
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;