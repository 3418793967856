import React from 'react';
import Newsletter from '../../Components/Newsletter';
import ContactBanner from './Components/ContactBanner';
import ContactUsForm from './Components/ContactUsForm';
import HelpFeature from './Components/HelpFeature';
import HelpSearch from './Components/HelpSearch';
import Location from './Components/Location';
import './Css/Contact.css'

const Contact = () => {
  return (
    <>
    <ContactBanner/>
    {/* <HelpSearch/> */}
    {/* <HelpFeature/> */}
    <ContactUsForm/>
    <Location/>
    <Newsletter/>
    </>
  );
};

export default Contact;