import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { fetchPages } from "../../../store/page/page";




const HomeAbout = () => {
  const [aboutdata, setAboutData] = useState([]);
  


  




  useEffect(() => {
    const loadpage = async() => {
      try {
       setAboutData([]);
       //const featured =  props.featured?props.featured:false;
         const  list = await fetchPages({slug:"dutch-dabba-wala"});
         setAboutData(list);
        } catch (error) {
         
        }
     }
     loadpage();
   }, []);
 
  // console.log(fetchPages);
  function createMarkup() {
    return { __html: aboutdata?.description };
  }
  return (
    <>
      <section className="section-about">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 offset-lg-5">
              <div className="about-content">
                <h2 className="about-title">{aboutdata.title}</h2>
                <div className="about-image">
                  <LazyLoad>
                    <img src={aboutdata?.image} alt="" />
                  </LazyLoad>
                </div>
                <div className="about-text">
                  <div dangerouslySetInnerHTML={createMarkup()} />
                </div>
                <div className="about-action">
                  <Link to="/about" className="btn-action">
                    {aboutdata.button_name}
                  </Link>
                </div>
              </div>
              <div className="about-watermark">
                <img src="assets/img/logo.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
