import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchTeams } from "../../../store/Team/team";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SecTeam = () => {
  const dispatch = useDispatch();
  const { teamlist } = useSelector((state) => state.teams);

  useEffect(() => {
    dispatch(
      fetchTeams({
        status: true,
        limit: 9,
        sortByField: 'ordering',
        sortByValue: 1
      })
    );
  }, [dispatch]);
  return (
    <section className="team-section">
      <div className="container">
        <div className="section-header">
          <h2 className="section-title">
            The<span> team</span>
          </h2>
        </div>
        <div className="team-slider-wrap">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            navigation
            modules={[Navigation, Pagination]}
            pagination={{
              clickable: true,
            }}
            className="team-slider"
          >
            {teamlist.length > 0 &&
              teamlist.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="team-item">
                      <div className="team-inner">
                        <div className="team-image">
                        <img src={item?.image} alt=''/>
                        </div>
                        <div className="team-content">
                          <div className="team-author">{item.name}</div>
                          <div className="team-designation">{item.designation}</div>
                          <div className="description">
                            <p>{item.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default SecTeam;
