
import { useState, useEffect } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import BASE_URL from '../../../config/host';




const DeliverRegistrationForm = () => {
  const history = useNavigate();
  const sessionCartId = localStorage.getItem('sessionCartId');

  const [userType, setUserType] = useState(1);
  const [tncAcctept, setTncAcctept] = useState(false);
  const [tncAccteptGuest, setTncAccteptGuest] = useState(false);
  const [isCreating, setCreating] = useState(false)


  const handleTNCAcceptChange = event => {
    setTncAcctept(current => !current);
  };

  const handleTNCAcceptChangeGuest = event => {
    setTncAccteptGuest(current => !current);
  };


  const handleNewGuestUserEnterchange = (event) => {
    setUserType(Number(event.target.value));
  };

  function setToken(userToken) {
    localStorage.setItem("token", JSON.stringify(userToken));
  }

  const [nameUser, setNameUser] = useState('');
  const [phoneUser, setPhoneUser] = useState('');
  const [zipCodeUser, setZipCodeUser] = useState('');
  const [houseNumberUser, setHouseNumberUser] = useState('');
  const [additionalUser, setAdditionalUser] = useState('');
  const [addressUser, setAddressUser] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [passwordUser, setPasswordUser] = useState('');
  const [cnfPasswordUser, setCnfPasswordUser] = useState('');

  const [nameGuest, setNameGuest] = useState('');
  const [phoneGuest, setPhoneGuest] = useState('');
  const [zipCodeGuest, setZipCodeGuest] = useState('');
  const [houseNumberGuest, setHouseNumberGuest] = useState('');
  const [additionalGuest, setAdditionalGuest] = useState('');
  const [addressGuest, setAddressGuest] = useState('');
  const [emailGuest, setEmailGuest] = useState('');

  
  const handleSubmitUser = async (e) => {
    e.preventDefault();
    setCreating(true);

    if(tncAcctept){
      const data = {
        account_type : userType,
        name : nameUser,
        phone : phoneUser,
        zipcode: zipCodeUser,
        house_no: houseNumberUser,
        additional: additionalUser,
        address: addressUser,
        email:emailUser,
        password : passwordUser,
        confirm_password : cnfPasswordUser,
        cart_id : sessionCartId,    
      }
      axios
        .post(`${BASE_URL}checkout/create-account`, {
          ...data
        })
        .then(function (response) {
          if (response.data.status === 400) {
             toast.error(response.data.message, { theme: "colored" });
             setCreating(false);
          }
          if (response.data.status === 200 || response.data.status === 201) {
            toast.success(response.data.message, { theme: "colored" });
            setToken(response.data.access_token);
            history("/cart");
            setCreating(false);
          }
        })
        .catch(function (error) {
          toast.error(error.message, { theme: "colored" });
        });
    }else{
      toast.error('You must accept the terms and conditions.', { theme: "colored" });
      setCreating(false);
    }
  };



  const handleSubmitguest = async (e) => {
    e.preventDefault();
    setCreating(true);

    if(tncAccteptGuest){
      const data = {
        account_type : userType,
        name : nameGuest,
        phone : phoneGuest,
        zipcode: zipCodeGuest,
        house_no: houseNumberGuest,
        additional: additionalGuest,
        address: addressGuest,
        email:emailGuest,
        cart_id : sessionCartId,
      }
      axios
        .post(`${BASE_URL}checkout/create-account`, {
          ...data
        })
        .then(function (response) {
          if (response.data.status === 400) {
             toast.error(response.data.message, { theme: "colored" });
             setCreating(false);
          }
          if (response.data.status === 200 || response.data.status === 201) {
            toast.success(response.data.message, { theme: "colored" });
            setToken(response.data.access_token);
            history("/cart");
            setCreating(false);
          }
        })
        .catch(function (error) {
          toast.error(error.message, { theme: "colored" });
        });
    }else{
      toast.error('You must accept the terms and conditions.', { theme: "colored" });
      setCreating(false);
    }
  };


  return (
    <>
      <ToastContainer theme="colored" />
      <section className='delivery-registration-section'>
        <div className='container'>
          <div className='regitration-form-content'>
            <div className='description'>
              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here.</p>
            </div>
            <div className='form-part'>
              <div className='registration-form-section'>
                <div className='form-options'>
                  <div className='option'>
                    <div className="custom-radio">
                      <input type="radio" className="custom-radio-input" name="address" value="1" onChange={(e)=>handleNewGuestUserEnterchange(e)} checked={(userType==1) ? true : false}/>
                      <label className="custom-radio-label">
                        <div className='text'>Create Account</div></label>
                    </div>
                  </div>
                  <div className='option'>
                    <div className="custom-radio">
                      <input type="radio" className="custom-radio-input" value="2" onChange={(e)=>handleNewGuestUserEnterchange(e)} name="address" checked={(userType==2) ? true : false} />
                      <label className="custom-radio-label">
                        <div className='text'>Guest Account</div></label>
                    </div>
                  </div>
                  <div className='option'>
                    <Link className="go-signin-btn" to="/sign-in?_trgt=cart">Have an account?</Link>
                  </div>
                </div>


                {
                  (userType === 1) &&
                  (
                    <div className='form-tab-section active'>
                      <form className='form-content' onSubmit={handleSubmitUser}>
                        <div className='form-row'>
                          <div className='col-md-12'>
                            <div className="input-style">
                              <div className='form-label'>Name*</div>
                              <input type="text" className="form-control" value={nameUser}  onChange={e => setNameUser(e.target.value)} />
                            </div>
                          </div>
                        </div>
                        <div className="input-style">
                          <div className='form-label'>Phone*</div>
                          <input type="number" className="form-control" value={phoneUser}  onChange={e => setPhoneUser(e.target.value)} />
                        </div>
                        <div className='form-row'>
                          <div className='col-md-4'>
                            <div className="input-style">
                              <div className='form-label'>Postal code*</div>
                              <input type="text" className="form-control" value={zipCodeUser}  onChange={e => setZipCodeUser(e.target.value)} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className="input-style">
                              <div className='form-label'>House Number</div>
                              <input type="text" className="form-control" value={houseNumberUser}  onChange={e => setHouseNumberUser(e.target.value)} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className="input-style">
                              <div className='form-label'>Additional</div>
                              <input type="text" className="form-control" value={additionalUser}  onChange={e => setAdditionalUser(e.target.value)} />
                            </div>
                          </div>
                        </div>

                        <div className="input-style">
                          <input type="text" className="form-control" placeholder='Address' value={addressUser}  onChange={e => setAddressUser(e.target.value)} />
                        </div>

                        <div className="input-style">
                          <div className='form-label'>E-mail*</div>
                          <input type="email" className="form-control" value={emailUser}  onChange={e => setEmailUser(e.target.value)} />
                        </div>

                        <div className="input-style">
                          <div className='form-label'>Password*</div>
                          <input type="password" className="form-control" value={passwordUser}  onChange={e => setPasswordUser(e.target.value)} />
                        </div>
                        <div className="input-style">
                          <div className='form-label'>Confirm Password*</div>
                          <input type="password" className="form-control" value={cnfPasswordUser}  onChange={e => setCnfPasswordUser(e.target.value)} />
                        </div>

                        <div className='form-condition'>
                          <div className="custom-checkbox">
                            <input type="checkbox" className="custom-checkbox-input" checked={tncAcctept} onChange={handleTNCAcceptChange}/>
                              <label  className="custom-checkbox-label"></label>
                          </div>
                          <div className='text'>It is a long <span>established fact</span> that a reader will be distracted by the readable content of a page when looking at its layout.</div>
                        </div>

                        <div className='form-action-submit'>
                          <button 
                          type="submit" 
                          className='btn-register'
                          disabled = {isCreating}
                          >
                            {isCreating && (
                              <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
                            )}
                            Register
                          </button>
                        </div>


                      </form>
                      <div className='form-details-section'>
                        <div className='form-details-list'>
                          <div className='title'>Why Create Account?</div>
                          <ul className='item-conent'>
                            <li className='list'>
                              <div className='icon'><i className="fa-solid fa-check"></i></div>
                              <div className='text'><span>Discount</span> is a long established fact that a reader will be distracted</div>
                            </li>
                            <li className='list'>
                              <div className='icon'><i className="fa-solid fa-check"></i></div>
                              <div className='text'><span>News </span> is a long established fact that a reader will be distracted</div></li>
                            <li className='list'>
                              <div className='icon'><i className="fa-solid fa-check"></i></div>
                              <div className='text'><span>Stuff</span>  is a long established fact that a reader will be distracted</div>
                            </li>
                            <li className='list'>
                              <div className='icon'><i className="fa-solid fa-check"></i></div>
                              <div className='text'><span>More Benefits</span>is a long established fact that a reader will be distracted</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  (userType === 2) &&
                  (
                    <div className='form-tab-section active'>
                      <form className='form-content' onSubmit={handleSubmitguest}>
                        <div className='form-row'>
                          <div className='col-md-12'>
                            <div className="input-style">
                              <div className='form-label'>Name*</div>
                              <input type="text" className="form-control" value={nameGuest}  onChange={e => setNameGuest(e.target.value)} />
                            </div>
                          </div>
                        </div>
                        <div className="input-style">
                          <div className='form-label'>Phone*</div>
                          <input type="number" className="form-control" value={phoneGuest}  onChange={e => setPhoneGuest(e.target.value)} />
                        </div>
                        <div className='form-row'>
                          <div className='col-md-4'>
                            <div className="input-style">
                              <div className='form-label'>Postal code*</div>
                              <input type="text" className="form-control" value={zipCodeGuest}  onChange={e => setZipCodeGuest(e.target.value)} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className="input-style">
                              <div className='form-label'>House Number</div>
                              <input type="text" className="form-control" value={houseNumberGuest}  onChange={e => setHouseNumberGuest(e.target.value)} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className="input-style">
                              <div className='form-label'>Additional</div>
                              <input type="text" className="form-control" value={additionalGuest}  onChange={e => setAdditionalGuest(e.target.value)} />
                            </div>
                          </div>
                        </div>

                        <div className="input-style">
                          <input type="text" className="form-control" placeholder='Address' value={addressGuest}  onChange={e => setAddressGuest(e.target.value)} />
                        </div>

                        <div className="input-style">
                          <div className='form-label'>E-mail*</div>
                          <input type="email" className="form-control" value={emailGuest}  onChange={e => setEmailGuest(e.target.value)} />
                        </div>

                        <div className='form-condition'>
                          <div className="custom-checkbox">
                            <input type="checkbox" className="custom-checkbox-input" checked={tncAccteptGuest} onClick={handleTNCAcceptChangeGuest}/>
                              <label  className="custom-checkbox-label"></label>
                          </div>
                          <div className='text'>It is a long <span>established fact</span> that a reader will be distracted by the readable content of a page when looking at its layout.</div>
                        </div>

                        <div className='form-action-submit'>
                        <button 
                          type="submit" 
                          className='btn-register'
                          disabled = {isCreating}
                          >
                            {isCreating && (
                              <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
                            )}
                            Register
                          </button>
                        </div>


                      </form>
                      <div className='form-details-section'>
                        <div className='form-details-list'>
                          <div className='title'>Why Guest Account?</div>
                          <ul className='item-conent'>
                            <li className='list'>
                              <div className='icon'><i className="fa-solid fa-fw fa-xmark"></i></div>
                              <div className='text'><span>Can’t Discount</span> is a long established fact that a reader will be distracted</div>
                            </li>
                            <li className='list'>
                              <div className='icon'><i className="fa-solid fa-fw fa-xmark"></i></div>
                              <div className='text'><span>Can’t  News </span> is a long established fact that a reader will be distracted</div></li>
                            <li className='list'>
                              <div className='icon'><i className="fa-solid fa-fw fa-xmark"></i></div>
                              <div className='text'><span>Can’t  Stuff</span>  is a long established fact that a reader will be distracted</div>
                            </li>
                            <li className='list'>
                              <div className='icon'><i className="fa-solid fa-fw fa-xmark"></i></div>
                              <div className='text'><span>Can’t  More Benefits </span>is a long established fact that a reader will be distracted</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                






                

              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeliverRegistrationForm;
