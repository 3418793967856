import React from 'react';
import './Css/ContactDetails.css'
import SectionContactDetails from './Components/SectionContactDetails';
import ContactDetailsBanner from './Components/ContactDetailsBanner';
import ContactDetailsHelp from './Components/ContactDetailsHelp';
import Newsletter from '../../Components/Newsletter';

const ContactDetails = () => {
    return (
        <>
            
            <ContactDetailsBanner/>
            {/* <ContactDetailsHelp/> */}
            <SectionContactDetails/>
            <Newsletter/>
        </>
    );
};

export default ContactDetails;