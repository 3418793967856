
import React, { useState, useEffect } from 'react';
import "react-toastify/dist/ReactToastify.css";
import BASE_URL from '../../../config/host';
import axios from 'axios';


const UserProfile = () => {
  const [profileData, setProfileData] = useState();
  const [point, setPoint] = useState();
  const [totalPoint, setTotalPoint] = useState();
 

  useEffect( ()=>{
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }

    axios.post(`${BASE_URL}profile-details`,{  },{headers: headers} )
      .then(function (response) {
      setProfileData(response.data.data);
    })
    .catch(function (error) {
      console.log(error);
    });

    axios.post(`${BASE_URL}site-setting/details`,{ id: "624c1d79ac364e5ef3796d76" },{} )
      .then(function (response) {
      setPoint(response.data.data.point_per_euro);
      //console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

    axios.post(BASE_URL + 'total-point',{ },{headers: headers} )
    .then(function (response) {
      setTotalPoint(response.data);
      //console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
  },[]);
  

 //console.log(totalPoint);

  return (
    <>
      <section className='user-profile-section'>
        <div className='container'>
        
              <div className='profile-form'>
                <div className='profile-image-edit'>
                  <div className='no-image'><img src={profileData?.image} alt='' /></div>
                  {/* <input name="image" type="file" id="file-input" className="upload-file" /><label className="file-input-label" htmlFor="file-input">
                    <div className='no-image'><img src={profileData?.image} alt='' /></div>
                    <div className='icon'>
                      <i className="fa-light fa-pencil"></i>
                    </div>
                  </label> */}
                </div> 
                <div className='profile-content'>
                  <div className='intro'>Hi <span>{profileData?.name},</span> you have</div>
                  <div className='points'><span>{totalPoint?.total_point}</span> Points</div>
                  <div className='points-calculation'><span>1 €</span> = <span>{point}</span>Points</div>
                </div>
              </div>
            </div>
         
      </section>
    </>
  );
};

export default UserProfile;