import React from 'react';
import { Navigate } from 'react-router-dom';
import './Css/OrderDetails.css'
import OrderDetailsBanner from './Components/OrderDetailsBanner';
import OrderSection from './Components/OrderSection';

const OrderDetails = () => {
  if(localStorage.getItem('token')){
    return (
      <>
        <OrderDetailsBanner/>
        <OrderSection/>
      </>
    );
  }else{
    return (
      <Navigate to='/' replace={true} />
    );
  }
};

export default OrderDetails;