import React from 'react';
import { NavLink } from 'react-router-dom';
const ReferBanner = () => {
  return (
    <>
      <section className='page-banner-section'>
        <div className='container'>
          <div className='banner-section'>
            <div className='breadcrumb-section'>
              <div className='link'>
                <NavLink to="/" className="breadcrumb-link">Home</NavLink>
              </div>
              <div className='seperator'>/</div>
              <div className='link'>
                <NavLink to="/" className="breadcrumb-link">Account</NavLink>
              </div>
              <div className='seperator'>/</div>
              <div className='link'>
                <div className="breadcrumb-link">Refer Friend</div>
              </div>
            </div>
            <div className='page-title'>
              <h1 className='title'>Refer Friend</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferBanner;