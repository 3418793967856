import React from 'react';
import Newsletter from '../../Components/Newsletter';
import BlogBanner from './Components/BlogBanner';
import BlogSec from './Components/BlogSec';
import BlogSlider from './Components/BlogSlider';
import './Css/Blog.css'

const Blog = () => {
  return (
    <>
      <BlogBanner/>
      <BlogSlider/>
      <BlogSec/>
      <Newsletter/>
    </>
  );
};

export default Blog;