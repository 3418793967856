import React from 'react';
import {useState } from 'react';
const FaqListItem = ({ Question, Answer }) => {
  const [active, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!active);
  }
  return (
    <>
      <div className={`faqitem ${active ? ' active' : ''}`} onClick={toggleClass}>
        <div className='faq-item-title'>
          <h6 className='text'>{Question}</h6>
        </div>
        <div className='faq-content'>
          <div className='content'>
            <div className='descriptions'>
              <div dangerouslySetInnerHTML={{__html: Answer}}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqListItem;
