import React,{ useState } from 'react';
import './Css/Rewards.css'
import RewardsBanner from './Components/RewardsBanner';
import RewardsProfile from './Components/RewardsProfile';
import RewardsTab from './Components/RewardsTab';
import Newsletter from '../../Components/Newsletter';
import RewardsFoods from './Components/RewardsFoods';
import RewardsPointsConversion from './Components/RewardsPointsConversion';
import RewardsMerchandise from './Components/RewardsMerchandise';
import PointsDonation from './Components/PointsDonation';

const AccountRewards = () => {
  const [rewardsTab, setRewardsTab] = useState('spendPoint');
  const tabMenu = [
    { 
      name: 'Spend Points', 
      active: false, 
    },
    { 
      name: 'Mildstone Challenges', 
      active: false, 
    },
    { 
      name: 'How Rewards works', 
      active: false, 
    },
    { 
      name: 'Points Conversion', 
      active: false, 
    },
  ];

  const [active, setActive] = useState(1);
  return (
    <>
      <RewardsBanner/>
      <RewardsProfile/>

      <RewardsTab rewardsTab={rewardsTab}  setRewardsTab={setRewardsTab} active={active} setActive={setActive}/>

      {/* <RewardsMenus/> */}
      {active !== 4 && <section className='rewards-menu-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='left-side-menu' style={{cursor: "pointer"}} onClick={() => {setActive(2); window.scrollTo({ top: 1700, behavior: 'smooth'});}}>
                <div className='left-side-menu-link'>
                  <div className='image'>
                    <img src='assets/img/rewards-fanshop.svg' alt='' />
                  </div>
                  <div className='menu-text'>Fanshop</div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='row'>
                <div className='col-12'>
                  <div className='right-side-menu-link'  style={{cursor: "pointer"}}  onClick={() => {setActive(1); window.scrollTo({ top: 1700, behavior: 'smooth'}); }}>
                    <div className='image'>
                      <img src='assets/img/free-food.svg' alt='' />
                    </div>
                    <div className='menu-text'>Free Food</div>
                  </div>
                </div>
                <div className='col-12 mt-4'>
                  <div className='right-side-menu-link' style={{cursor: "pointer"}} onClick={() => {setActive(3); window.scrollTo({ top: 1700, behavior: 'smooth'});}}>
                    <div className='image'>
                      <img src='assets/img/rewards-charity.svg' alt='' />
                    </div>
                    <div className='menu-text'>Spend on charity</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}

      {active === 1  && <div id='rfood'><RewardsFoods /></div>}
      {active === 2 && <RewardsMerchandise/>}
      {active === 3 && <PointsDonation/>}
      {active === 4 && <RewardsPointsConversion />}

      <Newsletter/>
    </>
  );
};

export default AccountRewards;