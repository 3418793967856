import React, { useState, useEffect } from "react";
import Pagination from "../../../Components/Pagination";
import { addedToCartApi } from "../../../helpers/cart_helper";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
// import RewardsFoodItem from './RewardsFoodItem';
import axios from "axios";
import { useMemo } from "react";
import BASE_URL from "../../../config/host";
import { useDispatch } from "react-redux";
import { updateCartList } from "../../../store/cartlist/cartlist";

const RewardsFoods = () => {
  const [storeId, setUserId] = useState("634e739a7732d77728294d89");
  const dispatch = useDispatch();
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };

    axios
      .post(
        BASE_URL + "products/list",
        { status: 1, product_type: 1, price_point: true },
        { headers: headers }
      )
      .then(function (response) {
        setPointFoodData(response.data.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  let PageSize = 6;
  const [pointFoodData, setPointFoodData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return pointFoodData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pointFoodData]);

  console.log(currentTableData);

  const [isaddedToCartCreating, setaddedToCartCreating] = useState(false);
  const addedToCart = async (
    productId,
    itemType,
    buyingMode,
    storeId = null,
    productSpiceLevel = null,
    addedOnArray = null
  ) => {
    setaddedToCartCreating(true);
    const returnData = await addedToCartApi(
      productId,
      itemType,
      buyingMode,
      storeId,
      productSpiceLevel,
      addedOnArray
    );
    if (returnData.data.status === 400) {
      toast.error(returnData.data.message, { theme: "colored" });
      setaddedToCartCreating(false);
    }
    if (returnData.data.status === 200 || returnData.data.status === 201) {
      toast.success(returnData.data.message, { theme: "colored" });
      dispatch(updateCartList(returnData.data));
      setaddedToCartCreating(false);
    }
  };

  return (
    <>
      <ToastContainer theme="colored" />
      <section className="rewards-foods-section">
        <div className="container">
          <h2 className="rewards-title">
            Food <span>with points</span>
          </h2>
          <div className="rewards-foods-part">
            <div className="foods-list">
              {currentTableData &&
                currentTableData.map((item, i) => {
                  return (
                    <div className="food-item" key={i}>
                      <div className="food-inner">
                        <div className="ingredients">
                          <div className="list-contains">
                            {currentTableData.allergie &&
                              currentTableData.allergie.map((v, index) => {
                                return (
                                  <div className="icon">
                                    <i className={v.name} key={index}></i>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="name">{item?.title}</div>
                        <div className="menu-image">
                          <img src={item?.image} alt="" />
                        </div>

                        <div className="description">
                          <div
                            dangerouslySetInnerHTML={{ __html: item?.title }}
                          />
                        </div>

                        <div className="menu-order-action">
                          <button className="add-to-cart" type="button">
                            <div className="points">{item?.price_point}</div>
                            <div className="text">Points</div>
                          </button>
                        </div>
                      </div>
                      <div className="cart-action">
                        {item.in_stock === 1 ? (
                          <button
                            className="cart-btn"
                            type="button"
                            onClick={(e) => {
                              addedToCart(
                                item?._id,
                                1,
                                2,
                                storeId
                              );
                            }}
                            disabled={isaddedToCartCreating}
                          >
                            Add to cart
                          </button>
                        ) : (
                          <button className="cart-btn" type="button">
                            {item?.in_stock === 2 ? (
                              <div className="text">Out of Stock</div>
                            ) : item?.in_stock === 3 ? (
                              <div className="text">Today out of Stock</div>
                            ) : (
                              ""
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {pointFoodData?.length > PageSize && (
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={pointFoodData?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default RewardsFoods;
