import React from 'react';
import SignInSection from './Components/SignInSection';
import './Css/SignIn.css';
import { Navigate } from 'react-router-dom';

const SignIn = () => {

        if(localStorage.getItem('token')){
            return (
                <Navigate to='/account' replace={true} />
            );
          }
          else{
            return (
                <SignInSection/>
            );
          };
}

export default SignIn;