import React, { useState } from 'react';
import axios from "axios";
import BASE_URL from '../../../config/host';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
const ContactDetailsItem = ({ Question, Answer, KeyId, Cat_id}) => {
    const [active, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!active);
    }

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
    
    const handleFeedBack = (e, feedback,index) => {
        e.preventDefault();
        axios.post(`${BASE_URL}/faq/send-feedback`, {
          feedback: feedback,
          faq_id : index,
          category_id: Cat_id,
        },{headers: headers})
        .then(function (response) {
          if (response.data.status === 400) {
            toast.error(response.data.message, { theme: "colored" });
          }
          if (response.data.status === 200) {
            toast.success(response.data.message, { theme: "colored" });
          }
        })
        .catch(function (error) {
          toast.error(error.message, { theme: "colored" });
        });
      }


    return (
        <>
            <div className={`detailsitem ${active ? ' active' : ''}`} >
                <div className="details-item-title" onClick={toggleClass}  style={{cursor: 'pointer'}}>
                    <h6 className='text'>{Question}</h6>
                </div>
                <div className='details-content'>
                    <div className='content'>
                    <div dangerouslySetInnerHTML={{__html: Answer}}/>
                        {/* <div className='descriptions'>
                            <p>When you pay for an order, you'll get an email confirming your payment sent to the email address you use for your Dabba Walla account. This email includes all your payment details.</p>
                            <p>Receipts for payments on Dabba Walla include your number of orders, charges,  delivery charges, and Total amount you paid for.</p>
                            <p>If you lose your payment confirmation or need another copy, you can request another email receipt.</p>
                        </div>
                        <div className='descriptions-title'><h5>To request a receipt:</h5></div>
                        <ul className='descriptions-list'>
                            <li>1. In your Dabba Walla account, open your My Purchases page.</li>
                            <li>2. Find the order you want a receipt for.</li>
                            <li>3. In the Purchase Date column, click Email Receipt.</li>
                            <li>In your Dabba Walla account, open your My Purchases page.</li>
                        </ul>
                        <div className='descriptions'>
                            <p>Check the email address you use for your Dabba Walla account. You should get your receipt within 1 mint..</p>
                        </div>
                        <div className='descriptions-title'><h5>Unfortunately, Dabba Walla cannot:</h5></div>
                        <ul>
                            <li>Customize receipts with business details (e.g., VAT number) or other information that is not included on our standard receipt.</li>
                            <li>Provide invoices in advance of payment. You can see the costs for a course or subscription by clicking Enroll on the order home page.</li>
                            <li>Mail a hard copy of your receipt.</li>
                        </ul>
                        <div className='descriptions'>
                            <p>We apologize for any inconvenience.</p>
                        </div> */}
                    </div>
                    <div className='helpfull-inner'>
                        <div className='helpfull-title'>Was this article helpful?</div>
                        <div className='emoji-content'>
                        <button type='button' className='emoji-btn' onClick={(e)=>handleFeedBack(e,true, KeyId)}>
                            <div className='icon'><i className="fa-light fa-face-smile"></i></div>
                            <div className='text'>Yes</div>
                        </button>
                        <button type='button' className='emoji-btn' onClick={(e)=>handleFeedBack(e, false, KeyId)}>
                            <div className='icon'><i className="fa-light fa-face-frown"></i></div>
                            <div className='text'>No</div>
                        </button>
                        </div>
                    </div>
                    {/* <div className='emoji-content mod'>
                        <button type='button' className='emoji-btn'>
                            <div className='icon'><i className="fa-light fa-face-smile"></i></div>
                            <div className='text'>Yes</div>
                        </button>
                        <button type='button' className='emoji-btn'>
                            <div className='icon'><i className="fa-light fa-face-frown"></i></div>
                            <div className='text'>No</div>
                        </button>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default ContactDetailsItem;