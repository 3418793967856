
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";

export const benefitSlice = createSlice({
    name: 'benefits',
    loading: false,
    initialState: {
        benefitlist: []
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.benefitlist ='';
            //console.log(action.payload);
        },
        listReceived: (state, action)=>{
            state.loading = false;
            state.benefitlist = action.payload.data?action.payload.data:[];
        }
    }
});


export const {listReceived,listRequested ,listRequestFailed } = benefitSlice.actions;
//console.log(body);
export const fetchBenefits = (body)=> apiCallBegan({
    url: '/account-benefit/list',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type
 
});














