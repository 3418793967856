import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BASE_URL from '../config/host';
import axios from 'axios';


export const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [auth, setAuth] = useState(false);
  const [checkTokenData, setCheckTokenData] = useState([]);


  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
    axios.post(`${BASE_URL}verify-jwt`,{  },{headers: headers} )
      .then(function (response) {
        setCheckTokenData(response.data);
        setIsLoader(false);
    })
    .catch(function (error) {
      setIsLoader(false);
      console.log(error);
    });
  }, []);


  let token_ = localStorage.getItem('token');
  useEffect(() => {
    if((checkTokenData.token_verified === true) && (token_) && (!isLoader)){
      //localStorage.setItem('userId', TokenData.id)
      setAuth(true);
      return
    }else if(!isLoader){
      setAuth(false);
      localStorage.removeItem('token')
      navigate('/sign-in')
    }
  }, [checkTokenData,isLoader])



  return (
    <>
    {
      auth && !isLoader && props.children
    }
    </>
  )
}

