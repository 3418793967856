import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchBenefits } from "../../../store/home/benefit";
import { fetchPages } from "../../../store/page/page";
import { useEffect } from "react";

const HomeBenefits = () => {
  const dispatch = useDispatch();
  const { benefitlist } = useSelector((state) => state.benefits);
  const [benifitHeading, setBenifitHeading] = useState([]);
  useEffect(() => {
    dispatch(fetchBenefits({ status: true, sortByField: 'ordering', sortByValue: 1 }));
    const loadpage = async () => {
      try {
        setBenifitHeading([]);
        const list = await fetchPages({ slug: "benifit-account" });
        setBenifitHeading(list);
      } catch (error) {}
    };
    loadpage();
  }, [dispatch]);
  // function createMarkup() {
  //   return { __html: benifitHeading?.description };
  // }

  console.log(benefitlist);

  return (
    <section className="section-benefits">
      <div className="benefits-image">
        <img src={benifitHeading?.image} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="benefits-content">
              <h2 className="benefits-title">
              <h2 className='benefits-title'>{benifitHeading?.title} <span>{benifitHeading?.subtitle}</span></h2>
              </h2>
              <div className="benefits">
                {benefitlist.length > 0 &&
                  benefitlist.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <div className="item-icon">
                          <img src={item.image} alt={item.image} style={{ height: "40px", borderRadius: "10px" }}/>
                        </div>
                        <div className="item-text">{item.title}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBenefits;
