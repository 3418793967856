
import React, { useEffect, useState } from 'react';
import MenuList from '../../MenuPage/Components/MenuList';
import axios from 'axios';
import BASE_URL from "../../../config/host";

const NewItems = () => {

  const [newItemList, setNewItemList] = useState();

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }

      axios.post(BASE_URL + 'v2/products/list',{ status: 1, product_type: 1},{headers: headers} )
      .then(function (response) {
        setNewItemList(response.data.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [])






  return (
    <>
      <section className='new-item-section'>
        <div className='container'>
          <div className='new-items-header'>
            <div className='section-header'>
              <h2 className='section-title'>New<span> Items</span></h2>
            </div>
            <div className='descriptions'>
              Discount is a long established fact that a reader will beDiscount is a long established fact that a reader will be
            </div>
          </div>

          

          {(newItemList && newItemList?.length > 0 ) &&
            <div className='new-items'>
              <div className='menu-part'>
                  <div className='menu-list'>
                    <MenuList newItemList = {newItemList} />
                  </div>
              </div>
            </div>
          }
        </div>

       
      </section>
    </>
  );
};

export default NewItems;