import React from 'react';
import { Navigate } from 'react-router-dom';
import './Css/DeliveryMethod.css'
import DeliveryBanner from './Components/DeliveryBanner';
import DeliveryProgress from './Components/DeliveryProgress';
import DeliveryOptions from './Components/DeliveryOptions';

const DeliveryMethods = () => {
  if(localStorage.getItem('token')){
    return(
      <>
        <DeliveryBanner/>
        <DeliveryProgress/>
        <DeliveryOptions/>
      </>
    )
  }else{
    return (
      <Navigate to='/delivery-registration' replace={true} />
    );
  }
};

export default DeliveryMethods;