import React from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import BASE_URL from '../../../config/host'

const SecForm = () => {

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
//console.log(BASE_URL);
  function handleSubmit(e) {
    e.preventDefault();
    //console.log(name, subject, email, comment);
    axios
      .post(BASE_URL + "send-enquiry", {
        name: name,
        email: email,
        subject: subject,
        message: comment
      })
      .then(function (response) {
        //console.log(response);
        if (response.data.status === 400) {
          toast.error(response.data.message, { theme: "colored" });
          //setIsLoading(false); 
        }
        if (response.data.status === 200) {
          toast.success(response.data.message, { theme: "colored" });
          //setIsLoading(false); 
          //setToken(response.data.data.access_token);
          //history("/account");
        }
      })
      .catch(function (error) {
        toast.error(error.message, { theme: "colored" });
        //setIsLoading(false); 
        //console.log(error);
      });
  }


  return (
    <>
      <ToastContainer theme="colored" />
      <section className='form-section'>
        <div className='form-img'><img src='assets/img/home-benefits-image.png' /></div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-9 offset-lg-3'>
              <div className='form-part'>
                <div className='form'>
                  <h5 className='form-title'>Form</h5>
                  <form className='form-content' onSubmit={e => handleSubmit(e)}>
                    <div className='input-style'>
                      <input type='text' className='form-control' placeholder='Name' onChange={e => setName(e.target.value)}/>
                    </div>
                    <div className='input-style'>
                      <input type='text' className='form-control' placeholder='Subject' onChange={e => setSubject(e.target.value)}/>
                    </div>
                    <div className='input-style'>
                      <input type='text' className='form-control' placeholder='E-mail' onChange={e => setEmail(e.target.value)}/>
                    </div>
                    <div className='input-style'>
                      <textarea type='text' rows="6" className='form-control message' placeholder='Message' onChange={e => setComment(e.target.value)}></textarea>
                    </div>
                    <div className='form-action'>
                      <button type='submit' className='form-btn'>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecForm;