//import React from 'react';

//import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchFutureTeam } from "../../../store/career/careerFutureTeam";
import { useEffect } from "react";
import ReactPlayer from "react-player/youtube";


const CareerFuture = () => {
  const dispatch = useDispatch();
  const { futureTeamList } = useSelector((state) => state.featureTeam);
  useEffect(() => {
    dispatch(
      fetchFutureTeam({ status: true, sortByField: "ordering", sortByValue: 1, featured:true })
    );
  }, [dispatch]);

  return (
    <>
      <div className="future-section">
        <div className="container">
          <div className="section-header">
            <h2 className="section-title">
              Your<span> Future Team</span>
            </h2>
          </div>
          <div className="future-part">
            {futureTeamList?.length > 0 &&
              futureTeamList.map((item, i) => {
                return item.type === 1 ? (
                  <div className="future-item">
                    <img src={item.file} alt="" />
                  </div>
                ) : (
                  <div className="future-item">
                    <ReactPlayer url={item?.file} width='100%' height='100%' />
                  </div>
                );
              })}

            {/* <div className="future-item">
              <img src='assets/img/future-5.jpg' alt='' />
              <div className="video-action"><button type="button" className="btn-action"><i className="fa-solid fa-fw fa-play"></i></button></div>
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerFuture;
