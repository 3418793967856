import $ from "jquery";
import React, { useEffect, useState, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import BASE_URL from "../../../config/host";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { Navigation } from "swiper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchProductcategories } from "../../../store/productcategory/productcategory";
import { fetchCartList } from "../../../store/cartlist/cartlist";
import { fetchAllergies } from "../../../store/ingredient/allergies";
import { fetchDiets } from "../../../store/ingredient/diets";
import { updateCartList } from "../../../store/cartlist/cartlist";

import { handleQuantityIncreaseDecrease } from "../../../helpers/cart_helper";
import { addedToCartApi } from "../../../helpers/cart_helper";
import { removeCartApi } from "../../../helpers/cart_helper";

import Clock from "./clock";



const MenuItemSection = () => {
  const ref = useRef(null);

  const dispatch = useDispatch();
  const sessionCartId = localStorage.getItem("sessionCartId");
  const [storeId, setUserId] = useState("634e739a7732d77728294d89");

  const currentDate = new Date();
  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const { allergylist } = useSelector((state) => state.allergies);
  const { dietlist } = useSelector((state) => state.diets);
  const [poppularProductData, setPoppularProductData] = useState();
  const [desiPropductData, setDesiPropductData] = useState();
  const [details, setDetails] = useState();
  const [pCId, setPCId] = useState("");

  const { productcategorylist } = useSelector(
    (state) => state.productcategories
  );
  const { cartlist } = useSelector((state) => state.cartList);
  const { cartlistCount } = useSelector((state) => state.cartList);

  const [active, setActive] = useState(1);
  const [allergyArray, setAllergyArray] = useState([]);

  // let totalCartAmtSingle = 0;
  // let totalCartAmt = 0;

  let totalProductQty = 0;
  let totalCartAmtSingle = 0;
  let totalCartAmt = 0;
  let totalPoints = 0;
  let totalPointsSingle = 0;
  let totalPriceProduct = 0;
  let totalPointProduct = 0;
  let totalPointsSingleDis = 0;
  let totalPointsDis = 0;

  function handleClick(val) {
    if (allergyArray.includes(val)) {
      setAllergyArray(allergyArray.filter((id) => id !== val));
    } else {
      setAllergyArray([...allergyArray, val]);
    }
  }

  const [dietArray, setDietArray] = useState([]);

  function handleDietClick(val) {
    if (dietArray.includes(val)) {
      setDietArray(dietArray.filter((id) => id !== val));
    } else {
      setDietArray([...dietArray, val]);
    }
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const [paramId, setParamId] = useState(searchParams.get("id"));

  const handleParam = async (id) => {
    try {
      const res = await axios.post(
        BASE_URL + "v2/products/details",
        { id: id },
        {}
      );
      if (res.data.status === 200 || res.data.status === 201) {
        setDetails(res.data.data);
        $("#addonsModal").modal("show");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };

    // axios
    //   .post(
    //     BASE_URL + "v2/products/list",
    //     {
    //       status: 1,
    //       product_type: 1,
    //       featured: true,
    //       product_category_id: pCId,
    //       allergie_id: allergyArray,
    //       diet_id: dietArray,
    //       //sortByField:"ordering",
    //     },
    //     { headers: headers }
    //   )
    //   .then(function (response) {
    //     setPoppularProductData(response.data.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    axios
      .post(
        BASE_URL + "v2/products/category-wise-list",
        {
          sortByField:"ordering",
          sortByValue:1,
          status: 1,
          product_category_id: pCId,
          allergie_id: allergyArray,
          diet_id: dietArray,
          product_ordering : 1,
          limit: 9999
        },
        {}
      )
      .then(function (response) {
        //console.log(response);
        setDesiPropductData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [pCId, allergyArray, dietArray, paramId]);

  useEffect(() => {
    dispatch(
      fetchProductcategories({
        status: true,
        sortByField: "ordering",
        sortByValue: 1,
        limit: 99999,
      })
    );

    dispatch(
      fetchAllergies({
        status: true,
        type: 1,
        sortByField: "ordering",
        sortByValue: 1,
      })
    );

    dispatch(
      fetchDiets({
        status: true,
        type: 3,
        sortByField: "ordering",
        sortByValue: 1,
      })
    );
  }, [dispatch]);

  //Cart Activity
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };
    dispatch(fetchCartList({ cart_id: sessionCartId }, { headers }));
  }, [dispatch]);

  const changeToggle = (index, item) => {
    const data = {
      ...poppularProductData,
      [index]: { ...item, showDescription: !item.showDescription },
    };
    setPoppularProductData(Object.values(data));
  };
  const changeToggle2 = (index, item) => {
    const data = {
      ...desiPropductData,
      [index]: { ...item, showDescription: !item.showDescription },
    };
    setDesiPropductData(Object.values(data));
  };

  //ADDED TO CART
  const [productSpiceLevel, setProductSpiceLevel] = useState(null);
  const [productAddonPriceTot, setProductAddonPriceTot] = useState(0.0);
  const [isaddedToCartCreating, setaddedToCartCreating] = useState(false);
  const [addedOnArray, setAddedOnArray] = useState([]);
  const [removeCartData, setRemoveCartData] = useState([]);
  const [isremoveCartData, setisremoveCartData] = useState(false);

  const [maxAddonGroup, setMaxAddonGroup] = useState([]);

  useEffect(() => {
    setProductAddonPriceTot(
      details?.sale_price > 0 ? details?.sale_price : details?.regular_price
    );
  }, [details]);

  const handleSpiceLevel = (event) => {
    setProductSpiceLevel(event.target.value);
  };




  useEffect(() => {
    let maxAddonGroupTemp = [];
    details?.addon_group_list.forEach((item,index) => {
      var tempId = item._id;
      maxAddonGroupTemp.push({[tempId] : [{maxBuyQty : item.max_buy_qty, addonProductId:[]}]})
    });
    setMaxAddonGroup(maxAddonGroupTemp);
  }, [details]);


  
  const handleAddedOnProducts = (event, addonGroupId, groupIndx, addedOnPrice) => {
    if (event.target.checked) {
      maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.push([event.target.value]);
      var maxData = maxAddonGroup[groupIndx][addonGroupId][0].maxBuyQty;
      var pData = maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.length;
      if(pData <= maxData){
        setProductAddonPriceTot(productAddonPriceTot + addedOnPrice);
        setAddedOnArray([...addedOnArray, event.target.value]);
      }else{
        var index = maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.indexOf(event.target.value);
        maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.splice(index, 1);
        toast.error(`You could not select more than ${maxData} add on product.`, { theme: "colored" });
      }
    } else {
      setProductAddonPriceTot(productAddonPriceTot - addedOnPrice);
      setAddedOnArray(
        addedOnArray.filter((data) => data !== event.target.value)
      );
      var index = maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.indexOf(event.target.value);
      maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.splice(index, 1);
    }
  };

  const addedToCart = async (
    productId,
    itemType,
    buyingMode,
    storeId = null,
    productSpiceLevel = null,
    addedOnArray = []
  ) => {
    setaddedToCartCreating(true);
    const returnData = await addedToCartApi(
      productId,
      itemType,
      buyingMode,
      storeId,
      productSpiceLevel,
      addedOnArray
    );
    if (returnData.data.status === 400) {
      toast.error(returnData.data.message, { theme: "colored" });
      setaddedToCartCreating(false);
    }
    if (returnData.data.status === 200 || returnData.data.status === 201) {
      toast.success(returnData.data.message, { theme: "colored" });
      setProductSpiceLevel(null);
      setAddedOnArray([]);
      setaddedToCartCreating(false);
      setProductAddonPriceTot(0.0);
      dispatch(updateCartList(returnData.data));
      setaddedToCartCreating(false);
      $("#addonsModal").modal("hide");
    }
  };

  const closeModal = (e) => {
    $(`#${e}`).modal("hide");
    setProductSpiceLevel(null);
    setAddedOnArray([]);
    setaddedToCartCreating(false);
    setProductAddonPriceTot(0.0);
  };

  //Revove Cart data
  const removeCartItem = (e, cartId) => {
    setRemoveCartData(cartId);
    $("#removeCartModal").modal("show");
  };
  const confirmRemoveCart = async (e) => {
    setisremoveCartData(true);
    const returnCartData = await removeCartApi(removeCartData);
    if (returnCartData.data.status === 400) {
      toast.error(returnCartData.data.message, { theme: "colored" });
      setRemoveCartData("");
      setisremoveCartData(false);
    }
    if (
      returnCartData.data.status === 200 ||
      returnCartData.data.status === 201
    ) {
      toast.success(returnCartData.data.message, { theme: "colored" });
      const tempCartList = cartlist.filter(
        (data) => data._id !== removeCartData
      );
      const tempCartData = {
        data: tempCartList,
        count: tempCartList.length,
      };
      dispatch(updateCartList(tempCartData));
      setRemoveCartData("");
      setisremoveCartData(false);
      $("#removeCartModal").modal("hide");
    }
  };

  console.log(desiPropductData);


  return (
    <>
      <ToastContainer theme="colored" />

      <div
        className="modal modal-diest fade"
        id="allergiesModal"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Allergies</h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="close"
                className="modal-close"
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="diest-inner">
                {allergylist?.length > 0 &&
                  allergylist.map((item, index) => {
                    return (
                      <div
                        className="custom-checkbox"
                        key={item._id.toString()}
                      >
                        <input
                          type="checkbox"
                          className="custom-checkbox-input"
                          value={item._id}
                          onClick={() => handleClick(item._id)}
                        />
                        <div className="custom-checkbox-label">
                          <div className="diest-item">
                            <div className="diest-icon">
                              <img src={item?.image} />
                              {/* <i className={item?.icon}></i> */}{" "}
                            </div>
                            <div className="diest-text">{item.title}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="diest-action">
                  <button
                    type="button"
                    className="diest-btn modal-close"
                    aria-label="close"
                    data-dismiss="modal"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal modal-diest fade"
        id="diestModal"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Diets</h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="close"
                className="modal-close"
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="diest-inner">
                {dietlist?.length > 0 &&
                  dietlist.map((item, index) => {
                    return (
                      <div
                        className="custom-checkbox"
                        key={item._id.toString()}
                      >
                        <input
                          type="checkbox"
                          value={item._id}
                          className="custom-checkbox-input"
                          onClick={() => handleDietClick(item._id)}
                        />
                        <div className="custom-checkbox-label">
                          <div className="diest-item">
                            <div className="diest-icon">
                              <img src={item?.image} />
                              {/* <i className={item?.icon}></i> */}{" "}
                            </div>
                            <div className="diest-text">{item?.title}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className="diest-action">
                  <button
                    type="button"
                    className="diest-btn"
                    aria-label="close"
                    data-dismiss="modal"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="menu-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="menu-category">
                <button
                  type="button"
                  className="category-item"
                  data-toggle="modal"
                  data-target="#allergiesModal"
                >
                  {allergylist?.length > 0 &&
                    allergylist.map((item, index) => {
                      var isIamgeFlag = allergyArray.includes(item?._id);
                      return (
                        isIamgeFlag && (
                          <div className="icon">
                            <img src={item?.image} />
                          </div>
                        )
                      );
                    })}
                  <div className="text">Allergies</div>
                </button>
                <button
                  type="button"
                  className="category-item"
                  data-toggle="modal"
                  data-target="#diestModal"
                >
                  {dietlist?.length > 0 &&
                    dietlist.map((item, index) => {
                      var isIamgeFlag = dietArray.includes(item?._id);
                      return (
                        isIamgeFlag && (
                          <div className="icon">
                            <img src={item?.image} />
                          </div>
                        )
                      );
                    })}
                  <div className="text">Diets</div>
                </button>
                <button
                  type="button"
                  className="category-item reciept-item"
                  data-toggle="modal"
                  data-target="#recieptModal"
                >
                  <div className="icon">
                    <i className="fa-light fa-receipt fa-sm"></i>
                  </div>
                  <div className="text">Reciept</div>
                </button>
              </div>
              <div className="category-wrap">
                <Swiper
                  slidesPerView={5}
                  spaceBetween={0}
                  navigation
                  modules={[Navigation]}
                  breakpoints={{
                    760: {
                      slidesPerView: 6,
                      spaceBetween: 15,
                    },
                  }}
                  className="category-slider"
                >
                  <SwiperSlide>
                    <div
                      className={`slider-item ${active === 1 ? "active" : ""}`}
                      onClick={() => {
                        setPCId("");
                        setActive(1);
                      }}
                    >
                      <div className="icon">
                        <i className="icon-all-category"></i>
                      </div>
                      <div className="text">All</div>
                    </div>
                  </SwiperSlide>
                  {productcategorylist?.length > 0 ?
                    productcategorylist.map((item, index) => {
                      return (
                        <SwiperSlide key={item._id.toString()}>
                          <div
                            className={`slider-item ${
                              active === item._id ? "active" : ""
                            }`}
                            onClick={() => {
                              setPCId(item._id);
                              setActive(item._id);
                              ref.current.item._id?.scrollIntoView({behavior: 'smooth',
                              inline: "start"});
                            }}
                            key={item._id.toString()}
                          >
                            <div className="icon">
                              {/* <i className={item?.icon}></i> */}
                              <img src={item?.image} />
                            </div>
                            <div className="text">{item?.title}</div>
                          </div>
                        </SwiperSlide>
                      );
                    }) : <div className="text-center">
                    <div className="fad fa-spinner-third fa-spin" style={{color: "red", fontSize: "2.5em", animationDuration: "1s"}}>
                    </div>
                  </div>}
                </Swiper>
              </div>
            </div>

            {/* ====== Billing Section ======= */}
            <div className="col-xl-4 position-relative">
              <div className="bill-section">
                <div className="bill-title">Bill</div>
                <div className="bill-date">
                  <Clock />
                </div>

                <div className="bill-list">
                  {cartlist?.length > 0 ? (
                    cartlist?.length &&
                    cartlist.map((item, index) => {
                      if (item?.buying_mode === 1) {
                        totalPriceProduct += 1;
                        if (item?.product_details?.sale_price > 0) {
                          totalCartAmtSingle =
                            item?.quantity * item?.product_details?.sale_price;
                        } else {
                          totalCartAmtSingle =
                            item?.quantity *
                            item?.product_details?.regular_price;
                        }
                        totalCartAmt = totalCartAmtSingle + totalCartAmt;
                      }

                      if (item?.buying_mode === 2) {
                        totalPointProduct += 1;
                        totalPointsSingle =
                          item?.quantity * item?.product_details?.price_point;
                        totalPoints = totalPointsSingle + totalPoints;
                      }

                      
                      return (
                        <div className="bill-item" key={item._id.toString()}>
                          <div className="menu-name">
                            <div className="text">
                              {item?.product_details?.title}
                            </div>
                            {item?.spice_level && (
                              <div className="sub-text">
                                {item?.spice_level?.title}
                              </div>
                            )}
                          </div>
                          <div className="menu-item-number">
                            <button
                              type="button"
                              className="btn-action"
                              onClick={() =>
                                handleQuantityIncreaseDecrease(
                                  item._id,
                                  item?.quantity,
                                  item?.product_details?._id,
                                  dispatch,
                                  cartlist,
                                  1
                                )
                              }
                            >
                              <i className="fa-solid fa-fw fa-minus"></i>
                            </button>
                            <input
                              type="text"
                              className="input-style"
                              value={item?.quantity}
                              readOnly={true}
                            />
                            <button
                              type="button"
                              className="btn-action alt"
                              onClick={() =>
                                handleQuantityIncreaseDecrease(
                                  item._id,
                                  item?.quantity,
                                  item?.product_details?._id,
                                  dispatch,
                                  cartlist,
                                  2
                                )
                              }
                            >
                              <i className="fa-solid fa-fw fa-plus-large"></i>
                            </button>
                          </div>
              
                          <div className="menu-price">
                            {item?.buying_mode === 1
                              ? item?.product_details?.sale_price > 0
                                ? "€" +
                                  item?.product_details?.sale_price.toFixed(2)
                                : "€" + item?.product_details?.regular_price.toFixed(
                                    2
                                  )
                              : "Pts. " + item?.product_details?.price_point}
                          </div>

                          <button
                            className="remove-btn"
                            type="button"
                            onClick={(e) => removeCartItem(e, item?._id)}
                          >
                            <i className="fa-regular fa-xmark"></i>
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <span className="empty-cart">Cart is Empty</span>
                  )}
                </div>

                {/* <div className="delivery-section">
                  <div className="icon">
                    <i className="icon-cycle1"></i>
                  </div>
                  <div className="text-part">
                    <div className="text">delivery Charges</div>
                  </div>
                  <div className="charges">€00.00</div>
                </div> */}

                {/* <div className="total-price">
                  <div className="text">Total Price</div>
                  <div className="items">
                    <span>{cartlistCount}</span> Items 
                  </div>
                  <div className="price">€{totalCartAmt.toFixed(2)}</div>
                </div> */}

                {cartlistCount !== 0 && (
                  <div className="delivery-section">
                    <div className="text-part">
                      <div className="text">Total</div>
                    </div>

                    <div className="items">
                      <span>{cartlistCount}</span> Items
                    </div>

                    <div className="charges">
                      {totalCartAmt && totalCartAmt > 0
                        ? "€" + totalCartAmt.toFixed(2)
                        : ""}
                      {totalCartAmt && totalPoints && totalPoints > 0
                        ? " + "
                        : ""}
                      {totalPoints && totalPoints > 0
                        ? "Pts" + totalPoints
                        : ""}
                    </div>
                  </div>
                )}

                {cartlist?.length > 0 ? (
                  <div className="payment-action">
                    <Link
                      to="/delivery-registration"
                      className="pay-btn"
                      type="button"
                    >
                      Pay Now
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="menu-item-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 mr-auto">
              {/* <div className="popular-section">
                {poppularProductData?.length > 0 ? (
                  <div className="section-header">
                    <h2 className="section-title">
                      Popular <span>Dishes</span>
                    </h2>
                  </div>
                ) : (
                  ""
                )}
                <div className="menu-part">
                  <div className="menu-list">
                    {poppularProductData &&
                      poppularProductData.map((item, i) => {
                        return (
                          <div
                            className={
                              "menu-item " +
                              (item.showDescription ? "active" : "")
                            }
                            key={item._id.toString()}
                            onClick={
                              item.has_addon === true ||
                              item.has_spice_level === true
                                ? () => {
                                    handleParam(item._id);
                                  }
                                : null
                            }
                          >
                            {item?.short_description && (
                              <button
                                type="button"
                                className="btn-product-info"
                                onClick={() => changeToggle(i, item)}
                              >
                                <i className="fa-regular fa-fw fa-info-circle"></i>
                              </button>
                            )}
                            <div className="menu-inner">
                              <span className="popular-tag">Most Popular</span>
                              <div className="ingredients">
                                <div className="list-contains">
                                  {poppularProductData[i].allergie &&
                                    poppularProductData[i].allergie.map(
                                      (v, index) => {
                                        return (
                                          <div
                                            className="icon"
                                            key={v._id.toString()}
                                            title={v.title}
                                          >
                                            <img src={v.image} />
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                              <div className="name">{item?.title}</div>
                              <div className="menu-image">
                                <img src={item?.image} alt="" />
                              </div>

                              <div className="description">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.short_description,
                                  }}
                                />
                              </div>

                              <div className="menu-order-action">
                                {item.in_stock === 1 &&
                                item.has_addon !== true &&
                                item.has_spice_level !== true ? (
                                  <button
                                    className="add-to-cart"
                                    type="button"
                                    onClick={(e) => {
                                      addedToCart(
                                        item?._id,
                                        1,
                                        1,
                                        storeId,
                                        productSpiceLevel,
                                        addedOnArray
                                      );
                                    }}
                                  >
                                    <div className="icon">
                                      <i className="icon-buy"></i>{" "}
                                    </div>
                                    {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0)?(
                                      <small
                                        style={{
                                          textDecorationLine: "line-through",
                                        }}
                                      >
                                        €{item?.regular_price}
                                      </small>
                                      ):''
                                    }
                                    {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                                      <div className="price">
                                        €{item?.sale_price}
                                      </div>
                                    ) : (
                                      <div className="price">
                                        €{item?.regular_price}
                                      </div>
                                    )}
                                    <div className="text">Add to Cart</div>
                                  </button>
                                ) : (
                                  <button className="add-to-cart" type="button">
                                    <div className="icon">
                                      <i className="icon-buy"></i>{" "}
                                    </div>

                                    {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                                      <small
                                        style={{
                                          textDecorationLine: "line-through",
                                        }}
                                      >
                                        €{item?.regular_price}
                                      </small>
                                      ):''
                                    }


                                    {(item?.regular_price > item?.sale_price) && (item?.sale_price !==0) ?(
                                      <div className="price">
                                        €{item?.sale_price}
                                      </div>
                                    ) : (
                                      <div className="price">
                                        €{item?.regular_price}
                                      </div>
                                    )}


                                    {item.in_stock === 1 ? (
                                      <div className="text">Order Now</div>
                                    ) : item.in_stock === 2 ? (
                                      <div className="text">Out of Stock</div>
                                    ) : item.in_stock === 3 ? (
                                      <div className="text">
                                        Today out of Stock
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div> */}

              <div className="popular-section">
                {desiPropductData === undefined && 
                <div className="text-center">
                  <div className="fas fa-spinner-third fa-fw fa-2x fa-spin" style={{color: "red", fontSize: "2.5em", animationDuration: "1.5s"}}>
                  </div>
                </div>
                }
                {desiPropductData &&
                  desiPropductData?.map((item, i) => {
                    if (desiPropductData[i]?.product_list?.length > 0)
                      return (
                        <div ref={ref} id={item?.id} key={item._id.toString()}>
                          <div className="section-header">
                            <h2 className="section-title">{item?.title}</h2>
                          </div>
                          <div className="section-text-description">{item?.description}</div>
                          <div className="menu-part">
                            <div className="menu-list">
                              {desiPropductData[i].product_list &&
                                desiPropductData[i].product_list.map(
                                  (item, i) => {
                                    return (
                                      <div
                                        className={
                                          "menu-item " +
                                          (item.showDescription ? "active" : "")
                                        }
                                        key={item._id.toString()}
                                        onClick={
                                          item.has_addon === true ||
                                          item.has_spice_level === true
                                            ? () => {
                                                handleParam(item._id);
                                              }
                                            : null
                                        }
                                      >
                                        {item?.short_description && (
                                          <button
                                            type="button"
                                            className="btn-product-info"
                                            onClick={() =>
                                              changeToggle2(i, item)
                                            }
                                          >
                                            <i className="fa-regular fa-fw fa-info-circle"></i>
                                          </button>
                                        )}
                                        <div className={"menu-inner " +(item.featured ? (item.in_stock === 2 || item.in_stock === 3) ? "popular-item out-of-stock" : "popular-item" : (item.in_stock === 2 || item.in_stock === 3) ? "out-of-stock" : "")}>
                                        {item.featured && <span className="popular-tag">Popular</span>}
                                          <div className="ingredients">
                                            <div className="list-contains">
                                              {item.allergie_list &&
                                                item.allergie_list.map(
                                                  (v, index) => {
                                                    return (
                                                      <div
                                                        className="icon"
                                                        key={v._id.toString()}
                                                        title={v.title}
                                                      >
                                                        {/* <i
                                                          className={v?.icon}
                                                        ></i> */}
                                                        <img src={v.image} />
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          </div>
                                          <div className="name">
                                            {item?.title}
                                          </div>
                                          <div className="menu-image">
                                            <img src={item?.image} alt="" />
                                          </div>

                                          <div className="description">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: item?.short_description,
                                              }}
                                            />
                                          </div>
                                          <div className="menu-order-action">
                                            {item.in_stock === 1 &&
                                            item.has_addon !== true &&
                                            item.has_spice_level !== true ? (
                                              <button
                                                className={"add-to-cart "+(item.featured ? (item.in_stock === 2 || item.in_stock === 3) ? "popular-item out-of-stock" : "popular-item" : (item.in_stock === 2 || item.in_stock === 3) ? "out-of-stock" : "")}
                                                type="button"
                                                onClick={(e) => {
                                                  addedToCart(
                                                    item?._id,
                                                    1,
                                                    1,
                                                    storeId,
                                                    productSpiceLevel,
                                                    addedOnArray
                                                  );
                                                }}
                                              >
                                                <div className="icon">
                                                  <i className="icon-buy"></i>{" "}
                                                </div>

                                                {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                                                  <small
                                                    style={{
                                                      textDecorationLine:
                                                        "line-through",
                                                    }}
                                                  >
                                                    €{item?.regular_price}
                                                  </small>
                                                  ):''
                                                }

                                                {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                                                  <div className="price">
                                                    {item?.sale_price === 0 ? 'Free' : `€${item?.sale_price}`}
                                                  </div>
                                                ) : (
                                                  <div className="price">
                                                    {item?.regular_price === 0 ? 'Free' : `€${item?.regular_price}`}
                                                  </div>
                                                )}
                                                <div className="text">
                                                  Add to cart
                                                </div>
                                              </button>
                                            ) : (
                                              <button
                                                className={"add-to-cart "+(item.featured ? (item.in_stock === 2 || item.in_stock === 3) ? "popular-item out-of-stock" : "popular-item" : (item.in_stock === 2 || item.in_stock === 3) ? "out-of-stock" : "")}
                                                type="button"
                                              >
                                                <div className="icon">
                                                  <i className="icon-buy"></i>{" "}
                                                </div>

                                                {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                                                  <small
                                                    style={{
                                                      textDecorationLine:
                                                        "line-through",
                                                    }}
                                                  >
                                                    €{item?.regular_price}
                                                  </small>
                                                  ):''
                                                }

                                                {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                                                  <div className="price">
                                                    €{item?.sale_price}
                                                  </div>
                                                ) : (
                                                  <div className="price">
                                                    {item?.regular_price === 0 ? 'Free' : `€${item?.regular_price}`}
                                                  </div>
                                                )}
                                                {item.in_stock === 1 ? (
                                                  <div className="text">
                                                    Order Now
                                                  </div>
                                                ) : item.in_stock === 2 ? (
                                                  <div className="text">
                                                    Out of Stock
                                                  </div>
                                                ) : item.in_stock === 3 ? (
                                                  <div className="text">
                                                    Today out of Stock
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =====Product Details Modal===== */}
      <div
        className="modal modal-addons fade"
        id="addonsModal"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{details?.title}</h5>
              <button
                type="button"
                //data-dismiss="modal"
                //aria-label="close"
                onClick={(e) => closeModal("addonsModal")}
                className="modal-close"
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {details?.spice_level_list?.length > 0 && (
                  <div className="col-lg-12">
                    <div className="addons-title">Spice Level</div>
                  </div>
                )}
              </div>
              <div className="row">
                {details &&
                  details.spice_level_list.map((item, i) => {
                    return (
                      <>
                        <div
                          className="col-4 col-lg-4 d-flex"
                          key={item._id.toString()}
                        >
                          <div className="addons-inner">
                            <div className="custom-checkbox">
                              <input
                                type="radio"
                                className="custom-checkbox-input"
                                value={item?._id}
                                name="spiceLevelRadio"
                                checked={
                                  item?._id === productSpiceLevel && true
                                }
                                onChange={(e) => {
                                  handleSpiceLevel(e);
                                }}
                              />
                              <div className="custom-checkbox-label">
                                <div className="addons-item">
                                  <div className="addons-text">
                                    {item?.title}
                                  </div>
                                  <div className="addons-img">
                                    <img src={item?.image} alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>

              {details?.addon_group_list &&
                details?.addon_group_list.length > 0 &&
                details?.addon_group_list?.map((addonGroupItem, n) => {
                  return (
                    <div className="row" key={addonGroupItem._id.toString()}>
                      <div className="col-12">
                        <div className="addons-title">
                          {addonGroupItem?.title}
                        </div>
                      </div>

                      {addonGroupItem?.addon_product_list &&
                        addonGroupItem?.addon_product_list?.map(
                          (addonProductItem, i) => {
                            return (
                              <>
                                <div
                                  className="col-12 col-lg-4"
                                  key={addonProductItem._id.toString()}
                                >
                                  <div className="coke-inner">
                                    <div className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox-input"
                                        value={addonProductItem?._id}
                                        checked={addedOnArray.includes(
                                          addonProductItem?._id
                                        )}
                                        onChange={(e) => {
                                          handleAddedOnProducts(
                                            e,
                                            addonGroupItem._id,
                                            n,
                                            addonProductItem?.sale_price > 0
                                              ? addonProductItem?.sale_price
                                              : addonProductItem?.regular_price
                                          );
                                        }}
                                      />
                                      <div className="custom-checkbox-label">
                                        <div className="coke-option">
                                          <div className="coke-price">
                                            
                                            {(addonProductItem?.regular_price === 0) ? 'Free' : addonProductItem?.sale_price > 0
                                              ? '€'+addonProductItem?.sale_price  
                                              : '€'+addonProductItem?.regular_price}
                                          </div>
                                          <div className="coke-item">
                                            <div className="coke-content">
                                              <div className="coke-title">
                                                {addonProductItem.title}
                                              </div>
                                              <div className="coke-text">
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      addonProductItem.short_description,
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="coke-img">
                                              <img
                                                src={addonProductItem.image}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="ingredients">
                                        <div className="list-contains">
                                          {addonProductItem.addon_allergie_list &&
                                            addonProductItem.addon_allergie_list.map(
                                              (v, index) => {
                                                return (
                                                  <div
                                                    className="icon"
                                                    key={v._id.toString()}
                                                    title={v.title}
                                                  >
                                                    <img src={v.image} />
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    {/* <button
                                      type="button"
                                      className="coke-btn"
                                      data-toggle="modal"
                                      //data-target="#cocaModal"
                                      //onClick={handleParamAddOn(addonProductItem?._id)}
                                      // onClick={
                                      //   item.has_addon === true ||
                                      //   item.has_spice_level === true
                                      //     ? () => {
                                      //         handleParam(item._id);
                                      //       }
                                      //     : null
                                      // }
                                    >
                                      <i className="icon-i-icon"></i>{" "}
                                    </button> */}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                    </div>
                  );
                })}
            </div>
            <div className="modal-footer">
              <div className="cart-action">
                {details?.in_stock === 1 ? (
                  <div className="cart-price">€{productAddonPriceTot}</div>
                ) : (
                  ""
                )}

                {details?.in_stock === 1 ? (
                  <button
                    type="button"
                    className="add-btn"
                    onClick={(e) => {
                      addedToCart(
                        details?._id,
                        1,
                        1,
                        storeId,
                        productSpiceLevel,
                        addedOnArray
                      );
                    }}
                    disabled={isaddedToCartCreating}
                  >
                    <div className="text">
                      {isaddedToCartCreating && (
                        <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
                      )}
                      Add Cart
                    </div>
                    <div className="icon">
                      <i className="fa-regular fa-angle-right"></i>
                    </div>
                  </button>
                ) : (
                  <button type="button" className="add-btn">
                    {details?.in_stock === 2 ? (
                      <div className="text">Out of Stock</div>
                    ) : details?.in_stock === 3 ? (
                      <div className="text">Today out of Stock</div>
                    ) : (
                      ""
                    )}
                    <div className="icon">
                      <i className="fa-regular fa-angle-right"></i>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal modal-coca fade"
        id="cocaModal"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Coca Cola</h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="close"
                className="modal-close"
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="coca-inner">
                <div className="coca-content">
                  <div className="coca-item">
                    <div className="coca-text">
                      It is a long established fact.It is a long established
                      fact.It is a long.
                    </div>
                    <div className="coca-sub-text">500 ml</div>
                    <div className="coca-ingredients">
                      <div className="ingredients-title">Allergies</div>
                      <div className="list-contains">
                        <div className="icon">
                          <i className="icon-nut"></i>
                        </div>
                        <div className="icon">
                          <i className="icon-peas"></i>
                        </div>
                        <div className="icon">
                          <i className="icon-wheat"></i>
                        </div>
                        <div className="icon">
                          <i className="icon-almond"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="coca-img">
                    <img src="assets/img/coke.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="coca-action">
                <button type="button" className="coca-btn">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ====== Cart Remove Alert ====== */}
      <div
        className="modal custom-alert-modal fade"
        id="removeCartModal"
        data-backdrop="static"
        role="dialog"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ready to remove the cart item?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Select "Remove" below if you are ready to remove your cart data.
            </div>
            <div className="modal-footer">
              <button type="button" className="cnf-btn" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="cnf-btn alt"
                onClick={confirmRemoveCart}
                draggable={isremoveCartData}
              >
                {isremoveCartData && (
                  <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
                )}
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>

       <div className="modal recieptModal fade" id="recieptModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="bill-section">
                <div className="bill-title">Bill</div>
                <div className="bill-date">
                  <span>{weekday[currentDate.getDay()]}</span> <span>{currentDate.getDate()}/{currentDate.getMonth()}/{currentDate.getFullYear()}</span> <span>{currentDate.getHours()}:{currentDate.getMinutes()}</span>
                </div>


                <div className="bill-list">
                  {cartlist?.length > 0 &&
                    cartlist.map((item, index) => {
                      totalProductQty = item?.quantity + totalProductQty;
                      if (item?.product_details?.sale_price > 0) {
                        totalCartAmtSingle = item?.quantity * item?.product_details?.sale_price;
                      } else {
                        totalCartAmtSingle = item?.quantity * item?.product_details?.regular_price;
                      }
                      totalCartAmt = totalCartAmtSingle + totalCartAmt;

                      return (
                        <div className="bill-item" key={item._id}>
                          <div className="menu-name">
                            <div className="text">{item?.product_details?.title}</div>
                            <div className="sub-text">Half White biryani</div>
                          </div>
                          <div className="menu-item-number">
                            <button type="button" className="btn-action" onClick={()=>handleQuantityIncreaseDecrease(item._id, item?.quantity, item?.product_details?._id, dispatch, cartlist, 1)}>
                              <i className="fa-solid fa-fw fa-minus"></i>
                            </button>
                            <input
                              type="text"
                              className="input-style"
                              value={item?.quantity}
                              readOnly={true}
                            />
                            <button type="button" className="btn-action alt" onClick={()=>handleQuantityIncreaseDecrease(item._id, item?.quantity, item?.product_details?._id, dispatch, cartlist, 2)}>
                              <i className="fa-solid fa-fw fa-plus-large"></i>
                            </button>
                          </div>
                          <div className="menu-price">€{(item?.product_details?.sale_price > 0) ? item?.product_details?.sale_price.toFixed(2) : item?.product_details?.regular_price.toFixed(2)}</div>
                        </div>
                      );
                    })}
                </div>

                <div className="delivery-section">
                  <div className="icon">
                    <i className="icon-cycle1"></i>
                  </div>
                  <div className="text-part">
                    <div className="text">delivery Charges</div>
                    <div className="sub-text">Tax</div>
                  </div>
                  <div className="charges">€00.00</div>
                </div>

                <div className="total-price">
                  <div className="text">Total Price</div>
                  <div className="items">
                    <span>{totalProductQty}</span> Items
                  </div>
                  <div className="price">€{totalCartAmt.toFixed(2)}</div>
                </div>

                <div className="payment-action">
                  <Link to="/cart" className="pay-btn" type="button">
                    Pay Now
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> 
    </>
  );
};

export default MenuItemSection;
