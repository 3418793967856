import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import BASE_URL from "../../config/host";

export const featureTeamSlice = createSlice({
  name: "featureTeam",
  loading: false,
  initialState: {
    futureTeamList: [],
  },
  reducers: {
    listRequested: (state, action) => {
      state.loading = true;
    },
    listRequestFailed: (state, action) => {
      state.loading = false;
      state.futureTeamList = "";
      //console.log(action.payload);
    },
    listReceived: (state, action) => {
      state.loading = false;
      state.futureTeamList = action.payload.data ? action.payload.data : [];
    },
  },
});

export const { listReceived, listRequested, listRequestFailed } =
  featureTeamSlice.actions;
//console.log(body);
export const fetchFutureTeam = (body) =>
  apiCallBegan({
    url: "/future-team/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type,
  });
