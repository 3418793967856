
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";

export const funfactSlice = createSlice({
    name: 'funfacts',
    loading: false,
    initialState: {
        funfactlist: [],
        funfactCount: 0
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.funfactlist ='';
            //console.log(action.payload);
        },
        listReceived: (state, action)=>{
            state.loading = false;
            state.funfactlist = action.payload.data?action.payload.data:[];
            state.funfactCount = action.payload.count || 0
        }
    }
});


export const {listReceived,listRequested ,listRequestFailed } = funfactSlice.actions;
//console.log(body);
export const fetchFunfacts = (body)=> apiCallBegan({
    url: '/fun-facts/list',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type
 
});














