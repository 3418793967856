import React from 'react';

const SplashScreen = () => {
  return (
    <>
      <section className='splash-section'>
        <div className='splash-inner'>
          <div className='logo'>
            <img src='assets/img/logo.svg' alt='' />
          </div>
        </div>
      </section>
    </>
  );
};

export default SplashScreen;