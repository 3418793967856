import React from 'react';

const ChallengesSection = () => {
    return (
        <>
           <section className='sec-challenges'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                        <div className='challenges-heading'>
                            <div className='challenges-title'>Challenges</div>
                            <div className='challenges-sub-title'>Discount is a long established fact that a reader will beDiscount is a<br></br> long established fact that a reader will be</div>
                        </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3'>
                                <div className='challenges-item'>
                                    <div className='challenges-icon img'>
                                        <img src='assets/img/challenge-1.svg' alt='' />
                                    </div>
                                    <div className='challenges-text'>Invite Friend</div>
                                    <div className='challenges-content'>Discount is a long established fact that a reader will be Discount</div>
                                    <div className='challenges-action'><a href='#!' className='challenges-btn'>Invite</a></div>
                                    <div className='challenges-pts'>+10 Pts</div>
                                </div>
                        </div>
                        <div className='col-lg-3'>
                                <div className='challenges-item'>
                                    <div className='challenges-icon'>
                                        <i className='icon-challenge-3'></i>
                                    </div>
                                    <div className='challenges-text'>Buy 1 Get 1 free</div>
                                    <div className='challenges-content'>Discount is a long established fact that a reader will be Discount</div>
                                    <div className='challenges-action'><a href='#!' className='challenges-btn'>Buy</a></div>
                                    <div className='challenges-pts'>+05 Pts</div>
                                </div>
                        </div>
                        <div className='col-lg-3'>
                                <div className='challenges-item'>
                                    <div className='challenges-icon big'>
                                        <i className='icon-challenge-2'></i>
                                    </div>
                                    <div className='challenges-text'>Free Delivery</div>
                                    <div className='challenges-content'>Discount is a long established fact that a reader will be Discount</div>
                                    <div className='challenges-action'><a href='#!' className='challenges-btn'>Buy</a></div>
                                    <div className='challenges-pts'>+15 Pts</div>
                                </div>
                        </div>
                        <div className='col-lg-3'>
                                <div className='challenges-item'>
                                    <div className='challenges-icon big'>
                                        <i className='icon-challenge-4'></i>
                                    </div>
                                    <div className='challenges-text'>70% Discount</div>
                                    <div className='challenges-content'>Discount is a long established fact that a reader will be Discount</div>
                                    <div className='challenges-action'><a href='#!' className='challenges-btn'>Menu</a></div>
                                    <div className='challenges-pts'>+25 Pts</div>
                                </div>
                        </div>
                    </div>
                </div>
           </section> 
        </>
    );
};

export default ChallengesSection;