import React, { useEffect } from 'react';
import { useSelector,  useDispatch } from 'react-redux';
import { fetchSteps } from '../../../store/home/step';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const HomeProcess = () => {
  const dispatch = useDispatch();
  const { steplist } = useSelector(state => state.steps);
  useEffect(() => {
    dispatch(fetchSteps({status:true, sortByField:'ordering', sortByValue:1}))
  }, [dispatch]);
  return (
    <section className='section-process'>
      <div className='container'>
        <Swiper
          slidesPerView={1.4}
          spaceBetween={0}
          navigation
          modules={[Navigation]}
          breakpoints={{
            760: {
              slidesPerView: 2,
            },
            990: {
              slidesPerView: 3,
            },
          }}
          className="process-list"
        >
        {steplist.length > 0 && steplist.map((item) =>{
          return (
          <SwiperSlide key={item._id}>
          
            <div className='item'>
              <div className='item-inner'>
                <div className='item-icon'><img src={item?.image} alt={item?.image} /></div>
                <div className='item-content'>
                  <h2 className='item-title'>{item.title}</h2>
                  <div className='item-text'>{item.description}</div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          )
        })}
        </Swiper>
        {/* <div className='process-list'>
        {steplist.length > 0 && steplist.map((item,index) =>{
         return (
            <div className='item' key={index}>
              <div className='item-inner'>
                <div className='item-icon'><i className={item.icon}></i></div>
                <div className='item-content'>
                  <h2 className='item-title'>{item.title}</h2>
                  <div className='item-text'>{item.description}</div>
                </div>
              </div>
            </div>
          )
          })}
        </div> */}
      </div>
    </section>
  );
};

export default HomeProcess;