import React from 'react';
import { Link } from 'react-router-dom';
const OrderDetailsBanner = () => {
  return (
    <>
       <section className='page-banner-section order-details'>
        <div className='container'>
          <div className='banner-section'>
            <div className='banner-back'>
              <Link to="/order-history" className="back"><div className='icon'><i className="fa-solid fa-chevron-left"></i></div></Link>
            </div>
            <div className='breadcrumb-section'>
              <div className='link'>
                <Link to="/" className="breadcrumb-link">Home</Link>
              </div>
              <div className='seperator'>/</div>
              <div className='link'>
                <div className="breadcrumb-link">Ordering</div>
              </div>
            </div>
            <div className='page-title'>
              <h1 className='title'>Order Details</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderDetailsBanner;