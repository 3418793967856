import React from 'react';
import './Css/Menu.css'
import MenuBanner from './Components/MenuBanner';
import MenuCategory from './Components/MenuCategory';
import MenuItemSection from './Components/MenuItemSection';
import Addons from './Components/Addons';

const Menu = () => {
  return (
    <>
      <MenuBanner/>
      {/* <MenuCategory/> */}
      <MenuItemSection/>
      {/* <Addons/> */}
    </>
  );
};

export default Menu;