import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import BASE_URL from "../../config/host";

export const blogSlice = createSlice({
  name: "blogs",
  loading: false,
  initialState: {
    bloglist: [],
    blogCount: 0,
  },
  reducers: {
    listRequested: (state, action) => {
      state.loading = true;
    },
    listRequestFailed: (state, action) => {
      state.loading = false;
      state.bloglist = "";
    },
    listReceived: (state, action) => {
      state.loading = false;
      state.bloglist = action.payload.data ? action.payload.data : [];
      state.blogCount = action.payload.count || 0;
    },
  },
});

export const { listReceived, listRequested, listRequestFailed } =
  blogSlice.actions;

export const fetchBlogs = (body) =>
  apiCallBegan({
    url: "/blog/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type,
  });
