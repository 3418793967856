import React from 'react';
import './Css/Faq.css';
import HelpSearch from '../Contact/Components/HelpSearch';
import FaqBanner from './Components/FaqBanner';
import HelpFeature from '../Contact/Components/HelpFeature';
import ContactUsForm from '../Contact/Components/ContactUsForm';
import Newsletter from '../../Components/Newsletter';
import FaqList from './Components/FaqList';

const FAQ = () => {
  return (
    <>
      <FaqBanner />
      {/* <HelpSearch /> */}
      <HelpFeature />
      <section className='faq-section'>
        <div className='container'>
          <div className='section-header'>
            <h2 className='section-title'>frequently <span> asked questions</span></h2>
          </div>
          <FaqList/>
        </div>
      </section>
      <ContactUsForm />
      <Newsletter />
    </>
  );
};

export default FAQ;