import React from 'react';

const LoyaltySection = () => {
  return (
    <>
      <section className='loyalty-section'>
        <div className='container'>
          <div className='section-header'>
            <h2 className='section-title'>How <span>it Works</span></h2>
          </div>
          <div className='loyalty-part'>
            <div className='loyalty-item'>
              <div className='icon'>
                <i className='icon-sign-up'></i>
              </div>
              <div className='loyalty-content'>
                <div className='title'>Sign up</div>
                <div className='description'>
                  <p>In eget turpis elit. Phasellus eleifend mattis felis, ac mollis sapien bibendum.</p>
                </div>
              </div>
            </div>
            <div className='loyalty-item arrow'>
              <img src='assets/img/arrow.svg' alt='' />
            </div>
            <div className='loyalty-item'>
              <div className='icon'>
                <i className='icon-invite'></i>
              </div>
              <div className='loyalty-content'>
                <div className='title'>Invite Friends</div>
                <div className='description'>
                  <p>In eget turpis elit. Phasellus eleifend mattis felis, ac mollis sapien bibendum.</p>
                </div>
              </div>
            </div>
            <div className='loyalty-item arrow'>
              <img src='assets/img/arrow-flip.svg' alt='' />
            </div>
            <div className='loyalty-item'>
              <div className='icon'>
                <i className='icon-smily'></i>
              </div>
              <div className='loyalty-content'>
                <div className='title'>Enjoy</div>
                <div className='description'>
                  <p>In eget turpis elit. Phasellus eleifend mattis felis, ac mollis sapien bibendum.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default LoyaltySection;