import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import BASE_URL from '../../../config/host';

const RewardsProfile = () => {

  const [point, setPoint]  = useState();
  const [totalPoint, setTotalPoint] = useState();
  useEffect(() => {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }

    axios.post(`${BASE_URL}profile-details`,{ },{ headers: headers } )
      .then(function (response) {
      setPoint(response.data.data);
      //console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

    axios.post(BASE_URL + 'total-point',{ },{headers: headers} )
    .then(function (response) {
      setTotalPoint(response.data);
      //console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [])

  //console.log(point);

  return (
    <>
      <section className='rewards-profile-section'>
        <div className='container'>
          <div className='profile-section'>
            <div className='rewards-image'>
              <img src='assets/img/account-rewards-profile.svg' alt='' />
            </div>
            <div className='text-content'>
              <div className='profile-name'>Hi <span>{point?.name}</span>, you have</div>
              <div className='points'><span>{totalPoint?.total_point}</span> Points</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RewardsProfile;