import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { fetchBlogs } from "../../../store/blog/blog";
import ReactPaginate from "react-paginate";
// import Pagination from '../../../Components/Pagination';
import BlogList from "./BlogList";
import BlogSearch from "./BlogSearch";

const BlogSec = () => {
  const dispatch = useDispatch();
  const { blogCount } = useSelector((state) => state.blogs);
  const [keyword, setKeyword] = useState("");
  const [category_id, setCategory] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const [limit] = useState(6);

  const handlePageClick = (event) => {
    dispatch(
      fetchBlogs({
        status: true,
        keyword,
        category_id,
        dateRange,
        limit,
        offset: event.selected * limit,
      })
    );
  };

  const handleSearch = (value) => {
    setKeyword(value);

    dispatch(
      fetchBlogs({
        status: true,
        keyword: value,
        category_id,
        dateRange,
        limit,
        offset: 0,
      })
    );
  };

  const handleCategorySearch = (value) => {
    setCategory(value);

    dispatch(
      fetchBlogs({
        status: true,
        keyword,
        category_id: value,
        limit,
        offset: 0,
      })
    );
  };

  const handleDateRangeSearch = (value, dateStatus) => {
    if(value === "all_data") {
      dispatch(
        fetchBlogs({
          status: true,
          keyword,
          category_id,
          dateRange: {
            start: null,
            end: null,
          },
          limit,
          offset: 0,
        })
      );
      return
    }
    if (dateStatus) {
      const start = moment().add(0, value).startOf(value).format("YYYY-MM-DD");
      const end = moment().add(0, value).endOf(value).format("YYYY-MM-DD");

      const range = {
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
      };

      setDateRange(range);

      dispatch(
        fetchBlogs({
          status: true,
          keyword,
          category_id,
          dateRange: range,
          limit,
          offset: 0,
        })
      );
    } else {
      const start = moment()
        .subtract(1, value)
        .startOf(value)
        .format("YYYY-MM-DD");
      const end = moment().subtract(1, value).endOf(value).format("YYYY-MM-DD");

      const range = {
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
      };

      setDateRange(range);

      dispatch(
        fetchBlogs({
          status: true,
          keyword,
          category_id,
          dateRange: range,
          limit,
          offset: 0,
        })
      );
    }
  };

  return (
    <section className="blog-item-section">
      <div className="container">
        <div className="blog-header">
          <div className="blog-title">
            <div className="section-header">
              <h2 className="section-title">
                Dabba Wala<span> Blogs</span>
              </h2>
            </div>
          </div>
          <BlogSearch
            keyword={keyword}
            handleSearch={handleSearch}
            handleCategorySearch={handleCategorySearch}
            handleDateRangeSearch={handleDateRangeSearch}
            limit={limit}
          />
        </div>
        <BlogList limit={limit} />
        {/* <Pagination /> */}
        {Math.ceil(blogCount / limit) > 1 && (
          <div className="pagenation-section">
            <ReactPaginate
              breakLabel=" ... "
              breakClassName="px-2"
              nextLabel=" >"
              nextClassName="ml-2"
              previousLabel="< "
              previousClassName="mr-2"
              onPageChange={handlePageClick}
              pageCount={Math.ceil(blogCount / limit) || 1}
              containerClassName="pagination-number list-unstyled"
              pageClassName="number"
              activeClassName="active"
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogSec;
