import React from 'react';

const OrderNewsletter = () => {
  return (
    <>
      <div className='additional-data'>
        <div className='title-part'>The latest promotions and competitive offers directly in your mailbox?</div>
        <div className='additional-item'>
          <div className='additional-list'>
            <div className='icon'><i className="icon-toung"></i></div>
            <div className='text-content'>
              <div className='text-title'>Tasty Food</div>
              <div className='description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
            </div>
          </div>
          <div className='additional-list'>
            <div className='icon'><i className="icon-cycle"></i></div>
            <div className='text-content'>
              <div className='text-title'>Swift Delivery</div>
              <div className='description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
            </div>
          </div>
          <div className='additional-list'>
            <div className='icon'><i className="icon-home"></i></div>
            <div className='text-content'>
              <div className='text-title'>To Your Location</div>
              <div className='description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
            </div>
          </div>

          {/* <div className='shipping-item'>
            <div className="custom-checkbox">
              <input type="checkbox" className="custom-checkbox-input" name="address" />
              <label className="custom-checkbox-label">
                <div className='text'>Yes please, keep me posted!</div></label>
            </div>
          </div> */}
        </div>

      </div>
    </>
  );
};

export default OrderNewsletter;