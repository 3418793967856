import React ,{ useState }from 'react';
import { Link , NavLink} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchSettings } from '../store/shared/setting';
import { useEffect } from 'react';



const DefaultHeader = () => {
  const { cartlistCount } = useSelector((state) => state.cartList);
  const [isMenuActive, setMenuActive] = useState(false);
  const toggleClass = () => {
    setMenuActive(!isMenuActive);
  };
  
  const closeMenu = () => {
    setMenuActive(false);
  };

  const dispatch = useDispatch();
  const { settinglist } = useSelector(state => state.settings);

  useEffect(() => {
    dispatch(fetchSettings({ id : "624c1d79ac364e5ef3796d76" }))
  }, [dispatch]);

  //console.log(settinglist);
  return (
    <>
      <header>
        <div className='container'>
          <div className='header-content'>
            <div className='brand'>
              <Link to="/" className="logo">
                {settinglist?.logo && <img src={settinglist?.logo} alt='Logo'/>}
              </Link>
            </div>
            <div className={isMenuActive ? 'navigation active': 'navigation'}>
              <button type='button' className='btn-menu-toggle' onClick={toggleClass}><i className='icon-bars'></i></button>
             
              <div className='navigation-content'>
                <div className='navigation-header'>
                <button type='button' className='btn-menu-toggle alt' onClick={toggleClass}><i className="fa-regular fa-xmark"></i></button>
                 
                  <div className='brand'>
                    <Link to="/" className="logo">
                      <img src='assets/img/logo.svg' onClick={closeMenu} alt='Logo'/>
                    </Link>
                  </div>
                  <Link to="/" className="user-account"><i className='icon-user'></i></Link>
                </div>
                <div className='navigation-menu'>
                  <div className='menu-item'>
                    <NavLink to="/menu" onClick={closeMenu} className={({ isActive }) => (isActive ? 'menu-link active' : 'menu-link')}>Ordering</NavLink>
                  </div>
                  <div className='menu-item'>
                    <NavLink to="/catering" onClick={closeMenu} className={({ isActive }) => (isActive ? 'menu-link active' : 'menu-link')}>Catering</NavLink>
                  </div>
                  <div className='menu-item'>
                    <NavLink to="/deals" onClick={closeMenu} className={({ isActive }) => (isActive ? 'menu-link active' : 'menu-link')}>Deals</NavLink>
                  </div>
                  <div className='menu-item'>
                    <NavLink to="/about" onClick={closeMenu} className={({ isActive }) => (isActive ? 'menu-link active' : 'menu-link')} >About</NavLink>
                  </div>
                  <div className='menu-item'>
                    <NavLink to="/blog" onClick={closeMenu} className={({ isActive }) => (isActive ? 'menu-link active' : 'menu-link')}>Blog</NavLink>
                  </div>
                  <div className='menu-item'>
                    <NavLink to="/contact" onClick={closeMenu} className={({ isActive }) => (isActive ? 'menu-link active' : 'menu-link')}>Contact Us</NavLink>
                  </div>
                  <div className='menu-item'>
                    <NavLink to="/account" onClick={closeMenu} className={({ isActive }) => (isActive ? 'menu-link active' : 'menu-link')}>Account</NavLink>
                  </div>
                  
                </div>
                <div className='header-social'>
                  <Link to="/" target="_blank" className='social-item'><i className='icon-facebook'></i></Link>
                  <Link to="/" target="_blank" className='social-item'><i className='icon-instagram'></i></Link>
                  <Link to="/a" target="_blank" className='social-item'><i className='icon-twitter'></i></Link>
                </div>
                <div className='header-links'>
                  <div className='link-item'><Link to="/" className='link'>Terms & Conditions</Link></div>
                  <div className='link-item'><Link to="/" className='link'>Privacy Policy</Link></div>
                </div>
              </div>
            </div>
            <div className='navigation-options'>
              <Link to="/delivery-registration" className='cart'>
                {
                  (cartlistCount && cartlistCount > 0)
                  ?<span className='indication'>{cartlistCount&&cartlistCount}</span>
                  :''
                }
                <i className='icon-buy'></i>
              </Link>
              {localStorage.getItem("token") === null && <Link to="/sign-in" className='user-account'><i className='icon-user'></i></Link>}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DefaultHeader;
