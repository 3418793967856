import React from 'react';
import './Css/Home.css';
import HomeBanner from './Components/HomeBanner';
import HomeProcess from './Components/HomeProcess';
import HomeServices from './Components/HomeServices';
import HomeAbout from './Components/HomeAbout';
import HomeBenefits from './Components/HomeBenefits';
import HomeTestimonial from './Components/HomeTestimonial';
import Newsletter from '../../Components/Newsletter';


const Home = () => {
  return (
    <>
    <HomeBanner/>
    <HomeProcess/>
    <HomeServices/>
    <HomeAbout/>
    <HomeBenefits/>
    <HomeTestimonial/>
    <Newsletter/>
    </>
  );
};

export default Home;