import React from 'react'
import SignUpSection from './Components/SignUpSection';
import { Navigate } from 'react-router-dom';

const SignUp = () => {
  if(localStorage.getItem('token')){
    return (
        <Navigate to='/account' replace={true} />
    );
  }
  else{
    return (
        <SignUpSection/>
    );
  };
};

export default SignUp;