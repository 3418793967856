import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../../../store/shared/setting";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BASE_URL from "../../../config/host";
import axios from "axios";
import { toast } from "react-toastify";

const PointsOrganizationDetails = ({ data, usrId, existingPoint }) => {
  const [point, setPoint] = useState(10);
  const [maxPoint, setMaxPoint] = useState(10);
  const [pointVal, setPointVal] = useState();
  const [settinglist, setSettinglist] = useState();
  const [modal, setModal] = useState("modal");
  const [isCreating, setIsCreating] = useState(false);

  const orgId = data?._id;
  const userId = usrId;
  const dispatch = useDispatch();

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  useEffect(() => {
    setMaxPoint(existingPoint?.total_point);
    axios
      .post(
        BASE_URL + "site-setting/details",
        { id: "624c1d79ac364e5ef3796d76" },
        { headers: headers }
      )
      .then(function (response) {
        setSettinglist(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [existingPoint]);

  function handleIncrease() {
    if (maxPoint > point + 5) {
      setPoint(point + 5);
    } else {
      setPoint(maxPoint);
      toast.error("You have reached your point balance.", { theme: "colored" });
    }
  }

  function handleDecrease() {
    if (point > 10) {
      setPoint(point - 5);
    }
  }

  useEffect(() => {
    setPointVal(settinglist?.point_per_euro);
  }, [settinglist]);

  function handleClick() {
    setIsCreating(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };

    axios
      .post(
        `${BASE_URL}spend-point`,
        { organization_id: orgId, point: point, point_type: "donation" },
        { headers: headers }
      )
      .then(function (response) {
        if (response.data.status === 400) {
          toast.error(response.data.message, { theme: "colored" });
        }
        if (response.data.status === 200) {
          toast.success(response.data.message, { theme: "colored" });
          setTimeout(function () {
            $("#addonsModal").modal("hide");
            setIsCreating(false);
          }, 6000);
        }
        setPoint(10);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <ToastContainer theme="colored" />
      <section className="organization-details">
        <div className="container">
          <div className="modal-header">
            <h5 className="modal-title">Donation</h5>
            <button
              type="button"
              data-dismiss={modal}
              aria-label="close"
              className="modal-close"
              id="close"
            >
              <i className="fal fa-fw fa-times"></i>
            </button>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="details-part-organization">
                <div className="organization">
                  <div className="logo">
                    <img src={data?.image} alt="" />
                  </div>
                  <div className="name-organization">{data?.title}</div>
                </div>
                <div className="organization-details-content">
                  <p>{data?.description}</p>
                </div>
                <div className="points-donate-part">
                  <div className="points">
                    <div className="points-donate">
                      <button
                        type="button"
                        className="btn-points"
                        onClick={handleDecrease}
                      >
                        -
                      </button>
                      <span className="input-style">{point} Points</span>
                      <button
                        type="button"
                        className="btn-points"
                        onClick={handleIncrease}
                      >
                        +
                      </button>
                    </div>
                    <div className="points-value">
                      <span>=</span> €{point / pointVal}
                    </div>
                  </div>
                </div>
                <div className="donate-action">
                  <button
                    className="action-btn"
                    type="button"
                    onClick={handleClick}
                    disabled={isCreating}
                  >
                    {isCreating && (
                      <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
                    )}
                    Donate
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="donation-image">
                <img src="assets/img/organization.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PointsOrganizationDetails;
