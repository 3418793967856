import React from 'react'
import ForgetPasswordSection from './Components/ForgetPasswordSection';
import { Navigate } from 'react-router-dom';

const ForgetPassword = () => {
  if(localStorage.getItem('token')){
    return (
        <Navigate to='/account' replace={true} />
    );
  }
  else{
    return (
        <ForgetPasswordSection/>
    );
  };
};

export default ForgetPassword;