import $ from "jquery";
import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../../../config/host";

import { useDispatch } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { updateCartList } from "../../../store/cartlist/cartlist";
import { addedToCartApi } from "../../../helpers/cart_helper";

const MenuList = ({ newItemList }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState();

  const handleParam = async (id) => {
    try {
      const res = await axios.post(
        BASE_URL + "v2/products/details",
        { id: id },
        {}
      );
      if (res.data.status === 200 || res.data.status === 201) {
        setDetails(res.data.data);
        $("#addonsModal").modal("show");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //ADDED TO CART
  const [productSpiceLevel, setProductSpiceLevel] = useState(null);
  const [productAddonPriceTot, setProductAddonPriceTot] = useState(0.0);
  const [isaddedToCartCreating, setaddedToCartCreating] = useState(false);
  const [addedOnArray, setAddedOnArray] = useState([]);

  const [maxAddonGroup, setMaxAddonGroup] = useState([]);

  useEffect(() => {
    setProductAddonPriceTot(
      details?.sale_price > 0 ? details?.sale_price : details?.regular_price
    );
  }, [details]);

  const handleSpiceLevel = (event) => {
    setProductSpiceLevel(event.target.value);
  };


  useEffect(() => {
    let maxAddonGroupTemp = [];
    details?.addon_group_list.forEach((item,index) => {
      var tempId = item._id;
      maxAddonGroupTemp.push({[tempId] : [{maxBuyQty : item.max_buy_qty, addonProductId:[]}]})
    });
    setMaxAddonGroup(maxAddonGroupTemp);
  }, [details]);


  const handleAddedOnProducts = (event, addonGroupId, groupIndx, addedOnPrice) => {
    if (event.target.checked) {
      maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.push([event.target.value]);
      var maxData = maxAddonGroup[groupIndx][addonGroupId][0].maxBuyQty;
      var pData = maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.length;
      if(pData <= maxData){
        setProductAddonPriceTot(productAddonPriceTot + addedOnPrice);
        setAddedOnArray([...addedOnArray, event.target.value]);
      }else{
        var index = maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.indexOf(event.target.value);
        maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.splice(index, 1);
        toast.error(`You could not select more than ${maxData} add on product.`, { theme: "colored" });
      }
    } else {
      setProductAddonPriceTot(productAddonPriceTot - addedOnPrice);
      setAddedOnArray(
        addedOnArray.filter((data) => data !== event.target.value)
      );
      var index = maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.indexOf(event.target.value);
      maxAddonGroup[groupIndx][addonGroupId][0].addonProductId.splice(index, 1);
    }
  };

  const addedToCart = async (
    productId,
    itemType,
    buyingMode,
    storeId = null,
    productSpiceLevel = null,
    addedOnArray = []
  ) => {
    setaddedToCartCreating(true);
    const returnData = await addedToCartApi(
      productId,
      itemType,
      buyingMode,
      storeId,
      productSpiceLevel,
      addedOnArray
    );
    if (returnData.data.status === 400) {
      toast.error(returnData.data.message, { theme: "colored" });
      setaddedToCartCreating(false);
    }
    if (returnData.data.status === 200 || returnData.data.status === 201) {
      toast.success(returnData.data.message, { theme: "colored" });
      setProductSpiceLevel(null);
      setAddedOnArray([]);
      setaddedToCartCreating(false);
      setProductAddonPriceTot(0.0);
      dispatch(updateCartList(returnData.data));
      setaddedToCartCreating(false);
      $("#addonsModal").modal("hide");
    }
  };

  const closeModal = (e) => {
    $(`#${e}`).modal("hide");
    setProductSpiceLevel(null);
    setAddedOnArray([]);
    setaddedToCartCreating(false);
    setProductAddonPriceTot(0.0);
  };

  return (
    <>
      <ToastContainer theme="colored" />

      {newItemList &&
        newItemList.map((item, i) => (
          <div
            className={"menu-item " + (item.showDescription ? "active" : "")}
            key={item._id.toString()}
            onClick={
              item.has_addon === true || item.has_spice_level === true
                ? () => {
                    handleParam(item._id);
                  }
                : null
            }
          >
            {/* {item?.short_description &&
              <button type="button" className="btn-product-info" onClick={() => changeToggle(i, item)}><i className="fa-regular fa-fw fa-info-circle"></i></button>
            } */}

            <div className="menu-inner">
              <div className="ingredients">
                <div className="list-contains">
                  {item?.allergie &&
                    item?.allergie.map((v, index) => (
                      <div className="icon" key={index}>
                        <img src={v.image} />
                       {/*  <i className={v.icon}></i> */}
                      </div>
                    ))}
                </div>
              </div>
              <div className="name">{item?.title}</div>
              <div className="menu-image">
                <img src={item?.image} alt="" />
              </div>

              <div className="description">
                <div
                  dangerouslySetInnerHTML={{ __html: item?.short_description }}
                />
              </div>

              <div className="menu-order-action">
                {item.in_stock === 1 &&
                item.has_addon !== true &&
                item.has_spice_level !== true ? (
                  <button
                    className="add-to-cart"
                    type="button"
                    onClick={(e) => {
                      addedToCart(
                        item?._id,
                        1,
                        1,
                        "634e739a7732d77728294d89",
                        productSpiceLevel,
                        addedOnArray
                      );
                    }}
                  >
                    <div className="icon">
                      <i className="icon-buy"></i>{" "}
                    </div>
                    {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                      <small style={{ textDecorationLine: "line-through" }}>
                        €{item?.regular_price}
                      </small>
                      ):''
                    }
                    {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                      <div className="price">€{item?.sale_price}</div>
                    ) : (
                      <div className="price">€{item?.regular_price}</div>
                    )}
                    <div className="text">Add to Cart</div>
                  </button>
                ) : (
                  <button className="add-to-cart" type="button">
                    <div className="icon">
                      <i className="icon-buy"></i>{" "}
                    </div>
                    {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                      <small style={{ textDecorationLine: "line-through" }}>
                        €{item?.regular_price}
                      </small>
                      ):''
                    }
                    {(item?.regular_price > item?.sale_price) && (item?.sale_price !== 0) ? (
                      <div className="price">€{item?.sale_price}</div>
                    ) : (
                      <div className="price">€{item?.regular_price}</div>
                    )}
                    {item.in_stock === 1 ? (
                      <div className="text">Order Now</div>
                    ) : item.in_stock === 2 ? (
                      <div className="text">Out of Stock</div>
                    ) : item.in_stock === 3 ? (
                      <div className="text">Today out of Stock</div>
                    ) : (
                      ""
                    )}
                  </button>
                )}

                {/* <button className='add-to-cart' type='button'>
                  <div className='icon'><i className='icon-buy'></i> </div>
                  {item?.regular_price > item?.sale_price &&
                  <small style={{textDecorationLine: 'line-through'}}>€{item?.regular_price}</small>
                  }
                  { 
                  (item?.regular_price > item?.sale_price) ? 
                  <div className='price'>€{item?.sale_price}</div>:
                  <div className='price'>€{item?.regular_price}</div>
                  }
                  <div className='text'>Order Now</div>
                </button> */}
              </div>
            </div>
          </div>
        ))}

      {/* =====Product Details Modal===== */}
      <div
        className="modal modal-addons fade"
        id="addonsModal"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{details?.title}</h5>
              <button
                type="button"
                //data-dismiss="modal"
                //aria-label="close"
                onClick={(e) => closeModal("addonsModal")}
                className="modal-close"
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {details?.spice_level_list?.length > 0 && (
                  <div className="col-lg-12">
                    <div className="addons-title">Spice Level</div>
                  </div>
                )}
              </div>
              <div className="row">
                {details &&
                  details.spice_level_list.map((item, i) => {
                    return (
                      <div
                        className="col-4 col-lg-4 d-flex"
                        key={item._id.toString()}
                      >
                        <div className="addons-inner">
                          <div className="custom-checkbox">
                            <input
                              type="radio"
                              className="custom-checkbox-input"
                              value={item?._id}
                              name="spiceLevelRadio"
                              checked={item?._id === productSpiceLevel && true}
                              onChange={(e) => {
                                handleSpiceLevel(e);
                              }}
                            />
                            <div className="custom-checkbox-label">
                              <div className="addons-item">
                                <div className="addons-text">{item?.title}</div>
                                <div className="addons-img">
                                  <img src={item?.image} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {details?.addon_group_list &&
                details?.addon_group_list.length > 0 &&
                details?.addon_group_list?.map((addonGroupItem, n) => {
                  return (
                    <div className="row" key={addonGroupItem._id.toString()}>
                      <div className="col-12">
                        <div className="addons-title">
                        {addonGroupItem?.title}
                        </div>
                      </div>

                      {addonGroupItem?.addon_product_list &&
                        addonGroupItem?.addon_product_list?.map(
                          (addonProductItem, i) => {
                            return (
                              <>
                                <div
                                  className="col-6 col-lg-4"
                                  key={addonProductItem._id.toString()}
                                >
                                  <div className="coke-inner">
                                    <div className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox-input"
                                        value={addonProductItem?._id}
                                        checked={addedOnArray.includes(
                                          addonProductItem?._id
                                        )}
                                        onChange={(e) => {
                                          handleAddedOnProducts(
                                            e,
                                            addonGroupItem._id,
                                            n,
                                            addonProductItem?.sale_price > 0
                                              ? addonProductItem?.sale_price
                                              : addonProductItem?.regular_price
                                          );
                                        }}
                                      />
                                      <div className="custom-checkbox-label">
                                        <div className="coke-option">
                                          <div className="coke-price">
                                            €
                                            {addonProductItem?.sale_price > 0
                                              ? addonProductItem?.sale_price
                                              : addonProductItem?.regular_price}
                                          </div>
                                          <div className="coke-item">
                                            <div className="coke-content">
                                              <div className="coke-title">
                                                {addonProductItem.title}
                                              </div>
                                              <div className="coke-text">
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      addonProductItem.short_description,
                                                  }}
                                                />
                                              </div>
                                              {/* <div className="coke-velu">250 ml</div> */}
                                            </div>
                                            <div className="coke-img">
                                              <img
                                                src={addonProductItem.image}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <button
                                      type="button"
                                      className="coke-btn"
                                      data-toggle="modal"
                                      data-target="#cocaModal"
                                    >
                                      <i className="icon-i-icon"></i>{" "}
                                    </button> */}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                    </div>
                  );
                })}
            </div>
            <div className="modal-footer">
              <div className="cart-action">
                {/* <div className="cart-price">€{details?.sale_price}</div> */}
                {details?.in_stock === 1 ? (
                  <div className="cart-price">€{productAddonPriceTot}</div>
                ) : (
                  ""
                )}
                {details?.in_stock === 1 ? (
                  <button
                    type="button"
                    className="add-btn"
                    onClick={(e) => {
                      addedToCart(
                        details?._id,
                        1,
                        1,
                        "634e739a7732d77728294d89",
                        productSpiceLevel,
                        addedOnArray
                      );
                    }}
                    disabled={isaddedToCartCreating}
                  >
                    <div className="text">
                      {isaddedToCartCreating && (
                        <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
                      )}
                      Add Cart
                    </div>
                    <div className="icon">
                      <i className="fa-regular fa-angle-right"></i>
                    </div>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="add-btn"
                    disabled={isaddedToCartCreating}
                  >
                    {details?.in_stock === 2 ? (
                      <div className="text">Out of Stock</div>
                    ) : details?.in_stock === 3 ? (
                      <div className="text">Today out of Stock</div>
                    ) : (
                      ""
                    )}
                    <div className="icon">
                      <i className="fa-regular fa-angle-right"></i>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal modal-coca fade"
        id="cocaModal"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Coca Cola</h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="close"
                className="modal-close"
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="coca-inner">
                <div className="coca-content">
                  <div className="coca-item">
                    <div className="coca-text">
                      It is a long established fact.It is a long established
                      fact.It is a long.
                    </div>
                    <div className="coca-sub-text">500 ml</div>
                    <div className="coca-ingredients">
                      <div className="ingredients-title">Allergies</div>
                      <div className="list-contains">
                        <div className="icon">
                          <i className="icon-nut"></i>
                        </div>
                        <div className="icon">
                          <i className="icon-peas"></i>
                        </div>
                        <div className="icon">
                          <i className="icon-wheat"></i>
                        </div>
                        <div className="icon">
                          <i className="icon-almond"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="coca-img">
                    <img src="assets/img/coke.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="coca-action">
                <button type="button" className="coca-btn">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuList;
