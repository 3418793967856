import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchSettings } from '../../../store/shared/setting';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import BASE_URL from '../../../config/host'

const ContactUsForm = () => {
  const dispatch = useDispatch();
  const { settinglist } = useSelector(state => state.settings);

  useEffect(() => {
    dispatch(fetchSettings({ id : "624c1d79ac364e5ef3796d76" }))

  }, [dispatch]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    console.log(name, email, comment);
    axios
      .post(BASE_URL + "send-enquiry", {
        name: name,
        email: email,
        message: comment
      })
      .then(function (response) {
        //console.log(response);
        if (response.data.status === 400) {
          toast.error(response.data.message, { theme: "colored" });
          //setIsLoading(false); 
        }
        if (response.data.status === 200) {
          toast.success(response.data.message, { theme: "colored" });
          setName('');
          setEmail('');
          setComment('');
          setOneActive('active');
          setTwoActive('');
          //setIsLoading(false); 
          //setToken(response.data.data.access_token);
          //history("/account");
        }
      })
      .catch(function (error) {
        toast.error(error.message, { theme: "colored" });
        //setIsLoading(false); 
        //console.log(error);
      });
  }

  function isEmail(emailAdress){
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (emailAdress.match(regex)) 
    return true; 

   else 
    return false; 
  }

  function handleClick(e) {
    e.preventDefault();
    if (name === '' && email === '') {
      
      toast.error('Enter name and email', { theme: "colored" });
    } else if (name === '') {
      
      toast.error('Enter your name', { theme: "colored" });
    } else if (email === '') {
      
      toast.error('Enter your email', { theme: "colored" });
    } else if (isEmail(email)){
      
      setOneActive('');
      setTwoActive('active');
    } else {
      toast.error('The email format is invalid', { theme: "colored" });
    }
  }
 
  const [oneActive, setOneActive] = useState('active')
  const [twoActive, setTwoActive] = useState('')

  return (
    <>
      <ToastContainer theme="colored" />
      <section className='contact-us-from-section'>
        <div className='container'>
          <h2 className='contact-title'>Contact <span>us</span></h2>
          <div className='row d-flex align-items-center justify-content-between'>
            <div className='col-lg-6 col-md-6'>
              <div className='contact-form-part'>
                <h6 className='form-title'>Let’s Connect</h6>
                <div className='form-step'>
                  <div className={'step ' + oneActive} onClick={() => {setTwoActive(''); setOneActive('active');}} style={{cursor:'pointer'}}></div>
                  <div className={'step ' + twoActive} onClick={() => {setOneActive(''); setTwoActive('active');}} style={{cursor:'pointer'}}></div>
                </div>
                <div className='form'>
                  <div className={'form-item ' + oneActive}>
                    <form className='form-content'>
                      <div className="input-style"><input type="text" className="form-control" placeholder="Full Name" value={name} onChange={e => setName(e.target.value)}/></div>
                      <div className="input-style"><input type="text" className="form-control" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/></div>
                      <div className='form-action'>
                        <button className='btn-submit' onClick={(e) => handleClick(e)}>Continue</button>
                      </div>
                    </form>
                  </div>
                  <div className={'form-item ' + twoActive}>
                    <form className='form-content'>
                    <div className="input-style"><textarea type="text" rows="6" className="form-control message" value={comment} placeholder="Message" onChange={e => setComment(e.target.value)}></textarea></div>
                      <div className='form-action'>
                        <button className='btn-submit' type='submit' onClick={e => handleSubmit(e)}>Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='contact-details'>
                <div className='contact-list'>
                  <div className='contact-item'>
                    <div className='icon'><i className="fa-solid fa-fw fa-phone"></i></div>
                    <div className='text'>
                      {settinglist.phone}
                    </div>
                  </div>
                  <div className='contact-item'>
                    <div className='icon'><i className="fa-brands fa-fw fa-whatsapp"></i></div>
                    <div className='text'>
                      {settinglist.phone}
                    </div>
                  </div>
                  <div className='contact-item'>
                    <div className='icon'><i className="fa-regular fa-fw fa-envelope"></i></div>
                    <div className='text'>
                      {settinglist.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsForm;