import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchJobs } from "../../../store/career/job";
import { convertToBase64 } from "../../../helpers/image_helper";
import BASE_URL from '../../../config/host';

const ApplyForm = () => {

  


  const { joblist } = useSelector((state) => state.jobs);
  const uniqueTitle = [...new Set(joblist.map((item) => item))];

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [mtFile, setMtFile] = useState("")
  const [cvFile, setCvFile] = useState("")
  const [motivationUpload, setmotivationUpload] = useState('');
  const [cvUplade, setcvUplade] = useState('');


  const { slug } = useParams();
  const [jobId, setJobId] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [isReqMtv, setIsReqMtv] = useState();
  const [isReqCv, setIsReqCv] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJobs({ status: true }));
  }, [dispatch]);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  }
  useEffect(() => {
    axios
      .post(`${BASE_URL}job/details`,{
        type: "user",
        slug: slug
      }, {headers: headers})
      .then(function (response) {
        if (response.data.status === 400) {
          toast.error(response.data.message, { theme: "colored" });
        }
        if (response.data.status === 200) {
          setJobId(response.data.data._id)
          setIsReqMtv(response.data.data.required_motivation)
          setIsReqCv(response.data.data.required_cv)
        }
      })
      .catch(function (error) {
        toast.error(error.message, { theme: "colored" });
      });
  }, [slug]);


  const allowedExts = ["doc", "docx", "jpg", "jpeg", "png", "pdf"];
  const motivationUploader = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      // if (![...allowedExts].includes(ext)) {
      //   console.log("Please upload a valid file");
      //   toast("Please upload a valid file.");
      // } else {
      //   convertToBase64(e.target.files[0], setmotivationUpload);
      // }
      convertToBase64(e.target.files[0], setmotivationUpload);
    }
  };

  const cvUploader  = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      // if (![...allowedExts].includes(ext)) {
      //   console.log("Please upload a valid file");
      //   toast("Please upload a valid file.");
      // } else {
      //   convertToBase64(e.target.files[0], setcvUplade);
      // }
      convertToBase64(e.target.files[0], setcvUplade);
    }
  };


  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    axios
      .post(`${BASE_URL}job/add-apply`, {
        job_id: jobId,
        name: name,
        email: email,
        phone: phone,
        comment: comment,
        cv_upload: cvUplade,
        motivation: motivationUpload,
      })
      .then(function (response) {
        //console.log(response);
        if (response.data.status === 400) {
          toast.error(response.data.message, { theme: "colored" });
          setIsLoading(false); 
        }
        if (response.data.status === 200) {
          toast.success(response.data.message, { theme: "colored" });
          setIsLoading(false);
          setName("");
          setEmail("");
          setPhone("");
          setComment("");
          setmotivationUpload("");
          setcvUplade("");
        }
      })
      .catch(function (error) {
        toast.error(error.message, { theme: "colored" });
        setIsLoading(false); 
      });
  };


  useEffect(() => {
    if (uniqueTitle !== undefined) {
      const temp =
        uniqueTitle.length > 0 &&
        uniqueTitle.find((item) => {
          return item._id === jobId;
        });
      setJobTitle(temp?.title);
    }
  }, [jobId, uniqueTitle]);

  //console.log(isReqMtv, isReqCv);
  return (
    <>
      <ToastContainer theme="colored" />
      <section className="apply-section">
        <div className="container">
          <div className="form-section">
            <div className="title">Fill your details here</div>
            <div className="required-text">
              Required field <span>*</span>
            </div>
            <form className="form-content" onSubmit={handleSubmit}>
              <input type="hidden" id="base64pdf" />
              <div className="form-row">
                <div className="col-md-6">
                  <div className="vacancy-search">
                    <div className="dropdown vacancy-dropdown">
                      <button
                        className="dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                      >
                        {jobTitle ? jobTitle : "Select a vacancy*"}
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-scroll" id="search-vacancy">
                          {uniqueTitle?.length > 0 &&
                            uniqueTitle.map((item, i) => {
                              if (item._id === jobId) {
                                return (
                                  <button
                                    type="button"
                                    className="dropdown-item active"
                                    data-value={item.title}
                                    data-name={item.title}
                                    key={i}
                                    onClick={() => {
                                      setJobId(item._id);
                                      setJobTitle(item.title);
                                    }}
                                  >
                                    {item.title}
                                  </button>
                                );
                              } else {
                                return (
                                  <button
                                    type="button"
                                    className="dropdown-item"
                                    data-value={item.title}
                                    data-name={item.title}
                                    key={i}
                                    onClick={() => {
                                      setJobId(item._id);
                                      setJobTitle(item.title);
                                    }}
                                  >
                                    {item.title}
                                  </button>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-style">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name*"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-6">
                  <div className="input-style">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="E-mail*"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-style">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number*"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="input-style">
                <textarea
                  type="text"
                  rows="6"
                  className="form-control message"
                  placeholder="On what days are you available?*"
                  maxLength={300}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
              <div className="upload-section">
                <div className="upload-action">
                  <div className="input-file">
                    <input
                      name="image"
                      type="file"
                      id="file-input"
                      className="upload-file"
                     //onChange={(e) => handleUploadMotivation(e)}
                     onChange={motivationUploader}
                    />
                    <label className="file-input-label" htmlFor="file-input">
                      <i className="fa-solid fa-cloud-arrow-up mr-3"></i>Upload
                      Your Motivation{isReqMtv && ' *'}
                    </label>
                  </div>
                  {mtFile && <><strong>{mtFile} </strong>uploaded</>} 
                </div>
                <div className="upload-action">
                  <div className="input-file">
                    <input
                      name="image"
                      type="file"
                      id="file-input1"
                      className="upload-file"
                      //onChange={(e) => handleUploadCv(e)}
                      onChange={cvUploader}
                    />
                    <label className="file-input-label" htmlFor="file-input1">
                      <i className="fa-solid fa-cloud-arrow-up mr-3"></i>Upload
                      Your CV{isReqCv && ' *'}
                    </label>
                  </div>
              {cvFile && <><strong>{cvFile} </strong>uploaded</>}
                </div>
              </div>

              <div className="form-action">
                <button type="submit" className="submit-action d-flex align-items-center justify-content-md-center" disabled={isLoading} >
                  <div className="form-btn">{isLoading && <i className="fas fa-circle-notch fa-spin mr-2"></i>} Apply Now</div>{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApplyForm;
