import React, { useState, useEffect } from "react";
import { Swiper } from "swiper/react";
import { Pagination } from "swiper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchBlogcomments,
  Createblogcomment,
  deleteBlogcomment,
  profileData,
} from "../../../store/blog/blogcomment";
import moment from "moment/moment";
import "swiper/css";
import "swiper/css/pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
const BLogComment = ({ blog_id }) => {
  const [limit] = useState(10);
  const dispatch = useDispatch();
  const [user_id, setUser_id] = useState("");
  const { blogcommentlist } = useSelector((state) => state.blogcomments);
  const { blogcommentCount } = useSelector((state) => state.blogcomments);
  const [addComment, setAddComment] = useState({
    name: "",
    email: "",
    comment: "",
    blog_id: blog_id !== undefined && blog_id !== "" ? blog_id : "",
  });
  const inputChange = (e) => {
    setAddComment({
      ...addComment,
      [e.target?.name]: e.target.value,
    });
  };
  
  const handlePageClick = (event) => {
    dispatch(
      fetchBlogcomments({
        status: true,
        blog_id: blog_id,
        limit,
        offset: event.selected * limit,
      })
    );
  };
  useEffect(() => {
    if (blog_id !== undefined && blog_id !== "") {
      setAddComment({ ...addComment, blog_id: blog_id });
    }
  }, [blog_id]);

  const handleSubmit = async () => {
    try {
      const add = await Createblogcomment(addComment);
      if (add.status === 400) {
        toast.error(add.message, { theme: "colored" });
      }
      if (add.status === 200) {
        toast.success(add.message, { theme: "colored" });
        setAddComment({
          name: "",
          email: "",
          comment: "",
          blog_id: blog_id !== undefined && blog_id !== "" ? blog_id : "",
        });
        dispatch(
          fetchBlogcomments({
            status: true,
            blog_id: blog_id,
            limit,
            offset: 0,
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(
      fetchBlogcomments({
        status: true,
        blog_id: blog_id,
        limit,
        offset: 0,
      })
    );
  }, [blog_id]);

  useEffect(() => {
    async function fetchData() {
      let a = await profileData()
      if(a.status === 200) {
        setUser_id(a.data._id);
      }
    }
    fetchData()
  }, [])


  const handleDelete = async (cmnt_id) => {
    try {
      const add = await deleteBlogcomment({ id: cmnt_id });
      if (add.status === 400) {
        toast.error(add.message, { theme: "colored" });
      }
      if (add.status === 200) {
        toast.success(add.message, { theme: "colored" });

        dispatch(
          fetchBlogcomments({
            status: true,
            blog_id: blog_id,
            limit,
            offset: 0,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer theme="colored" />
      <section className="comments-section">
        <div className="container">
          <div className="comment-part">
            {blogcommentlist.length > 0 && (
              <div className="comment-post">
                <div className="title">Post Comments</div>
                <div className="comment-list">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={50}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    breakpoints={{
                      480: {
                        slidesPerView: 2,
                      },
                      760: {
                        spaceBetween: 10,
                        slidesPerView: 3,
                      },
                      990: {
                        spaceBetween: 30,
                        slidesPerView: 3,
                      },
                      1400: {
                        spaceBetween: 50,
                        slidesPerView: 3,
                      },
                    }}
                    className="service-slider"
                  >
                    {blogcommentlist.length > 0 &&
                      blogcommentlist.map((item, index) => {
                        const createdAtTime = item
                          ? new Date(item.created_at).toLocaleString("en-US", {
                              timeZone:
                                Intl.DateTimeFormat().resolvedOptions()
                                  .timeZone,
                            })
                          : "";

                        return (
                          <div className="comment-item">
                            <div className="comment-image">
                              <img src="/assets/img/default-img.jpg" alt="" />
                            </div>
                            <div className="comment">
                              <div className="comment-author">
                                <div className="author-info">
                                  <div className="name">{item?.name}</div>
                                  <div className="date">
                                    {moment(new Date(createdAtTime)).format(
                                      "DD MMM YYYY"
                                    )}
                                  </div>
                                </div>
                                {(item.user_id === user_id) && <div className="fa-regular fa-fw fa-trash-can" style={{ cursor: "pointer" }} onClick={() => handleDelete(item._id)}></div>}

                                {/* <div className="reply-text">
                                <div className="reply">Reply</div>
                              </div> */}
                              </div>
                              <div className="reply-comment">
                                <div className="text">
                                  <p>{item.comment}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Swiper>
                </div>
                {Math.ceil(blogcommentCount / limit) > 1 && (
                  <div className="pagenation-section">
                    <ReactPaginate
                      breakLabel=" ... "
                      breakClassName="px-2"
                      nextLabel=" >"
                      nextClassName="ml-2"
                      previousLabel="< "
                      previousClassName="mr-2"
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(blogcommentCount / limit) || 1}
                      containerClassName="pagination-number list-unstyled"
                      pageClassName="number"
                      activeClassName="active"
                    />
                  </div>
                )}
              </div>
            )}

            <div className="comment-add">
              <div className="title">Add Comment</div>
              <div className="comment-form">
                <form className="form-content">
                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="input-style">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          name="name"
                          value={addComment?.name}
                          onChange={inputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-style">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="E-mail"
                          name="email"
                          value={addComment.email}
                          onChange={inputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="input-style">
                    <textarea
                      type="text"
                      rows="6"
                      className="form-control message"
                      placeholder="Comment"
                      name="comment"
                      value={addComment.comment}
                      onChange={inputChange}
                    />
                  </div>
                  <div className="form-action">
                    <button
                      className="form-btn"
                      type="button"
                      onClick={handleSubmit}
                    >
                      <span>Send Comment</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BLogComment;
