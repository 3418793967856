import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchBanners } from "../../store/home/banner";
import { fetchPages } from "../../store/page/page";
import "./Css/Privacy.css";
import Newsletter from "../../Components/Newsletter";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const [aboutdata, setAboutData] = useState([]);
  useEffect(() => {
    const loadpage = async () => {
      try {
        setAboutData([]);
        const list = await fetchPages({ slug: "privacy-policy" });
        setAboutData(list);
      } catch (error) {}
    };
    loadpage();
  }, []);
  const { bannerlist } = useSelector((state) => state.banners);
  useEffect(() => {
    dispatch(fetchBanners({ slug: "privacy-policy" }));
  }, [dispatch]);
  function createMarkup() {
    return { __html: aboutdata?.description };
  }
 
  return (
    <>
      <section className="page-banner-section">
        <div className="container">
          <div className="banner-section">
            <div className="breadcrumb-section">
              <div className="link">
                <NavLink to="/" className="breadcrumb-link">
                  Home
                </NavLink>
              </div>
              <div className="seperator">/</div>
              <div className="link">
                <div dangerouslySetInnerHTML={{ __html: bannerlist?.title }} />
              </div>
            </div>
            <div className="page-title">
              <h1 className="title">
                <div dangerouslySetInnerHTML={{ __html: bannerlist?.title }} />
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="description-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6 order-md-1 order-2 order-sm-1">
              <div className="description-content">
                <div className="section-header">
                  <h2 className="section-title">
                  {aboutdata?.title}<span>{aboutdata?.subtitle}</span>
                  </h2>
                </div>
                <div className="content">
                  <div className="description">
                    <div dangerouslySetInnerHTML={createMarkup()} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-md-2 order-1 order-sm-2">
              <div className="description-img">
                {aboutdata?.image && <img src={aboutdata?.image} alt="Logo" />}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Newsletter />
    </>
  );
};

export default PrivacyPolicy;
