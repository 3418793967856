import React from 'react';
import BannerCatering from './Components/BannerCatering';
import SecDescription from './Components/SecDescription';
import SecForm from './Components/SecForm';
import SecRange from './Components/SecRange';
import './Css/Catering.css';
import Newsletter from '../../Components/Newsletter';

const Catering = () => {
  return (
    <>
      <BannerCatering/>
      <SecDescription/>
      <SecRange/>
      <SecForm/>
      <Newsletter/>
    </>
  );
};

export default Catering;