
import { createSlice, createAction } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";


export const updateUserSelectedAddress = createAction("updateUserSelectedAddress");

export const userSelectedAddressSlice = createSlice({
    name: 'getUserSelectedAddress',
    loading: false,
    initialState: {
        selectedAddressId : '',
        selectedZipCode : '',
    },
    extraReducers: (builder) => {
    builder
        .addCase(updateUserSelectedAddress, (state, action) => {
            state.loading = true;
            state.selectedAddressId = action.payload.selectedAddressId;
            state.selectedZipCode = action.payload.selectedZipCode;
        })
    },
});














