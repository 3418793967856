import React from 'react';
import ContactDetailsItem from './ContactDetailsItem';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchFaqs } from '../../../store/contact-detail/faq'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
const SectionContactDetails = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState("");
    const {faqlist } = useSelector(state => state.faqs);
console.log(id);
    useEffect(() => {
      dispatch(fetchFaqs({ status: true, keyword: keyword, category: id , sortByField: "ordering", sortByValue: 1}))
    }, [dispatch, keyword]);



    // const res = axios.get("https://api.ipify.org?format=json", {})
    // console.log(res);


    return (
        <>
        <ToastContainer theme="colored" />
        <section className='help-search-section' >
                <div className='container'>
                <div className='help-search'>
                    <h2 className='text'>HOW CAN WE HELP?</h2>
                    <div className='search-section'>
                    <div className='search-input'><input type='text' placeholder='Search' className='form-control' value={keyword} onChange={(e) => {setKeyword(e.target.value)}}/><div className='icon'><i className='fa-regular fa-magnifying-glass'></i></div></div>
                    </div>
                </div>
                </div>
            </section>

           <section className='contact-details'>
                <div className='container'>
                    <div className='row'>
                        {/* <div className='col-lg-12'>
                        <div className='details-payments'>
                            <button className='payments-icon'><i className="fa-regular fa-angle-left"></i></button>
                            <div className='Payments-item'>
                                <div className='icon'>
                                    <i className='icon-about-icon-5'></i>
                                </div>
                                <div className='feature-content'>
                                    <h2 className='title'>Payments</h2>
                                </div>
                            </div>
                             <button className='payments-icon close'><i className="fa-regular fa-angle-right"></i></button>
                            </div>
                        </div> */}
                        <div className='col-lg-12'>
                {/* <ContactDetailsItem /> */}
                <div className='feature-content'>
                    <h2 className='title'>{faqlist[0]?.category?.name}</h2>
                </div>
                {faqlist.length > 0 ? faqlist.map((item, i)=> {

                    return (
                        <ContactDetailsItem
                            KeyId={item._id}
                            Question={item.question}
                            Answer={item.answer}
                            Cat_id={faqlist[0]?.category?._id}
                        />

                    )}) : <h2 className='title'>No Data</h2>}
               {/*  <div className='detailsitem active'>
                    <div className='details-item-title'>
                    <h6 className='text'>Payment receipts</h6>
                    </div>
                    <div className='details-content'>
                        <div className='content'>
                            <div className='descriptions'>
                                <p>When you pay for an order, you'll get an email confirming your payment sent to the email address you use for your Dabba Walla account. This email includes all your payment details.</p>
                                <p>Receipts for payments on Dabba Walla include your number of orders, charges,  delivery charges, and Total amount you paid for.</p>
                                <p>If you lose your payment confirmation or need another copy, you can request another email receipt.</p>
                            </div>
                            <div className='descriptions-title'><h5>To request a receipt:</h5></div>
                            <ul className='descriptions-list'>
                                <li>1. In your Dabba Walla account, open your My Purchases page.</li>
                                <li>2. Find the order you want a receipt for.</li>
                                <li>3. In the Purchase Date column, click Email Receipt.</li>
                                <li>In your Dabba Walla account, open your My Purchases page.</li>
                            </ul>
                            <div className='descriptions'>
                                <p>Check the email address you use for your Dabba Walla account. You should get your receipt within 1 mint..</p>
                            </div>
                            <div className='descriptions-title'><h5>Unfortunately, Dabba Walla cannot:</h5></div>
                            <ul>
                                <li>Customize receipts with business details (e.g., VAT number) or other information that is not included on our standard receipt.</li>
                                <li>Provide invoices in advance of payment. You can see the costs for a course or subscription by clicking Enroll on the order home page.</li>
                                <li>Mail a hard copy of your receipt.</li>
                            </ul>
                            <div className='descriptions'>
                                <p>We apologize for any inconvenience.</p>
                            </div>
                        </div>
                        <div className='helpfull-inner'>
                                <div className='helpfull-title'>Was this article helpful?</div>
                                <div className='emoji-content'>
                                <button type='button' className='emoji-btn'>
                                    <div className='icon'><i className="fa-light fa-face-smile"></i></div>
                                    <div className='text'>Yes</div>
                                </button>
                                <button type='button' className='emoji-btn'>
                                    <div className='icon'><i className="fa-light fa-face-frown"></i></div>
                                    <div className='text'>No</div>
                                </button>
                                </div>
                            </div>
                    </div>
                </div>
                <div className='detailsitem active'>
                    <div className='details-item-title'>
                    <h6 className='text'>Change your order plan?</h6>
                    </div>
                    <div className='details-content'>
                        <div className='content'>
                            <div className='descriptions'>
                                <p>When you pay for an order, you'll get an email confirming your payment sent to the email address you use for your Dabba Walla account. This email includes all your payment details.</p>
                                <p>Receipts for payments on Dabba Walla include your number of orders, charges,  delivery charges, and Total amount you paid for.</p>
                                <p>If you lose your payment confirmation or need another copy, you can request another email receipt.</p>
                            </div>
                            <div className='descriptions-title'><h5>To request a receipt:</h5></div>
                            <ul className='descriptions-list'>
                                <li>1. In your Dabba Walla account, open your My Purchases page.</li>
                                <li>2. Find the order you want a receipt for.</li>
                                <li>3. In the Purchase Date column, click Email Receipt.</li>
                                <li>In your Dabba Walla account, open your My Purchases page.</li>
                            </ul>
                            <div className='descriptions'>
                                <p>Check the email address you use for your Dabba Walla account. You should get your receipt within 1 mint..</p>
                            </div>
                            <div className='descriptions-title'><h5>Unfortunately, Dabba Walla cannot:</h5></div>
                            <ul>
                                <li>Customize receipts with business details (e.g., VAT number) or other information that is not included on our standard receipt.</li>
                                <li>Provide invoices in advance of payment. You can see the costs for a course or subscription by clicking Enroll on the order home page.</li>
                                <li>Mail a hard copy of your receipt.</li>
                            </ul>
                            <div className='descriptions'>
                                <p>We apologize for any inconvenience.</p>
                            </div>
                        </div>
                        <div className='helpfull-inner'>
                                <div className='helpfull-title'>Was this article helpful?</div>
                                <div className='emoji-content'>
                                <button type='button' className='emoji-btn'>
                                    <div className='icon'><i className="fa-light fa-face-smile"></i></div>
                                    <div className='text'>Yes</div>
                                </button>
                                <button type='button' className='emoji-btn'>
                                    <div className='icon'><i className="fa-light fa-face-frown"></i></div>
                                    <div className='text'>No</div>
                                </button>
                                </div>
                            </div>
                    </div>
                </div>
                <div className='detailsitem active'>
                    <div className='details-item-title'>
                    <h6 className='text'>Accepted payment methods?</h6>
                    </div>
                    <div className='details-content'>
                        <div className='content'>
                            <div className='descriptions'>
                                <p>When you pay for an order, you'll get an email confirming your payment sent to the email address you use for your Dabba Walla account. This email includes all your payment details.</p>
                                <p>Receipts for payments on Dabba Walla include your number of orders, charges,  delivery charges, and Total amount you paid for.</p>
                                <p>If you lose your payment confirmation or need another copy, you can request another email receipt.</p>
                            </div>
                            <div className='descriptions-title'><h5>To request a receipt:</h5></div>
                            <ul className='descriptions-list'>
                                <li>1. In your Dabba Walla account, open your My Purchases page.</li>
                                <li>2. Find the order you want a receipt for.</li>
                                <li>3. In the Purchase Date column, click Email Receipt.</li>
                                <li>In your Dabba Walla account, open your My Purchases page.</li>
                            </ul>
                            <div className='descriptions'>
                                <p>Check the email address you use for your Dabba Walla account. You should get your receipt within 1 mint..</p>
                            </div>
                            <div className='descriptions-title'><h5>Unfortunately, Dabba Walla cannot:</h5></div>
                            <ul>
                                <li>Customize receipts with business details (e.g., VAT number) or other information that is not included on our standard receipt.</li>
                                <li>Provide invoices in advance of payment. You can see the costs for a course or subscription by clicking Enroll on the order home page.</li>
                                <li>Mail a hard copy of your receipt.</li>
                            </ul>
                            <div className='descriptions'>
                                <p>We apologize for any inconvenience.</p>
                            </div>
                        </div>
                        <div className='helpfull-inner'>
                                <div className='helpfull-title'>Was this article helpful?</div>
                                <div className='emoji-content'>
                                <button type='button' className='emoji-btn'>
                                    <div className='icon'><i className="fa-light fa-face-smile"></i></div>
                                    <div className='text'>Yes</div>
                                </button>
                                <button type='button' className='emoji-btn'>
                                    <div className='icon'><i className="fa-light fa-face-frown"></i></div>
                                    <div className='text'>No</div>
                                </button>
                                </div>
                            </div>
                    </div>
                </div> */}
                        </div>
                    </div>
                </div>

           </section>
        </>
    );
};

export default SectionContactDetails;