import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchBanners } from "../../store/home/banner";
import { fetchPages } from "../../store/page/page";
import "./Css/Terms.css";
const Terms = () => {
  const dispatch = useDispatch();
  const [aboutdata, setAboutData] = useState([]);
  useEffect(() => {
    const loadpage = async () => {
      try {
        setAboutData([]);
        const list = await fetchPages({ slug: "terms-conditions" });
        setAboutData(list);
      } catch (error) {}
    };
    loadpage();
  }, []);
  const { bannerlist } = useSelector((state) => state.banners);

  useEffect(() => {
    dispatch(fetchBanners({ slug: "terms-conditions" }));
  }, [dispatch]);
  function createMarkup() {
    return { __html: aboutdata?.description };
  }
  return (
    <>
      <section className="page-banner-section">
        <div className="container">
          <div className="banner-section">
            <div className="breadcrumb-section">
              <div className="link">
                <NavLink to="/" className="breadcrumb-link">
                  Home
                </NavLink>
              </div>
              <div className="seperator">/</div>
              <div className="link">
                <div dangerouslySetInnerHTML={{ __html: bannerlist?.title }} />
              </div>
            </div>
            <div className="page-title">
              <h1 className="title">
              <div dangerouslySetInnerHTML={{ __html: bannerlist?.title }} />
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="other-page-content">
        <div className="container">
          <div className="content">
            <div dangerouslySetInnerHTML={createMarkup()} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
