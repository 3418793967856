import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchDeliveryCharges } from '../../../store/delivery-charges/deliveryCharges';
import { updateUserSelectedAddress } from '../../../store/set-user-selected-address/userSelectedAddress';
import axios from 'axios';
import BASE_URL from "../../../config/host";
//import { fetchCartList } from "../../../store/cartlist/cartlist";

const DeliveryOptions = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  }

  const { deliveryCharges } = useSelector((state) => state.getDeliveryCharges);
  const { isSetZipCode } = useSelector((state) => state.getDeliveryCharges);

  const [zipCodeAddressId, sezipCodeAddressId] = useState('');
  const [zipCodeAddress, setZipCodeAddress] = useState('');
  const [zipCodeCustom, setZipCodeCustom] = useState('');

  const [changeAddressTab, setChangeAddressTab] = useState(false);
  const [changeOthersTab, setChangeOthersTab] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [buttonState, setButtonState] = useState(false);

  const { cartlistCount } = useSelector((state) => state.cartList);




  useEffect(() => {
    setButtonState(isSetZipCode)
  }, [isSetZipCode]);



  const getDefaultAddress = async ()=>{
    try{
      const response = await axios.post(`${BASE_URL}profile-details`,{ },{headers: headers})
      sezipCodeAddressId(response.data?.data?.user_default_address?._id);
      setZipCodeAddress(response.data?.data?.user_default_address?.zipcode);
      setChangeAddressTab(true);
      return response.data;
    }catch(error){
      return error
    }
  }
  
  useEffect(() => {
    getDefaultAddress();
  }, []);


  useEffect(() => {
      axios.post(`${BASE_URL}user-address/list`,{ },{headers: headers})
      .then(function (response) {
      setAddressList(response.data.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  },[])


  const handleSetDelevaryOption = async(data)=>{
    sessionStorage.setItem("delevaryTypeOption",data);
    if(data === 1){
      const response = await getDefaultAddress()
      if(response.status === 200 || response.status === 201) {
        dispatch(
          fetchDeliveryCharges({ zip_code: response.data?.user_default_address?.zipcode}, { headers })
        );
        $('#zipCodeModal').modal('show');
      }
      
    }else{
      history("/order-delivery");
    }
  }


  const handleCheckZipCode = (defaultZipCode=null)=>{
    dispatch(
      fetchDeliveryCharges({ zip_code: defaultZipCode || zipCodeCustom }, { headers })
    );
  }


  const handleChangeAddressTab = (indx)=>{
      if((indx === 2) && (changeAddressTab === true)){
        setButtonState(false)
        setChangeAddressTab(false)
        setChangeOthersTab(true)
        handleCheckZipCode(zipCodeCustom)
      }else if((indx=== 1) && (changeAddressTab === false)){
        setButtonState(false)
        setChangeAddressTab(true)
        setChangeOthersTab(false)
        handleCheckZipCode(zipCodeAddress)
      }
  }


  const handleChangeZipCode = (e, id, index1) => {
    setAddressList((data) =>
      data?.map((list, index) =>
        index === index1 ? { ...list, is_default: true } : { ...list, is_default: false }
      )
    )
    sezipCodeAddressId(id);
    setZipCodeAddress(e.target.value);
    handleCheckZipCode(e.target.value)
  };

  const pageRedirect = ()=>{
    let tempData = {}
    if(changeAddressTab === true){
      tempData = {
        selectedAddressId : zipCodeAddressId,
        selectedZipCode : '',
      }
    }else{
      tempData = {
        selectedAddressId : '',
        selectedZipCode : zipCodeCustom,
      }
    }
    dispatch(updateUserSelectedAddress(tempData));
    $('#zipCodeModal').modal('hide');
    history("/order-delivery");
  }



  const dismissAddressModal = ()=>{
    dispatch(
      fetchDeliveryCharges({ zip_code: ''}, { headers })
    );
    sezipCodeAddressId(null);
    setZipCodeCustom(null)
    setChangeAddressTab(false)
    setChangeOthersTab(false)
    $('#zipCodeModal').modal('hide');
  }


  return (
    <>
      <section className='delivery-options-section'>
        <div className='container'>
          {
            (cartlistCount>0)
            ?(
              <div className='row'>
                <div className='col-md-6'>
                  <div className='delivery-option'>
                    <div className='delivery-image'>
                      <img src='assets/img/delivery-pick-up.svg' alt=''/>
                    </div>
                    {/* <Link to='/delivery-registration' className='option-action'>Pick Up</Link> */}
                    <button type="button" className="option-action" onClick={(e)=>{handleSetDelevaryOption(2)}}>Pick Up</button>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='delivery-option'>
                    <div className='delivery-image'>
                      <img src='assets/img/delivery-man-cycle.svg' alt=''/>
                    </div>
                    {
                      (localStorage.getItem('token'))
                      ?(<button type="button" className="option-action" onClick={(e)=>{handleSetDelevaryOption(1)}}>Delivery</button>)
                      :(<button type="button" className="option-action" onClick={(e)=>{handleSetDelevaryOption(2)}}>Delivery</button>)
                    }
                  </div>
                </div>
              </div>
            )
            :(
              <div className='row'>
                <div className='col-md-6 m-auto'>
                  <div className='delivery-option'>
                    <div className='delivery-image'>
                      <img src='assets/img/delivery-pick-up.svg' alt=''/>
                    </div>
                    <Link to='/menu' className='option-action'>Go to menu</Link>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </section>


      <div 
        className="modal custom-alert-modal fade" 
        id="zipCodeModal" 
        data-backdrop="static"
        role="dialog" 
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Check Your Delivery Charges</h5>
            </div>
            <div className="modal-body">
             {/*  <p>Select your Zip Code to know about the delivery charges.</p> */}

              <ul className="address-changer">
                <button className={changeAddressTab ? 'menuitem active' : 'menuitem'} onClick={()=>handleChangeAddressTab(1)}>Your Address</button>
                <button className={changeOthersTab ? 'menuitem active' : 'menuitem'} onClick={()=>handleChangeAddressTab(2)} >Other Address</button>
              </ul>

              {
                changeOthersTab &&
                (<div className="row">
                  <div className="col-8">
                    <div className="input-style">
                      <input type="text" className="form-control" placeholder="Zip Code" value={zipCodeCustom}  onChange={e => setZipCodeCustom(e.target.value)}/>
                    </div>
                  </div>
                  <div className="col-4">
                    <button 
                    type="button" 
                    className="cnf-btn"
                    onClick={()=>handleCheckZipCode()} 
                    >
                      Check
                    </button>
                  </div>
                </div>)
              }






              <div className="select-default-address">
              {changeAddressTab && addressList && addressList?.map((item, index) => (
                <div className="address-item">
                  <div className="custom-radio">
                    <input type="radio" className="custom-radio-input" name="address" checked={(item?._id === zipCodeAddressId)} value={item?.zipcode}  onChange={(e) =>   handleChangeZipCode(e,item?._id,index)} />
                    <label className="custom-radio-label">
                      <div className="text">{item?.zipcode}</div>
                    </label>
                  </div>
                </div>
              ))}

              {
                ((changeAddressTab) && addressList.length === 0) ?
                <div className="font-weight-bold text-center">You have not saved any address.</div>
                :''
              }


              </div>
              
            </div>
            <div className="modal-footer">
              {
                (isSetZipCode)
                ? (<h6 className = "mr-auto">Charge : €{deliveryCharges?.delivery_charge.toFixed(2)}</h6>)
                : (<h6 className = "mr-auto"><i className="fa-light fa-cart-xmark mr-2"></i>Delivery not available</h6>)
              }
              <button 
              type="button" 
              className="cnf-btn alt" 
              onClick={dismissAddressModal}
              >
                Cancel
              </button>

              {
                (buttonState)
                ?(<button type="button" className="cnf-btn" onClick={pageRedirect} disabled={!buttonState}>Continue</button>)
                :(<button type="button" className="cnf-btn" disabled={!buttonState}>Continue</button>)
              }

            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default DeliveryOptions;