import React from 'react';

const DeliveryProgress = () => {
  return (
    <>
      <section className='delivery-progress-section'>
        <div className='container'>
          <div className='delivery-progress-part'>
            <div className='progress-bar-navigate'>
              <div className='parts active'>
                <div className='icon'>
                  <img src='assets/img/step-1-delivery.svg' alt='' />
                </div>
                <div className='delivery'>
                  <img src='assets/img/step-active.svg' alt='' />
                </div>
                <div className='text'>Delivery Method</div>
              </div>
              <div className='parts'>
                <div className='icon'>
                  <img src='assets/img/step-2-delivery.svg' alt='' />
                </div>
                <div className='delivery '>
                  <img src='assets/img/step-active.svg' alt='' />
                </div>
                <div className='text'>Account</div>
              </div>
              <div className='parts'>
                <div className='icon'>
                  <img src='assets/img/step-3-delivery.svg' alt='' />
                </div>
                <div className='delivery '>
                  <img src='assets/img/step-active.svg' alt='' />
                </div>
                <div className='text'>Delivery Address</div>
              </div>
            </div>
            <div className='progress'>
              <div className='progress-bar' style={{ width: "50%" }}></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeliveryProgress;