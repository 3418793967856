import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation, Pagination } from "swiper";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchHelps } from '../../../store/contact/help-feature';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";

const HelpFeature = () => {
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();
  const { helplist } = useSelector(state => state.helps);

  useEffect(() => {
    dispatch(fetchHelps({ category_type: "faqs", keyword: keyword, sortByField: 'ordering', sortByValue: 1}))

  }, [dispatch, keyword]);



  return (
    <>
     <section className='help-search-section' >
        <div className='container'>
          <div className='help-search'>
            <h2 className='text'>HOW CAN WE HELP?</h2>
            <div className='search-section'>
            <div className='search-input'><input type='text' placeholder='Search' className='form-control' value={keyword} onChange={(e) => {setKeyword(e.target.value)}}/><div className='icon'><i className='fa-regular fa-magnifying-glass'></i></div></div>
            </div>
          </div>
        </div>
      </section>

      <section className='help-feature-section'>

        <div className='container'>
          <div className='feature-list'>
            <Swiper
              slidesPerView={1}
              navigation
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              breakpoints={{

                480: {
                  slidesPerView: 1,
                  grid: {
                    rows: 1,
                  }
                },
                768: {
                  spaceBetween: 20,
                  slidesPerView: 2,
                  grid: {
                    rows: 1,
                  }
                },
                990: {
                  spaceBetween: 20,
                  slidesPerView: 3,
                  grid: {
                    rows: 2,
                  }
                },
                1400: {
                  spaceBetween: 20,
                  slidesPerView: 3,
                  grid: {
                    rows: 2,
                  }
                }
              }}
              modules={[Grid, Navigation, Pagination]}
              className="feature-category-slider"
            >
              {helplist?.length > 0 && helplist.map((item, i)=> {

                return (
              <SwiperSlide key={i}>
                <Link to={'/contact-details/' + item.alias}>
                <div className='feature-item'>
                  <div className='feature-inner'>
                    <div className='icon'>
                      {/* <i className='icon-about-icon-1'></i> */}
                      <img src={item.image}/>
                    </div>
                    <div className='feature-content'>
                      <h2 className='title'>{item.name}</h2>
                      <div className='description'>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
                </Link>
              </SwiperSlide>
                )})}
              {/* <SwiperSlide>
                <div className='feature-item'>
                  <div className='feature-inner'>
                    <div className='icon'>
                      <i className='icon-about-icon-2'></i>
                    </div>
                    <div className='feature-content'>
                      <h2 className='title'>Orders</h2>
                      <div className='description'>
                        <p>Billing options, refunds, and cancelations</p>
                      </div>
                    </div>
                  </div>

                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='feature-item'>
                  <div className='feature-inner'>
                    <div className='icon'>
                      <i className='icon-about-icon-3'></i>
                    </div>
                    <div className='feature-content'>
                      <h2 className='title'>Security</h2>
                      <div className='description'>
                        <p>How to  set security changes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='feature-item'>
                  <div className='feature-inner'>
                    <div className='icon'>
                      <i className='icon-about-icon-4'></i>
                    </div>
                    <div className='feature-content'>
                      <h2 className='title'>Account Setup</h2>
                      <div className='description'>
                        <p>How to log in and change settings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='feature-item'>
                  <div className='feature-inner'>
                    <div className='icon'>
                      <i className='icon-about-icon-5'></i>
                    </div>
                    <div className='feature-content'>
                      <h2 className='title'>Payments</h2>
                      <div className='description'>
                        <p>Billing options, refunds, and cancelations</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='feature-item'>
                  <div className='feature-inner'>
                    <div className='icon'>
                      <i className='icon-about-icon-6'></i>
                    </div>
                    <div className='feature-content'>
                      <h2 className='title'>delivery</h2>
                      <div className='description'>
                        <p>How to deliver order and take delivery</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpFeature;