
import { createSlice, createAction } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";


export const updateCartList = createAction("updateCartList");

export const cartListSlice = createSlice({
    name: 'cartList',
    loading: false,
    initialState: {
        cartlist: [],
        cartlistCount: 0
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.cartlist ='';
            //console.log(action.payload);
        },
        listReceived: (state, action)=>{
            state.loading = false;
            state.cartlist = action.payload.data?action.payload.data:[];
            state.cartlistCount = action.payload.count || 0
        }
    },

    extraReducers: (builder) => {
    builder
        .addCase(updateCartList, (state, action) => {
            state.loading = false;
            state.cartlist = action.payload.data;
            state.cartlistCount = action.payload.count;
        })
    },
});


export const {listReceived,listRequested ,listRequestFailed } = cartListSlice.actions;
//console.log(body);


export const fetchCartList = (body, header)=> apiCallBegan({
    url: '/cart/list',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type,
    headers: header,
});














