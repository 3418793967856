import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import BASE_URL from '../config/host';

function GetGoogleUserData() {
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [res, setRes] = useState(null);
    

    useEffect(() => {
        async function postData() {
            await axios
            .post(`${BASE_URL}/google-login-2`, {
                code: searchParams.get("code")
            })
            .then(function (response) {
                setRes(response.data)
                if(response.data.is_success) {
                    localStorage.setItem('token', JSON.stringify(response.data.access_token));
                    window.location.href = "/account";
                } else{ 
                    window.location.href = "/sign-in";
                }
            //   window.location.href = response.data.data
            })
        }

        postData();
        
    }, [])

  return (
    <div></div>
  )
}

export default GetGoogleUserData