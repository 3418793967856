import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCategories } from "../../../store/category/category";

const BlogSearch = ({
  keyword,
  handleSearch,
  handleCategorySearch,
  handleDateRangeSearch,
}) => {
  const dispatch = useDispatch();
  // const { bloglist, blogCount } = useSelector((state) => state.blogs);
  const [searchtext, setSearchtext] = useState("");
  const { categorylist } = useSelector((state) => state.categories);
  const [showCategory, setShowCategory] = useState("ALL Category");

  useEffect(() => {
    dispatch(fetchCategories({ category_type: "blogs", searchtext }));
  }, [dispatch, searchtext]);

  return (
    <div className="blog-search">
      <div className="blog-search-input">
        <input
          type="text"
          placeholder="Search"
          className="form-control"
          value={keyword}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div className="icon">
          <i className="fa-regular fa-magnifying-glass"></i>
        </div>
      </div>
      <div className="blog-inner">
        <div className="category-search">
          <div className="dropdown category-dropdown">
            <button
              className="dropdown-toggle"
              type="button"
              data-toggle="dropdown"
            >
              {showCategory}
            </button>
            <div className="dropdown-menu">
              {/* <div className='dropdown-search'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search'
                  value={searchtext}
                  onInput={(e) => setSearchtext(e.target.value)}
                />
              </div> */}

              <div className="dropdown-scroll" id="search-category">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={(e) => {
                    handleCategorySearch("all");
                    setShowCategory("ALL Category");
                  }}
                >
                  ALL Category
                </button>
              </div>

              {categorylist.length > 0 &&
                categorylist.map((item, index) => {
                  return (
                    <div
                      className="dropdown-scroll"
                      id="search-category"
                      key={item._id}
                    >
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={(e) => {
                          handleCategorySearch(item._id);
                          setShowCategory(item.name);
                        }}
                      >
                        {item.name}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="date-search">
          <div className="dropdown date-dropdown">
            <button
              className="dropdown-toggle"
              type="button"
              data-toggle="dropdown"
            >
              Date
            </button>
            <div className="dropdown-menu">
              {/* <div className='dropdown-search'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search'
                />
              </div> */}
              <div className="dropdown-scroll" id="search-date">
              <button
                  type="button"
                  className="dropdown-item"
                  data-value="This Week"
                  data-name="This Week"
                  onClick={() => handleDateRangeSearch("all_data", true)}
                >
                  All Data
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-value="This Week"
                  data-name="This Week"
                  onClick={() => handleDateRangeSearch("weeks", true)}
                >
                  This Week
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-value="This Month"
                  data-name="This Month"
                  onClick={() => handleDateRangeSearch("months", true)}
                >
                  This Month
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-value="Last Week"
                  data-name="Last Week"
                  onClick={() => handleDateRangeSearch("weeks", false)}
                >
                  Last Week
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-value="Last Month"
                  data-name="Last Month"
                  onClick={() => handleDateRangeSearch("weeks", false)}
                >
                  Last Month
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-value="This Year"
                  data-name="This Year"
                  onClick={() => handleDateRangeSearch("years", true)}
                >
                  This Year
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-value="Last Year"
                  data-name="Last Year"
                  onClick={() => handleDateRangeSearch("years", false)}
                >
                  Last Year
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSearch;
