import React, { useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import axios from "axios";
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from "react-toastify";
import BASE_URL from '../../../config/host';

import "react-toastify/dist/ReactToastify.css";




  const SignUpSection = () => {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [otp, setOtp] = useState();
    const [resendOTPFlag, setResendOTPFlag] = useState(false);

    const [isCreating, setIsCreating] = useState(false);

    const [showHidePass1, setShowHidePass1] = useState('password');
    const [showHidePass2, setShowHidePass2] = useState('password');
    const [showHidePass3, setShowHidePass3] = useState('password');

    const [eyeIcon1, setEyeIcon1] = useState('fa-solid fa-eye-slash');
    const [eyeIcon2, setEyeIcon2] = useState('fa-solid fa-eye-slash');
    const [eyeIcon3, setEyeIcon3] = useState('fa-solid fa-eye-slash');



    const showHideHandle1 = (e) =>{
      if(e === 'password'){
        setShowHidePass1('text')
        setEyeIcon1('fa-solid fa-eye')
      }else{
        setShowHidePass1('password');
        setEyeIcon1('fa-solid fa-eye-slash')
      }
    }
    const showHideHandle2 = (e) =>{
      if(e === 'password'){
        setShowHidePass2('text')
        setEyeIcon2('fa-solid fa-eye')
      }else{
        setShowHidePass2('password');
        setEyeIcon2('fa-solid fa-eye-slash')
      }
    }

    const showHideHandle3 = (e) =>{
      if(e === 'password'){
        setShowHidePass3('text')
        setEyeIcon3('fa-solid fa-eye')
      }else{
        setShowHidePass3('password');
        setEyeIcon3('fa-solid fa-eye-slash')
      }
    }


    function setToken(userToken) {
      localStorage.setItem("token", JSON.stringify(userToken));
    }


    //Singup
    const handleSubmit = (e) => {
      e.preventDefault();
      if (password !== confPassword) {
        toast.error("Passwords don't match", { theme: "colored" });
      } else {
        setIsCreating(true);
        axios.post(`${BASE_URL}signup`, {
            name: name,
            email: email,
            password: password,
            confirm_password: confPassword,
            type: "user"
        })
        .then(function (response) {
          if (response.data.status === 400) {
            toast.error(response.data.message, { theme: "colored" });
            setIsCreating(false);
          }
          if (response.data.status === 200) {
            toast.success(response.data.message, { theme: "colored" });
            setActiveSection(false);
            setIsCreating(false);
            setTimeout(() => {
              setResendOTPFlag(true);
            }, 120000);
            //setToken(response.data.data.access_token);
          }
        })
        .catch(function (error) {
          toast.error(error.message, { theme: "colored" });
        });
      }
    };

    //OTP Verification
    const handleSubmitOTP = (e) => {
      e.preventDefault();
      if ((typeof otp === 'undefined') || (otp === null) ||  (otp === '')) {
          toast.error("OTP field is required", { theme: "colored" });
      } else {
        setIsCreating(true);
        axios.post(`${BASE_URL}auth/verify`, {
          otp: otp,
          email: email,
          type : 1,
        })
        .then(function (response) {
          if (response.data.status === 400) {
            toast.error(response.data.message, { theme: "colored" });
            setIsCreating(false);
          }
          if (response.data.status === 200) {
            toast.success(response.data.message, { theme: "colored" });
            setActiveSection(false);
            setToken(response.data.data.access_token);
            navigate("/account");
          }
        })
        .catch(function (error) {
          toast.error(error.message, { theme: "colored" });
        });
      }
    };

    //Resend OTP
    const handleRedendOTP = (e) => {
      e.preventDefault();
      axios.post(`${BASE_URL}resend-otp`, {
        email: email,
        type : 1,
      })
      .then(function (response) {
        if (response.data.status === 400) {
          toast.error(response.data.message, { theme: "colored" });
          setResendOTPFlag(true);
        }
        if (response.data.status === 200) {
            toast.success(response.data.message, { theme: "colored" });
            setResendOTPFlag(false)
            setTimeout(() => {
              setResendOTPFlag(true);
            }, 120000);
        }
      })
      .catch(function (error) {
        toast.error(error.message, { theme: "colored" });
      });
    }


  return (
    <>
      <ToastContainer theme="colored" />
      <section className="sec-authentication">
        <div className="authentication-img signup-image">
          <img src="assets/img/signup.jpg" alt="" />
        </div>
        <div className="authentication-wrap signup-wrap">
          <div className="authentication-hader">
            <Link to="/" className="authentication-logo">
              <img src="assets/img/logo.svg" alt="Logo" />
            </Link>
            <div className="became-title">
              <div className="text">have an account?</div>
              <Link to="/sign-in" className="click-btn">
                Sign in!
              </Link>
            </div>
          </div>

          {(activeSection === true)?
            (
            <div className="authentication-container">
              <div className="authentication-box">
                <div className="box-header">
                  <div className="title signup">
                    Get Started With <span>DABBA WALA</span>
                  </div>
                  <div className="sub-title">Getting started is easy</div>
                </div>
                <div className="authentication-action">
                  <a href="#!" className="authentication-btn">
                    <div className="btn-icon">
                      <img src="assets/img/google.svg" alt="" />
                    </div>
                    <div className="btn-text">Google</div>
                  </a>
                  <a href="#!" className="authentication-btn">
                    <div className="btn-icon">
                      <img src="assets/img/facebook.svg" alt="" />
                    </div>
                    <div className="btn-text">Facebook</div>
                  </a>
                </div>
                <div className="continue-inner">
                  <span>Or Register</span>
                </div>

                <div className="form-wrap">
                  <form onSubmit={handleSubmit}>
                    <div className="custom-form-group">
                      <input
                        type="text"
                        name="name"
                        minLength={4}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Full Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="custom-form-group">
                      <input
                        type="email"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Enter Email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="custom-form-group">
                      <input
                        type={showHidePass1}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Password"
                        name="password"
                        minLength={5}
                        maxLength={16}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div 
                      className="icon"
                      onClick={(e) =>{showHideHandle1(showHidePass1)}}
                      >
                        <i className={eyeIcon1}></i>
                      </div>
                    </div>
                    <div className="custom-form-group">
                      <input
                        type={showHidePass2}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        minLength={5}
                        maxLength={16}
                        onChange={(e) => setConfPassword(e.target.value)}
                      />
                      <div 
                      className="icon" 
                      onClick={(e) =>{showHideHandle2(showHidePass2)}}
                      >
                        <i className={eyeIcon2}></i>
                      </div>
                    </div>
                    <div className="log-action">
                      <button
                        className="action-btn became"
                        type="submit"
                        disabled={isCreating}
                      >
                        {isCreating && <i className="fa-solid fa-circle-notch fa-spin mr-2"></i>}
                        Became a member
                      </button>
                    </div>
                  </form>
                  <div className="became-title responsive">
                    <div className="text">have an account?</div>
                    <Link to="/sign-in" className="click-btn">
                      Sign in!
                    </Link>
                  </div>
                </div>

              </div>
            </div>
            )
          :
            (
            <div className="authentication-container">
              <div className="authentication-box">
                <div className="box-header">
                  <div className="title signup">
                    Verify Your<span> OTP</span>
                  </div>
                  
                </div>
              
                <div className="form-wrap">
                  <form onSubmit={handleSubmitOTP}>
                    <div className="custom-form-group">
                      <input
                        type={showHidePass3}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Enter OTP"
                        name="otp"
                        minLength={5}
                        maxLength={16}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <div 
                      className="icon" 
                      onClick={(e) =>{showHideHandle3(showHidePass3)}}
                      >
                        <i className={eyeIcon3}></i>
                      </div>
                    </div>

                    <div className="log-action">
                      <button
                        className="action-btn became"
                        type="submit"
                        disabled={isCreating}
                      >
                        {isCreating && <i className="fa-solid fa-circle-notch fa-spin mr-2"></i>}
                        Verify OTP
                      </button>
                    </div>
                    {(resendOTPFlag === true) ?
                    <div className="mt-3 text-center">
                      <button type="button" onClick={handleRedendOTP} className="btn btn-link">Resend OTP</button>
                    </div>
                    :''
                    }

                  </form>
                  <div className="became-title responsive">
                    <div className="text">have an account?</div>
                    <Link to="/sign-in" className="click-btn">
                      Sign in!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            )
          }

          <div className="authentication-footer">
            By continuing you indicate that you read and agreed to the Terms of
            Use
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpSection;
