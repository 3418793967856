import React from 'react';
import './Css/Loyalty.css'
import LoyaltyBanner from './Components/LoyaltyBanner';
import LoyaltySection from './Components/LoyaltySection';
import Newsletter from '../../Components/Newsletter';
const LoyaltyProgram = () => {
  return (
    <>
      <LoyaltyBanner/>
      <LoyaltySection/>
      <Newsletter/>
    </>
  );
};

export default LoyaltyProgram;