import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import BASE_URL from "../../../config/host";
import axios from "axios";

const OrderList = () => {
  const [orderData, setOrderData] = useState(null);
  const [filter, setFilter] = useState("All time");
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const handleDateRangeSearch = (value, dateStatus, filterData) => {
    if (dateStatus) {
      const start = moment().add(0, value).startOf(value).format("YYYY-MM-DD");
      const end = moment().add(0, value).endOf(value).format("YYYY-MM-DD");

      const range = {
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
      };
      setFilter(filterData);
      setDateRange(range);
    } else {
      const start = moment().subtract(1, value).startOf(value).format("YYYY-MM-DD");
      const end = moment().subtract(1, value).endOf(value).format("YYYY-MM-DD");

      const range = {
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
      };
      setFilter(filterData);
      setDateRange(range);
    }
  };

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };

    axios
      .post(BASE_URL + "order/list", { status: false, dateRange: dateRange }, { headers: headers })
      .then(function (response) {
        setOrderData(response.data.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dateRange, filter]);

  //console.log(orderData);
  if (orderData === null) {
    return (
      <div className="text-center">
        <div className="fad fa-spinner-third fa-spin" style={{ color: "red", fontSize: "2.5em", animationDuration: "1s", marginTop: "55px" }}></div>
      </div>
    );
  }
  return (
    <>
      <section className="order-list-section">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <div className="section-header">
                <h2 className="section-title">
                  {orderData?.length} <span>Orders</span>
                </h2>
              </div>
            </div>
            <div className="col-md-3">
              <div className="order-search-section">
                <div className="dropdown order-dropdown">
                  <button className="dropdown-toggle" type="button" data-toggle="dropdown">
                    {filter}
                  </button>
                  <div className="dropdown-menu">
                    <div className="dropdown-scroll" id="search-category">
                      <button type="button" className="dropdown-item" data-value="This Week" data-name="This Week" onClick={() => handleDateRangeSearch("weeks", true, "This Week")}>
                        This Week
                      </button>
                      <button type="button" className="dropdown-item" data-value="This Month" data-name="This Month" onClick={() => handleDateRangeSearch("months", true, "This Month")}>
                        This Month
                      </button>
                      <button type="button" className="dropdown-item" data-value="Last Month" data-name="Last Month" onClick={() => handleDateRangeSearch("months", false, "Last Month")}>
                        Last Month
                      </button>
                      <button type="button" className="dropdown-item" data-value="This Year" data-name="This Year" onClick={() => handleDateRangeSearch("years", true, "This Year")}>
                        This Year
                      </button>
                      <button type="button" className="dropdown-item" data-value="Last Year" data-name="Last Year" onClick={() => handleDateRangeSearch("years", false, "Last Year")}>
                        Last Year
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="order-history-slider">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              navigation
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1440: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1920: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Navigation, Pagination]}
              className="order-wrap-slider"
            >
              {orderData?.length > 0 &&
                orderData.map((item, i) => (
                  <SwiperSlide key={i}>
                    <div className="bill-section">
                      <div className="bill-title">Bill</div>
                      <div className="bill-date">
                        {/* <span>Mon</span> <span></span> <span>11:25AM</span> */}
                        {moment(new Date(item?.created_at)).format("ddd, DD/MM/YYYY, hh:mm a")}
                      </div>
                      <div className="bill-list">
                        {item?.order_details?.map((item, i) => {
                          return (
                            <div className="bill-item" key={i}>
                              <div className="menu-name">
                                <div className="text">{item?.product_name}</div>
                                <div className="sub-text">{item?.spice_level_info?.title}</div>
                              </div>

                              <div className="menu-item-number">
                                <div type="text" className="input-style">
                                  {item?.buying_mode === 1 ? (
                                    <span className="sub-text">{item?.price + "x" + item?.quantity}</span>
                                  ) : (
                                    <span className="sub-text">{item?.point_amount + "x" + item?.quantity}</span>
                                  )}
                                </div>
                              </div>
                              {item?.buying_mode === 1 ? <div className="menu-price">{"€ " + item?.total_amount.toFixed(2)}</div> : <div className="menu-price">{"Pts. " + item?.point_amount}</div>}
                            </div>
                          );
                        })}
                      </div>
                      {item?.shipping_charge_amount !== 0 && (
                        <div className="delivery-section">
                          <div className="icon">
                            <i className="icon-cycle1"></i>
                          </div>
                          <div className="text-part">
                            <div className="text">delivery Charges</div>
                            {/* <div className='sub-text'>Tax</div> */}
                          </div>
                          <div className="charges">€{item?.shipping_charge_amount.toFixed(2)}</div>
                        </div>
                      )}
                      <div className="total-price">
                        <div className="text">Total Price</div>
                        <div className="items">
                          <span>{item?.order_details?.length}</span> Items
                        </div>
                        <div className="price">{"€" + item?.order_total_amount.toFixed(2)}</div>
                      </div>
                      <div className="payment-action">
                        <Link to={"/order-details/" + item._id}>
                          <button className="pay-btn" type="button">
                            Order Details
                          </button>
                        </Link>
                        <Link to="/menu">
                          <button className="pay-btn" type="button">
                            Order again
                          </button>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            {orderData?.length === 0 && <div style={{ fontWeight: 800 }}>No Orders Found</div>}
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderList;
