import $ from "jquery";
import React, { useEffect, useState } from "react";
import DeliveryDeliver from "./DeliveryDeliver";
import DeliveryPickup from "./DeliveryPickup";
import OrderNewsletter from "./OrderNewsletter";
import { fetchCartList } from "../../../store/cartlist/cartlist";
import { updateCartList } from "../../../store/cartlist/cartlist";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BASE_URL from "../../../config/host";
import { handleQuantityIncreaseDecrease } from "../../../helpers/cart_helper";
import { removeCartApi } from "../../../helpers/cart_helper";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { applyCouponApi } from "../../../helpers/cart_helper";
import { updateApplyCuopon } from "../../../store/apply-cuopon/applyCuopon";
import { getUserTotalPoint } from "../../../helpers/cart_helper";

const DeliveryPlace = () => {
  const dispatch = useDispatch();
  const sessionCartId = localStorage.getItem("sessionCartId");

  const { deliveryCharges } = useSelector((state) => state.getDeliveryCharges);
  const { isSetZipCode } = useSelector((state) => state.getDeliveryCharges);

  const currentDate = new Date();
  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const { cartlist } = useSelector((state) => state.cartList);
  const { cartlistCount } = useSelector((state) => state.cartList);

  const [removeCartData, setRemoveCartData] = useState([]);
  const [isremoveCartData, setisremoveCartData] = useState(false);

  let totalProductQty = 0;
  let totalCartAmtSingle = 0;
  let totalCartAmt = 0;
  let totalPoints = 0;
  let totalPointsSingle = 0;
  let totalPriceProduct = 0;
  let totalPointProduct = 0;
  let totalPointsSingleDis = 0;
  let totalPointsDis = 0;

  //Cart Activity
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };
    dispatch(fetchCartList({ cart_id: sessionCartId }, { headers }));
  }, [dispatch]);

  const delevaryTypeOption = sessionStorage.getItem("delevaryTypeOption");
  const [tempDelevaryTypeOption, setTempDelevaryTypeOption] = useState([]);
  useEffect(() => {
    setTempDelevaryTypeOption(delevaryTypeOption);
  }, [delevaryTypeOption]);

  const handleSetDelevaryOption = (data) => {
    sessionStorage.setItem("delevaryTypeOption", data);
    setTempDelevaryTypeOption(data);
  };

  let FinalDeliveryCharges = 0;
  let showHideDeleverySec = false;
  if (tempDelevaryTypeOption == 1) {
    FinalDeliveryCharges = deliveryCharges && deliveryCharges?.delivery_charge ? deliveryCharges?.delivery_charge : 0;
    showHideDeleverySec = isSetZipCode;
  }

  //GET User Data
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };

  const [addressData, setAddressData] = useState();
  const [paymentMethodData, setPaymentMethod] = useState();
  const [userProfileData, setUserProfileData] = useState();

  const { selectedAddressId } = useSelector((state) => state.getUserSelectedAddress);
  //const { selectedZipCode } = useSelector((state) => state.getUserSelectedAddress);

  useEffect(() => {
    if (tempDelevaryTypeOption == 2) {
      axios
        .post(`${BASE_URL}user-address/default-details`, {}, { headers: headers })
        .then(function (response) {
          setAddressData(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (tempDelevaryTypeOption == 1) {
      axios
        .post(`${BASE_URL}user-address/details`, { id: selectedAddressId }, { headers: headers })
        .then(function (response) {
          if (response.data.status === 200) {
            setAddressData(response.data.data);
          }
          if (response.data.status === 400) {
            setAddressData([]);
          }
        })
        .catch(function (error) {
          console.log(error);
          setAddressData([]);
        });
    }
  }, [tempDelevaryTypeOption]);

  useEffect(() => {
    axios
      .post(`${BASE_URL}payment-method/list`, { status: true }, { headers: headers })
      .then(function (response) {
        setPaymentMethod(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .post(`${BASE_URL}profile-details`, {}, { headers: headers })
      .then(function (response) {
        setUserProfileData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //Revove Cart data
  const removeCartItem = (e, cartId) => {
    setRemoveCartData(cartId);
    $("#removeCartModal").modal("show");
  };

  const confirmRemoveCart = async (e) => {
    setisremoveCartData(true);
    const returnCartData = await removeCartApi(removeCartData);
    if (returnCartData.data.status === 400) {
      toast.error(returnCartData.data.message, { theme: "colored" });
      setRemoveCartData("");
      setisremoveCartData(false);
    }
    if (returnCartData.data.status === 200 || returnCartData.data.status === 201) {
      toast.success(returnCartData.data.message, { theme: "colored" });
      const tempCartList = cartlist.filter((data) => data._id !== removeCartData);
      const tempCartData = {
        data: tempCartList,
        count: tempCartList.length,
      };
      dispatch(updateCartList(tempCartData));
      setRemoveCartData("");
      setisremoveCartData(false);
      $("#removeCartModal").modal("hide");
    }
  };

  //Cuopon Apply
  const { cuoponDiscountAmount } = useSelector((state) => state.getUpdateApplyCuopon);
  const { appliedCuoponCode } = useSelector((state) => state.getUpdateApplyCuopon);
  const { isAppliedCuopon } = useSelector((state) => state.getUpdateApplyCuopon);

  const [cuoponCode, setCuoponCode] = useState();
  const [cuoponDiscountAmountLocal, setCuoponDiscountAmountLocal] = useState(0);

  useEffect(() => {
    setCuoponDiscountAmountLocal(cuoponDiscountAmount);
    setCuoponCode(appliedCuoponCode);
  }, [cuoponDiscountAmount, appliedCuoponCode]);

  const applyCoupon = async (cuoponCode, totalCartAmt) => {
    const applyCuoponData = await applyCouponApi(cuoponCode, totalCartAmt);
    if (applyCuoponData.status === 200 || applyCuoponData.status === 201) {
      setCuoponDiscountAmountLocal(applyCuoponData.discount_amount);
      const tempData = {
        cuoponDiscountAmount: applyCuoponData.discount_amount,
        appliedCuoponCode: applyCuoponData.data.code,
        isAppliedCuopon: true,
      };
      dispatch(updateApplyCuopon(tempData));
      toast.success(applyCuoponData.message, { theme: "colored" });
    } else {
      toast.error(applyCuoponData.message, { theme: "colored" });
    }
  };

  const dismissCoupon = () => {
    const tempData = {
      cuoponDiscountAmount: 0,
      appliedCuoponCode: "",
      isAppliedCuopon: false,
    };
    dispatch(updateApplyCuopon(tempData));
    setCuoponDiscountAmountLocal(cuoponDiscountAmount);
    setCuoponCode(appliedCuoponCode);
  };

  //Apply Points
  const { settinglist } = useSelector((state) => state.settings);
  const [userTotalPoint, setUserTotalPoint] = useState(0);
  useEffect(() => {
    const reciveData = async () => {
      const userTotalPoint = await getUserTotalPoint();
      if (userTotalPoint.status === 200 || userTotalPoint.status === 201) {
        setUserTotalPoint(userTotalPoint?.total_point);
      }
    };
    reciveData();
  }, []);

  let pointPrice = 0;
  if (cartlist?.length > 0) {
    cartlist.map((item, index) => {
      if (item?.buying_mode === 2) {
        totalPointsSingleDis = item?.quantity * item?.product_details?.price_point;
        totalPointsDis = totalPointsSingleDis + totalPoints;
      }
    });

    const balancePoint = userTotalPoint - totalPointsDis;
    if (userTotalPoint < balancePoint) {
      pointPrice = balancePoint / settinglist?.point_per_euro;
    }
  }

  const handleUserPointChange = (event) => {
    // if (event.target.checked) {
    //   console.log('');
    // } else {
    //   console.log('');
    // }
    // setIsSubscribed(current => !current);
  };

  return (
    <>
      <section className="delivery-order-section">
        <div className="container">
          <div className="row">
            <div className="delivery-order">
              <div className="delivery-order-part">
                <div className="order-part-delivery">
                  <div className="title">Your Data</div>
                  <div className="inner-data-part">
                    {/* <DeliveryOrderOptions /> */}
                    <div className="delivery-options">
                      <button
                        type="button"
                        className={tempDelevaryTypeOption == 2 ? "option active" : "option"}
                        onClick={(e) => {
                          handleSetDelevaryOption(2);
                        }}
                      >
                        Pick up
                      </button>
                      <button
                        type="button"
                        className={tempDelevaryTypeOption == 1 ? "option active" : "option"}
                        onClick={(e) => {
                          handleSetDelevaryOption(1);
                        }}
                      >
                        Delivery
                      </button>
                    </div>
                    {tempDelevaryTypeOption == 1 && (
                      <DeliveryDeliver
                        getData={{
                          userProfileData,
                          addressData,
                          paymentMethodData,
                        }}
                      />
                    )}
                    {tempDelevaryTypeOption == 2 && (
                      <DeliveryPickup
                        getData={{
                          userProfileData,
                          addressData,
                          paymentMethodData,
                        }}
                      />
                    )}
                  </div>
                </div>
                <OrderNewsletter />
              </div>
              <div className="bill-section">
                <div className="bill-title">Order</div>
                <div className="bill-date">
                  <span>{weekday[currentDate.getDay()]}</span>{" "}
                  <span>
                    {currentDate.getDate()}/{currentDate.getMonth() + 1}/{currentDate.getFullYear()}
                  </span>{" "}
                  <span>
                    {currentDate.getHours()}:{currentDate.getMinutes()}
                  </span>
                </div>

                <div className="bill-list">
                  {cartlist?.length > 0 ? (
                    cartlist?.length &&
                    cartlist.map((item, index) => {
                      if (item?.buying_mode === 1) {
                        totalPriceProduct += 1;
                        if (item?.product_details?.sale_price > 0) {
                          totalCartAmtSingle = item?.quantity * item?.product_details?.sale_price;
                        } else {
                          totalCartAmtSingle = item?.quantity * item?.product_details?.regular_price;
                        }
                        totalCartAmt = totalCartAmtSingle + totalCartAmt;
                      }

                      if (item?.buying_mode === 2) {
                        totalPointProduct += 1;
                        totalPointsSingle = item?.quantity * item?.product_details?.price_point;
                        totalPoints = totalPointsSingle + totalPoints;
                      }

                      return (
                        <div className="bill-item" key={item._id}>
                          <div className="menu-name">
                            <div className="text">{item?.product_details?.title}</div>
                            {item?.spice_level && <div className="sub-text">{item?.spice_level?.title}</div>}
                          </div>
                          <div className="menu-item-number">
                            <button type="button" className="btn-action" onClick={() => handleQuantityIncreaseDecrease(item._id, item?.quantity, item?.product_details?._id, dispatch, cartlist, 1)}>
                              <i className="fa-solid fa-fw fa-minus"></i>
                            </button>
                            <input type="text" className="input-style" value={item?.quantity} readOnly={true} />
                            <button
                              type="button"
                              className="btn-action alt"
                              onClick={() => handleQuantityIncreaseDecrease(item._id, item?.quantity, item?.product_details?._id, dispatch, cartlist, 2)}
                            >
                              <i className="fa-solid fa-fw fa-plus-large"></i>
                            </button>
                          </div>
                          <div className="menu-price">
                            {item?.buying_mode === 1
                              ? "€" + (item?.product_details?.sale_price > 0)
                                ? item?.product_details?.sale_price.toFixed(2)
                                : item?.product_details?.regular_price.toFixed(2)
                              : "Pts. " + item?.product_details?.price_point}
                          </div>

                          <button className="remove-btn" type="button" onClick={(e) => removeCartItem(e, item?._id)}>
                            <i className="fa-regular fa-xmark"></i>
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <span className="empty-cart">Cart is Empty</span>
                  )}
                </div>

                {cartlistCount !== 0 && (
                  <div className="delivery-section">
                    <div className="text-part">
                      <div className="text">Total</div>
                    </div>

                    <div className="items">
                      <span>{cartlistCount}</span> Items
                    </div>

                    <div className="charges">
                      {totalCartAmt && totalCartAmt > 0 ? "€" + totalCartAmt.toFixed(2) : ""}
                      {totalCartAmt && totalPoints && totalPoints > 0 ? " + " : ""}
                      {totalPoints && totalPoints > 0 ? "Pts" + totalPoints : ""}
                    </div>
                  </div>
                )}

                {showHideDeleverySec && cartlistCount !== 0 && (
                  <div className="delivery-section">
                    <div className="icon">
                      <i className="icon-cycle1"></i>
                    </div>
                    <div className="text-part">
                      <div className="text">delivery Charges</div>
                    </div>
                    <div className="charges">€{deliveryCharges && deliveryCharges?.delivery_charge ? deliveryCharges?.delivery_charge.toFixed(2) : "00.00"}</div>
                  </div>
                )}

                {cartlistCount !== 0 && totalCartAmt > 0 && (
                  <div className="coupon-section">
                    <div className="icon">
                      <i className="fa-light fa-ticket"></i>
                    </div>
                    {isAppliedCuopon && totalCartAmt > 0 ? (
                      <div className="content-wrap">
                        <div className="text-part">Applied Cuopon : {cuoponCode}</div>
                        <div className="charges">{totalCartAmt >= cuoponDiscountAmountLocal ? `- €${cuoponDiscountAmountLocal.toFixed(2)}` : `- €${totalCartAmt.toFixed(2)}`}</div>
                        <div className="button-wrap">
                          <button className="action-btn" type="button" onClick={(e) => dismissCoupon(e)}>
                            <i class="fa-regular fa-xmark"></i>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="content-wrap">
                        <div className="input-wrap">
                          <input className="form-control" onChange={(e) => setCuoponCode(e.target.value)} placeholder="Coupon Code"></input>
                          <button className="apply-btn" type="button" onClick={(e) => applyCoupon(cuoponCode, totalCartAmt)}>
                            Apply
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {totalPointProduct && totalPointProduct > 0 ? (
                  <div className="apply-Point-section">
                    <div className="custom-radio">
                      <input type="checkbox" className="custom-radio-input" name="checkbox" checked={true} value={userTotalPoint} onClick={handleUserPointChange} />
                      <label className="custom-radio-label">
                        <div className="text">
                          <span>Use your points</span>
                          <span>{userTotalPoint}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {cartlistCount > 0 ? (
                  <div className="total-price">
                    <div className="text">Total Price</div>
                    <div className="price">
                      {FinalDeliveryCharges || (totalCartAmt && totalCartAmt > 0)
                        ? totalCartAmt >= cuoponDiscountAmountLocal
                          ? "€" + (totalCartAmt + FinalDeliveryCharges - cuoponDiscountAmountLocal).toFixed(2)
                          : "€" + FinalDeliveryCharges.toFixed(2)
                        : ""}
                      {(totalCartAmt || FinalDeliveryCharges) && totalPoints && totalPoints > 0 ? " + " : ""}
                      {totalPoints && totalPoints > 0 ? "Pts" + totalPoints : ""}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal custom-alert-modal fade" id="removeCartModal" data-backdrop="static" role="dialog" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ready to remove the cart item?
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">Select "Remove" below if you are ready to remove your cart data.</div>
            <div className="modal-footer">
              <button type="button" className="cnf-btn" data-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="cnf-btn alt" onClick={confirmRemoveCart} draggable={isremoveCartData}>
                {isremoveCartData && <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>}
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryPlace;
