import React from 'react';
import './Css/CareerApply.css'
import ApplyBanner from './Components/ApplyBanner';
import ApplyForm from './Components/ApplyForm';

const CareerApply = () => {
  return (
    <>
      <ApplyBanner/>
      <ApplyForm/>
    </>
  );
};

export default CareerApply;