import React from 'react';
import { Navigate } from 'react-router-dom';
import './Css/DeliveryRegistration.css'
import DeliveryProgress from '../DeliveryMethod/Components/DeliveryProgress';
import DeliverRegistrationForm from './Components/DeliverRegistrationForm';
import DeliveryRegistrationBanner from './Components/DeliveryRegistrationBanner';

const DeliveryRegistration = () => {
  
  if(localStorage.getItem('token')){
    return (
      <Navigate to='/cart' replace={true} />
      );
  }else{
    return (
      <>
        <DeliveryRegistrationBanner/>
        <DeliveryProgress/>
        <DeliverRegistrationForm/>
      </>
    );
  }
};

export default DeliveryRegistration;