import React, { useEffect, useState } from "react";
// import PointsDonationItem from "./PointsDonationItem";
import PointsOrganizationDetails from "./PointsOrganizationDetails";
import BASE_URL from "../../../config/host";
import axios from "axios";

const PointsDonation = () => {
  const [orgData, setOrgProductData] = useState();
  const [orgId, setOrgId] = useState();
  const [details, setDetails] = useState({});
  const [userId, setUserId] = useState();
  const [existingPoint, setExistingPoint] = useState();

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };

    axios
      .post(`${BASE_URL}profile-details`, {}, { headers: headers })
      .then(function (response) {
        setUserId(response.data.data._id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };

    axios
      .post(BASE_URL + "total-point", {}, { headers: headers })
      .then(function (response) {
        setExistingPoint(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(`${BASE_URL}profile-details`, {}, { headers: headers })
      .then(function (response) {
        setUserId(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(BASE_URL + "organization/list", { status: 1 }, { headers: headers })
      .then(function (response) {
        setOrgProductData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        BASE_URL + "organization/details",
        { id: orgId },
        { headers: headers }
      )
      .then(function (response) {
        setDetails(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [orgId]);

  const handleClick = (val) => {
    setOrgId(val);
  };

  return (
    <>
      <div
        className="modal modal-addons fade"
        id="addonsModal"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <PointsOrganizationDetails
              data={details}
              usrId={userId}
              existingPoint={existingPoint}
            />
          </div>
        </div>
      </div>

      <section className="points-donation-section">
        <div className="container">
          <h2 className="donation-title">
            Points <span>Donation</span>
          </h2>
          <div className="donation-organization-part">
            <div className="donation-list">
              {orgData &&
                orgData.map((item, i) => {
                  return (
                    <div
                      className="points-donation-item"
                      key={i}
                      data-toggle="modal"
                      data-target="#addonsModal"
                    >
                      <div
                        className="points-donation-inner"
                        onClick={() => handleClick(item._id)}
                      >
                        <div className="menu-image">
                          <img src={item?.image} alt="" />
                        </div>

                        <div className="description">
                          <div className="content-item">
                            <ul className="list">
                              <li className="item-text">
                                <div className="icon">
                                  <i className="fa-light fa-circle-arrow-right"></i>
                                </div>
                                <div className="text">{item?.description}</div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="name-organization">{item?.title}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      {/* <PointsOrganizationDetails /> */}

      {/* <section className='points-donation-section'>
        <div className='container'>
          <div className='donation-organization-part'>
            <div className='donation-list'>
              <PointsDonationItem />

              <PointsDonationItem />

              <PointsDonationItem />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default PointsDonation;
