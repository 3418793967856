import { useState, useEffect, useContext } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import BASE_URL from '../../../config/host';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import { useRef } from 'react';
import "flatpickr/dist/themes/material_green.css";
import { updateCartList } from "../../../store/cartlist/cartlist";
import { useSelector } from "react-redux";
import { fetchDeliveryCharges } from '../../../store/delivery-charges/deliveryCharges';
import { updateUserSelectedAddress } from '../../../store/set-user-selected-address/userSelectedAddress';
import { updateApplyCuopon } from '../../../store/apply-cuopon/applyCuopon';

import { SocketContext } from "../../../socket/socketio";


const DeliveryDeliver = ({getData}) => {
  const socket = useContext(SocketContext);
  const history = useNavigate();
  const dispatch = useDispatch();
  const fp = useRef(null);

  const { cartlistCount } = useSelector((state) => state.cartList);
  if(cartlistCount < 1){
    history('/cart');
  }

  const { appliedCuoponCode } = useSelector((state) => state.getUpdateApplyCuopon);

  const [prickUpTimeDataset, setPrickUpTimeDataset] = useState([]);
  useEffect(() => {
      axios.post(`${BASE_URL}take-of-time/list`,{status:true, limit:9999999, sortByField:"ordering", sortByValue : 1})
      .then(function (response) {
        if(response.data.status === 200 || response.data.status === 201) {
          setPrickUpTimeDataset(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },[]);


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [house_no, setHouse_no] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [residence, setResidence] = useState('');
  const [kitchenNote, setKitchenNote] = useState('');
  const [riderNote, setRiderNote] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [pickupTimeDisplay, setPickupTimeDisplay] = useState('');
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [orderDate, setOrderDate] = useState(false); //
  const [oneTimeCheck, setOneTimeCheck] = useState(true);
  const [asap, setAsap] = useState(false);


  const delevaryTypeOption = sessionStorage.getItem("delevaryTypeOption");
  const sessionCartId = localStorage.getItem('sessionCartId');

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  }

  //const { selectedAddressId } = useSelector((state) => state.getUserSelectedAddress);
  const { selectedZipCode } = useSelector((state) => state.getUserSelectedAddress);
  const { isSetZipCode } = useSelector((state) => state.getDeliveryCharges);



  // const [tempDelevaryTypeOption, setTempDelevaryTypeOption] = useState([])
  // useEffect(() => {
  //   setTempDelevaryTypeOption(delevaryTypeOption);
  // },[delevaryTypeOption])

  const [isCreating, setCreating] = useState(false)
  useEffect(() => {
    setName(getData?.userProfileData?.name ? getData?.userProfileData?.name : '')
    setEmail(getData?.userProfileData?.email ? getData?.userProfileData?.email : '')
    setPhone(getData?.userProfileData?.phone ? getData?.userProfileData?.phone : '')
    setAddress(getData?.addressData?.address ? getData?.addressData?.address : '')
    setHouse_no(getData?.addressData?.house_no ? getData?.addressData?.house_no : '')
    if(selectedZipCode){
      setZipcode(selectedZipCode?selectedZipCode:'')
    }else{
      if(oneTimeCheck){
        setZipcode(getData?.addressData?.zipcode ? getData?.addressData?.zipcode : '')
      }
    }
    
  },[getData]);


  //Change Between Delivery & pickup.
  const handlePaymenyMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };


  const handleprickUpTime = (e) => {
    setPickupTime(e);
    setPickupTimeDisplay(e)
  }

  //Set As default Address
  const handleDefaultAddress = (event) => {
    setDefaultAddress((current) => !current);
  };

  //Check Delivery Avaiable or Not.
  const handleCheckZipCode = (data)=>{
    setOneTimeCheck(false)
    let tempData = {}
    tempData = {
      selectedAddressId : '',
      selectedZipCode : data,
    }
    dispatch(updateUserSelectedAddress(tempData));

    dispatch(
      fetchDeliveryCharges({ zip_code: data }, { headers })
    );
  }



  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);
    let OrderDateTime = '';
    if(pickupTime && orderDate){
      OrderDateTime = orderDate && orderDate?.e[0].toDateString();
      OrderDateTime = new Date(`${OrderDateTime} ${pickupTime}`).toISOString()
    }

    const data = {
          cart_id : sessionCartId,
          order_type : Number(delevaryTypeOption),
          name : name,
          email:email,
          phone : phone,
          address_id : getData?.addressData?._id,
          address: address,
          house_no: house_no,
          as_soon_as_possible: asap,
          zipcode: zipcode,
          residence : residence,
          kitchenNote : kitchenNote,
          riderNote : riderNote,
          payment_method_id : paymentMethod,
          pickup_time : OrderDateTime,
          is_daufelt : defaultAddress,
          coupon_code : appliedCuoponCode,
    }
    axios
      .post(`${BASE_URL}checkout/complete`, {
        ...data
      }, {headers: headers})
      .then(function (response) {
        console.log(response);
        if (response.data.status === 400) {
           toast.error(response.data.message, { theme: "colored" });
           setCreating(false);
        }
        if (response.data.status === 200 || response.data.status === 201) {
          socket.emit('orderNotification', {
            _id: response.data.data._id,
            order_id: response.data.data.order_id,
          });
          toast.success(response.data.message, { theme: "colored" });
          const tempCartData = {
            data : [],
            count : 0,
          }
          dispatch(updateCartList(tempCartData));
          const tempData = {
            cuoponDiscountAmount : 0,
            appliedCuoponCode : '',
            isAppliedCuopon : false,
          }
          dispatch(updateApplyCuopon(tempData));
          setCreating(false);
          console.log(response.data.data.payment,typeof response.data.data.payment );
          setTimeout(() => {
            if(response.data.data.payment === null) {
              window.location.href = `/order-details/${response.data.data._id}`;
            } else {
              window.location.href = response.data.data.payment._links.checkout.href;
            }
          },'2000')
        }else{
          setCreating(false);
        }
      })
      .catch(function (error) {
        toast.error(error.message, { theme: "colored" });
      });
  };



  const alertHandle = () =>{
    toast.error('Enter delevery address.', { theme: "colored" });
  }





  return (
    <>
      <ToastContainer theme="colored" />
      <div className='inner-option-view delivery'>
        <form className='form-content' onSubmit={handleSubmit}>
          <div className='form-row'>
            <div className='col-md-12'>
              <div className="input-style">
                <div className='form-label'>Name*</div>
                <input type="text" className="form-control" placeholder="Enter Name" name="name" value={name}  onChange={e => setName(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="input-style">
            <div className='form-label'>E-mail*</div>
            <input type="email" className="form-control" placeholder="Enter Email" name="email" value={email}  onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="input-style">
            <div className='form-label'>Phone*</div>
            <input type="number" className="form-control" placeholder="Enter Phone" name="phone" value={phone}  onChange={e => setPhone(e.target.value)} />
          </div>

          <div className="input-style">
            {/* <div className='form-label'>Payment Method*</div> */}
          <div className='shipping-item'>
          <div className="custom-radio">
                  <input 
                  type="checkbox" 
                  className="custom-radio-input" 
                  name="address" 
                  value={asap}
                  onChange={(e) => {console.log(e.target.checked); setAsap((val) => (!val))}} 
                  />
                  <label className="custom-radio-label">
                    <div className='text'>ASAP</div>
                  </label>
                </div>
          </div>
          </div>
          {!asap  && <><div className='form-inner-title'>To take off</div>

          <div className='note-section'>
            Note: you can only order in advance. Choose the desired date:
          </div>
          <div className="input-style">
            <div className='form-label'>Date</div>
            <Flatpickr
              type="text"
              ref={fp}
              name="date"
              className="form-control"
              placeholder="Select Date"
              onChange={(e) => {
                setOrderDate({ e });
              }}
              options={{
                dateFormat: "d-m-Y",
                minDate: new Date().fp_incr(1),
                maxDate: new Date().fp_incr(14),
              }}
            />
          </div>

          <div className="input-style">
            <div className='form-label'>Time</div>
            <div className="dropdown time-dropdown">
              <button className="dropdown-toggle" type="button" data-toggle="dropdown" id="dispalyTime">{pickupTimeDisplay ? pickupTimeDisplay : 'Time'}</button>
              <div className="dropdown-menu">
                {/* <div className="dropdown-time">
                  <input type="text" className="form-control" placeholder="Search" />
                </div> */}
                <div className="dropdown-scroll" id="time-category">
                  {
                    prickUpTimeDataset && prickUpTimeDataset.map((item, i)=>(
                      <button type="button" className="dropdown-item" onClick={(e)=>handleprickUpTime(item?.time)}>{item?.time}</button>
                    ))
                  }
                </div>
              </div>
            </div>
          </div></>
}



          <div className='form-inner-title'>Your Address</div>
          <div className='form-row'>
            <div className='col-md-6'>
              <div className="input-style">
                <div className='form-label'>Postal Code</div>
                <input type="text" className="form-control" name="zipcode" placeholder='Enter Postal Code' value={zipcode}  onChange={e => {
                    setZipcode(e.target.value);
                    handleCheckZipCode(e.target.value);
                }} />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="input-style">
                <div className='form-label'>House Number</div>
                <input type="text" className="form-control" name="house_no" placeholder='Enter House Number' value={house_no}  onChange={e => setHouse_no(e.target.value)} />
              </div>
            </div>
            {
              (isSetZipCode)
              ?('')
              :(<div className="note-section">Delivery is not available in this postal code.</div>)
            }
          </div>

          



          <div className="input-style">
            <div className='form-label'>Street</div>
            <input type="text" className="form-control" name="address" placeholder='Enter Street' value={address}  onChange={e => setAddress(e.target.value)} />
          </div>

          <div className="input-style">
            <div className='form-label'>Residence</div>
            <input type="text" className="form-control" name="residence" placeholder='Enter Residence' value={residence}  onChange={e => setResidence(e.target.value)} />
          </div>

          <div className='shipping-item'>
            <div className="custom-checkbox">
            <input 
              type="checkbox" 
              className="custom-checkbox-input" 
              name="address"
              onChange={handleDefaultAddress}  
            />
              <label className="custom-checkbox-label">
                <div className='text'>Save my (address) details for your next order.</div></label>
            </div>
          </div>

          <div className='form-inner-title'>Payment</div>
          <div className="input-style">
            <div className='form-label'>Payment Method*</div>

            {getData?.paymentMethodData && getData?.paymentMethodData.map((item, i)=> (
              <div className='shipping-item' key={item?._id}>
                <div className="custom-radio">
                  <input 
                  type="radio" 
                  className="custom-radio-input" 
                  name="address" 
                  value={item?._id}
                  onChange={handlePaymenyMethodChange} 
                  />
                  <label className="custom-radio-label">
                    <div className='text'>{item?.title}</div>
                    <div className="icon"><i className={item?.icon}></i></div>
                  </label>
                </div>
              </div>
            ))}



          </div>


          <div className="input-style">
            <div className='form-label'>Notes for Kitchen</div>
            <textarea type="text" rows="12" className="form-control Comments" placeholder="Notes for Kitchen" onChange={e => setKitchenNote(e.target.value)}></textarea>
          </div>

          <div className="input-style">
            <div className='form-label'>Notes for Rider</div>
            <textarea type="text" rows="12" className="form-control Comments" placeholder="Notes for Rider" onChange={e => setRiderNote(e.target.value)}></textarea>
          </div>
          
          <div className='form-submit'>
              {
                (isSetZipCode)
                ?(<button 
                  type='submit' 
                  className='btn-submit' 
                  // data-toggle="modal" 
                  // data-target="#orderModal" 
                  disabled = {isCreating}
                  >
                  {isCreating && (
                    <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
                  )}
                  Place an order
                  </button>)
                  :
                  (<button  type='button' onClick={alertHandle} className='btn-submit' disabled={isSetZipCode} >  Place an order </button>)
              }

            
            {/* <button 
            type='submit' 
            className='btn-submit' 
            // data-toggle="modal" 
            // data-target="#orderModal" 
            disabled = {isCreating}
            >
            {isCreating && (
              <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
            )}
            Place an order
            </button> */}
          </div>
        </form>
        <div className='delivery-image'>
          <img src='assets/img/deliver-man-alt.svg' alt='' />
        </div>
      </div>
    </>
  );
};

export default DeliveryDeliver;
