import { useState, useEffect } from "react";
function Clock() {
  const [date, setDate] = useState(new Date());
  function refreshClock() {
    setDate(new Date());
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
    const options = {
        weekday: "short",
        year: "numeric",
        day: "numeric",
        month: "2-digit",
        hour :"numeric",
        minute:"2-digit",
        //second : "2-digit",                     
    };
  return <span>{date.toLocaleString('en-GB', options)}</span>;
}

export default Clock;
