import React from 'react';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

const BlogItem = ({ blog }) => {
  const createMarkup = () => {
    return { __html: blog?.title };
  }

  const createdAtTime = blog
    ? new Date(blog.created_at).toLocaleString('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    : '';

  return (
    <section className='blog-banner'>
      <div className='container'>
        <div className='item'>
          <Link to={`/blog-details/${blog?.alias}`} className='blog-item'>
            <div className='blog-image'>
              <img src={blog?.image} alt={blog?.title} />
            </div>
            <div className='blog-info'>
              <div className='blog-data'>
                <div className='date'>
                  <div className='icon'>
                    <i className='fa-regular fa-calendar'></i>
                  </div>
                  <div className='text'>
                    {moment(new Date(createdAtTime)).format('DD MMM YYYY')}
                  </div>
                </div>
                <div className='author'>
                  <div className='icon'>
                    <i className='fa-regular fa-circle-user'></i>
                  </div>
                  <div className='text'>{blog?.created_by_name.name}</div>
                </div>
                <div className='tag'>
                  <div className='tag-text'>{blog?.category?.name}</div>
                </div>
              </div>
              <div className='blog-details'>
                <h3 className='blog-text'>
                  <div dangerouslySetInnerHTML={createMarkup()} />
                </h3>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BlogItem;
