import React,{ useState } from 'react';
import { Link } from 'react-router-dom';



const CareerJobListItem = ({id, title, jobType, locationType, city, state, country, description, slug}) => {
  
  const [active, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!active);
  }

  return (
    <>
      <div className={`careeritem ${active ? ' active' : ''}`} onClick={toggleClass}>
            <div className='career-item-title'>
              <h6 className='text'>{title}</h6>
            </div>
      
            <div className='career-content'>
              <div className='content'>
                <div className='title-part'>
                  <h6 className='content-title'>JOB TYPE</h6>
                </div>
                <div className='descriptions'>
                  <p>{jobType}, {locationType}</p>
                </div>
                <div className='title-part'>
                  <h6 className='content-title'>JOB LOCATION</h6>
                </div>
                <div className='descriptions'>
                  <p>{city}, {state}, {country}</p>
                </div>

                <div className='title-part'>
                  <h6 className='content-title'>DESCRIPTION</h6>
                </div>
                <div className='descriptions'>
                  <div dangerouslySetInnerHTML={{__html: description}}/>
                </div>
              <div className='apply-action'>
                <Link to={"/career-apply/" + slug} className='apply'>Apply Now</Link>
              </div>
            </div>
          </div>
          </div>
        
      {/* <div className='careeritem active'>
        <div className='career-item-title'>
          <h6 className='text'>Nullam quis justo id justo dapibus varius non eget libero.</h6>
        </div>
        <div className='career-content'>
          <div className='content'>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='description-list'>
              <ul className='points'>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
              </ul>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>interest?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='apply-action'>
              <Link to='/career-apply' className='apply'>Apply Now</Link>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CareerJobListItem;