import React from 'react';
import { NavLink } from 'react-router-dom';



const AccountTab = () => {
  return (
    <>
      <section className='account-tab-section'>
        <div className='container'>
          <div className='tab-list'>
            <div className='tab-item'>
              <NavLink to="/account" className="tab-link active">My Account</NavLink>
            </div>
            <div className='tab-item'>
              <NavLink to="/edit-account" className="tab-link">Edit Profile</NavLink>
            </div>
            <div className='tab-item'>
              <NavLink to="/terms-conditions" className="tab-link">Terms & Conditions</NavLink>
            </div>
            <div className='tab-item'>
              <NavLink to='/' onClick={() => localStorage.removeItem('token')} className="tab-link">Log Out</NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountTab;