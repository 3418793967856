
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";

export const faqSlice = createSlice({
    name: 'faqs',
    loading: false,
    initialState: {
        faqlist: []
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.faqlist ='';
            //console.log(action.payload);
        },
        listReceived: (state, action)=>{
            state.loading = false;
            state.faqlist = action.payload.data?action.payload.data:[];
        }
    }
});


export const {listReceived,listRequested ,listRequestFailed } = faqSlice.actions;
//console.log(body);
export const fetchFaqs = (body) => apiCallBegan({
    url: '/faq/list',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type
 
});














