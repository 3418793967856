import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchVissions } from "../../../store/Mission-Vission/Vission";
import { fetchMissions } from "../../../store/Mission-Vission/Mission";
const SecMission = () => {
  const dispatch = useDispatch();
  const { vissionlist } = useSelector((state) => state.vissions);
  const { missionlist } = useSelector((state) => state.missions);




  useEffect(() => {
    dispatch(
      fetchVissions({
        status: true,
        limit: 9,
        type: 1,
        sortByField: 'ordering',
        sortByValue: 1
      })
    );
    dispatch(
      fetchMissions({
        status: true,
        limit: 9,
        type: 2,
        sortByField: 'ordering',
        sortByValue: 1
      })
    );
  }, [dispatch]);
  return (
    <>
      <section className="section-mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="vision-part">
                <div className="section-header">
                  <h2 className="section-title">
                    Our <span>vision</span>
                  </h2>
                </div>
                <div className="vision-list">
                  {vissionlist.length > 0 &&
                    vissionlist.map((item, index) => {
                      return (
                        <div className="vision-item" key={index}>
                          <div className="item-header">
                            <div className="item-icon">
                              {/* <i className={"icon-fresh-product"}></i> */}
                              <img src={item.image} />
                            </div>
                            <h5 className="item-title">{item.title}</h5>
                          </div>
                          <div className="item-content">
                            <p>{item.description}</p>
                          </div>
                        </div>
                      );
                    })}{" "}
                </div>
                <div className="vision-img-section">
                  <div className="man-img">
                    <img src="assets/img/our-vision-man.svg" alt="" />
                  </div>
                  <div className="grass-img">
                    <img src="assets/img/grass.svg" alt="" />
                  </div>
                  <div className="responsive-image">
                    <img src="assets/img/responsive-man.svg" alt="" />

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="mission-part">
                <div className="section-header">
                  <h2 className="section-title">
                    Our <span>mission</span>
                  </h2>
                </div>
                <div className="mission-list">
                  {missionlist.length > 0 &&
                    missionlist.map((item, index) => {
                      return (
                        <div className="mission-item" key={index}>
                          <div className="misstion-tag">
                            <div className="text">{item.title}</div>
                          </div>
                          <div className="mission-description">
                            <p>{item.description}</p>
                          </div>
                        </div>
                      );
                    })}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMission;
