import React, {useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { fetchFunfacts } from "../../../store/FunFact/funfact";
import { fetchPages } from "../../../store/page/page";

const SecFunfacts = () => {
  const dispatch = useDispatch();
  const { funfactlist } = useSelector((state) => state.funfacts);
  const [pageDetails, setpageDetails] = useState(null);

  useEffect(() => {
    dispatch(
      fetchFunfacts({
        status: true,
        sortByField: 'ordering',
        sortByValue: 1
      })
    );
  }, [dispatch]);


  useEffect(() => {
    const pageDetails = async() =>{
      try {
        const data = await fetchPages({slug: "fun-facts", status: true,})
        setpageDetails(data)
      } catch (error) {
        console.log(error);
      }
    }
    pageDetails();
  }, [dispatch]);


  return (
    <section className="fun-facts-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-12">
            <div className="fun-facts-img">
              {/* <img src="assets/img/fun-fact-lady.svg" alt="" /> */}
              <img src={pageDetails?.image} alt="" />
            </div>
            <div  className="fun-facts-responsive">
            <img src="assets/img/responsive-fun-facts.svg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12">
            <div className="fun-fact-tag">
              <img src="assets/img/star-shape.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="fun-fact-boxes">
          <Swiper
            slidesPerView={1}
            navigation
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              480: {
                slidesPerView: 1,
                grid: {
                  rows: 1,
                },
              },
              768: {
                spaceBetween: 50,
                slidesPerView: 2,
                grid: {
                  rows: 1,
                },
              },
              990: {
                spaceBetween: 50,
                slidesPerView: 3,
                grid: {
                  rows: 2,
                },
              },
              1400: {
                spaceBetween: 50,
                slidesPerView: 3,
                grid: {
                  rows: 2,
                },
              },
            }}
            modules={[Grid, Navigation, Pagination]}
            className="fact-slider"
          >

            {funfactlist.length > 0 &&
              funfactlist.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className={`fact-item fact-0${(index+1) % 6 === 0 ? 6 : (index+1) % 6}`}>
                      <div className="item-inner">
                        <div className="fact-title">{item.title}</div>
                        <div className="fact-description">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default SecFunfacts;
