
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";

export const aboutbannerSlice = createSlice({
    name: 'aboutbanners',
    loading: false,
    initialState: {
        aboutbannerlist: []
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.aboutbannerlist ='';
            //console.log(action.payload);
        },
        listReceived: (state, action)=>{
            state.loading = false;
            state.aboutbannerlist = action.payload.data?action.payload.data:[];
        }
    }
});


export const {listReceived,listRequested ,listRequestFailed } = aboutbannerSlice.actions;
//console.log(body);
export const fetchAboutbanners = (body) => apiCallBegan({
    url: '/banner/list',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type
 
});














