import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import BASE_URL from '../../../config/host'

export default function PointHistory() {

  const [transactionHistory, setTransactionHistory] = useState()
  const [totalPoint, setTotalPoint] = useState()
  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }

    axios.post(BASE_URL + 'point/list',{ },{headers: headers} )
    .then(function (response) {
      setTransactionHistory(response.data.data);
      //console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });

  axios.post(BASE_URL + 'total-point',{ },{headers: headers} )
    .then(function (response) {
      setTotalPoint(response.data);
      //console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
  }, [])
    
  console.log(transactionHistory);

  return (
    <>
      <section className='page-banner-section'>
          <div className='container'>
            <div className='banner-section'>
              <div className='breadcrumb-section'>
                <div className='link'>
                  <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                </div>
                <div className='seperator'>/</div>
                <div className='link'>
                  <NavLink to="/account" className="breadcrumb-link">Account</NavLink>
                </div>
                <div className='seperator'>/</div>
                <div className='link'>
                  <div className="breadcrumb-link"> Point Transaction History</div>
                </div>
              </div>
              <div className='page-title'>
                <h1 className='title'> Point Transaction History</h1>
              </div>
            </div>
          </div>
      </section>
      <section>
      <div className="container">
        <div style={{ fontSize: 20, fontWeight: 500, textAlign: "end", marginBottom:20 }} >Avaialble Balance: {totalPoint?.total_point} Points</div>
      {transactionHistory?.length === 0 && <div style={{fontSize: 20,textAlign: "center", marginTop: "15px"}}>No Transactions Done</div>}
        {transactionHistory?.length > 0 && 
        <table className="w-100">
            <thead style={{background:'#F14E2D', color: 'white', height: '40px'}}>
                <th colSpan={3} style={{textAlign: 'center'}}>Point History</th>
            </thead>
          <tbody>
            {transactionHistory?.map((val, key) => {
              return (
                <tr key={key} style={{ borderBottom: '0.5px solid gray' }}>
                  <td style={{padding:8, textAlign: 'center'}}>{moment(new Date(val?.created_at)).format("ddd, DD/MM/YYYY, hh:mm a")}</td>
                  <td style={{padding:8, textAlign: 'center', textTransform: 'capitalize'}}>{val?.point_type} {val.status === 1 ? <span style={{marginLeft:5, fontWeight: '600'}}> €{val.order_amount.toFixed(2)}</span> : val.status === 2 ? <span style={{ marginLeft:5, fontWeight: '600'}}> €{val.order_amount.toFixed(2)}</span> : <span style={{marginLeft:5, fontWeight: '600'}}> €{val.order_amount.toFixed(2)}</span>}</td>
                  <td style={{padding:8, textAlign: 'center'}}>{val.status === 1 ? <div style={{color: 'green', fontWeight: '600'}}>+{val.point} Points</div> : val.status === 2 ? <div style={{color: 'red', fontWeight: '600'}}>-{val.point} Points</div> : <div style={{color: 'orange', fontWeight: '600'}}>Pending {val.point} Points</div>}</td>
                </tr>
              )
            })}
          </tbody>
        </table>}
        </div>
      </section>
    </>
  )
}
