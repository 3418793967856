import React from 'react';
import './Css/Deals.css';
import Newsletter from '../../Components/Newsletter';
import DealsBanner from './Components/DealsBanner';
import DealsSilder from './Components/DealsSilder';
import NewItems from './Components/NewItems';

const Deals = () => {
  return (
    <>
      <DealsBanner />
      <section className='deals-section'>
        <div className='deals-part'>
          <div className='deals-content'>
            <div className='section-header'>
              <h2 className='section-title'>Deals<span> For You</span></h2>
            </div>
            <div className='descriptions'>
              Discount is a long established fact that a reader will beDiscount is a long established fact that a reader will be
            </div>
            <div className='deals-img'>
              <img src='assets/img/deals-girl.svg' alt='' />
            </div>
          </div>
          <div className='deals-slider-section'>
            <DealsSilder />
          </div>
        </div>

      </section>
      <NewItems/>
      <Newsletter />
    </>
  );
};

export default Deals;