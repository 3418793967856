import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchBanners } from "../../../store/home/banner";
import ClipLoader from "react-spinners/ClipLoader";
import LazyLoad from "react-lazy-load";

const HomeBanner = () => {
  const dispatch = useDispatch();
  const { bannerlist, loading } = useSelector((state) => state.banners);
  useEffect(() => {
    dispatch(fetchBanners({ slug: "home" }));
  }, [dispatch]);

  function createMarkup() {
    return { __html: bannerlist?.description };
  }

  function createTitleMarkup() {
    return { __html: bannerlist?.title };
  }


  return (
    <>
<section className='section-banner'>
      <div className='container'>
        {!loading && 
        <div className='row align-items-center justify-content-between'>
          <div className='col-lg-6'>
            <div className='banner-content'>
              <h1 className='banner-title' dangerouslySetInnerHTML={createTitleMarkup()}></h1>
              <div className='banner-text'>
             <div dangerouslySetInnerHTML={createMarkup()} />
              </div>
              <div className='banner-image'>
              <LazyLoad>
                <img src={bannerlist?.image} alt=''/>
              </LazyLoad>
              </div>
              <div className='banner-action'>
                <Link to="/menu" className="btn-action">
                  <div className='icon'><i className='icon-buy'></i></div>
                  <div className='title'>Order Now</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        }
        {loading &&  <div className='row align-items-center justify-content-center'><ClipLoader size={100} color={"#F14E2D"}/></div> }
      </div>
    </section>
    </>
  );
};

export default HomeBanner;
