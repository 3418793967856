
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";

export const settingSlice = createSlice({
    name: 'settings',
    loading: false,
    initialState: {
        settinglist: []
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.settinglist ='';
            //console.log(action.payload);
        },
        listReceived: (state, action)=>{
            state.loading = false;
            state.settinglist = action.payload.data?action.payload.data:[];
        }
    }
});


export const {listReceived,listRequested ,listRequestFailed } = settingSlice.actions;
//console.log(body);
export const fetchSettings = (body) => apiCallBegan({
    url: '/site-setting/details',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type
 
});














