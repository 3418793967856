import BASE_URL from "../config/host";
import { updateCartList } from "../store/cartlist/cartlist";
import axios from 'axios';

const updateCartDataApi = async (id,cartId, productId, quantity)=>{
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
    try{
        const res = await axios.post(BASE_URL + 'cart/add',{id : id, cart_id: cartId, product_id : productId, quantity:quantity},{headers: headers})
        return res.data;
    }catch(error){
        return error;
    }
}

//Cart Update
export const handleQuantityIncreaseDecrease = async (id,quantity, product_id, dispatch, cartlist, type) => {
    const sessionCartId = localStorage.getItem('sessionCartId');
    const updated = [];
    let modifyQuantity = 0;
    if(type === 1){
        modifyQuantity = quantity-1;
    }else{
        modifyQuantity = quantity+1;
    }
    let returnData;
    if(type == 1){
        if(quantity > 1){
            returnData = await updateCartDataApi(id,sessionCartId,product_id,modifyQuantity);
            if(returnData.status === 200 || returnData.statusstatus === 201) {
                cartlist.forEach((item,index) => {
                    if(item._id == id){
                        updated.push({...cartlist[index], quantity:modifyQuantity});
                    }else{
                        updated.push({...cartlist[index]})
                    }
                });

                const tempCartData = {
                    data : updated,
                    count : updated.length
                }
                dispatch(updateCartList(tempCartData));
            }
            
        }
    }else{
        returnData = await updateCartDataApi(id,sessionCartId,product_id,modifyQuantity);
        if(returnData.status === 200 || returnData.status === 201) {
            cartlist.forEach((item,index) => {
                if(item._id == id){
                    updated.push({...cartlist[index], quantity:modifyQuantity});
                }else{
                    updated.push({...cartlist[index]})
                }
            });
            const tempCartData = {
                data : updated,
                count : updated.length
            }
            dispatch(updateCartList(tempCartData));
        }
    }
}

//Added To cart
export const addedToCartApi = async (productId, itemType, buyingMode, storeId=null, productSpiceLevel = null, addedOnArray = null)=>{
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
    const sessionCartId = localStorage.getItem('sessionCartId');
    try{
        const res = await axios.post(`${BASE_URL}cart/add`, {cart_id : sessionCartId, product_id: productId, item_type: itemType, buying_mode : buyingMode, store_id:storeId, spice_level_id:productSpiceLevel, addon_product_id : addedOnArray,}, {headers:headers})
        return res
    }catch(error){
        return error
    }
}

//Remove Caer
export const removeCartApi = async (removeCartData)=>{
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
    const sessionCartId = localStorage.getItem('sessionCartId');
    try{
        const res = await axios.post(`${BASE_URL}cart/remove`, {cart_id : sessionCartId, id: removeCartData}, {headers:headers})
        return res
    }catch(error){
        return error
    }
}


export const applyCouponApi = async (cuoponCode=null, totalCartAmt=null)=>{
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
    const sessionCartId = localStorage.getItem('sessionCartId');
    try{
        const response = await axios.post(`${BASE_URL}apply-coupon`, {cart_id : sessionCartId, coupon_code: cuoponCode,total_amount:totalCartAmt}, {headers:headers})
        return response.data
    }catch(error){
        return error
    }
}


export const getUserTotalPoint = async ()=>{
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
    const sessionCartId = localStorage.getItem('sessionCartId');
    try{
        const response = await axios.post(`${BASE_URL}total-point`, {}, {headers:headers})
        return response.data
    }catch(error){
        return error
    }
}