import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchBanners } from "../../../store/home/banner";
import { useEffect } from 'react';

const BannerCatering = () => {

  
  const dispatch = useDispatch();
  const { bannerlist, loading } = useSelector((state) => state.banners);
  useEffect(() => {
    dispatch(fetchBanners({ slug: "caterings" }));
  }, [dispatch]);


  return (
    <section className='page-banner-section'>
      <div className='container'>
        <div className='banner-section'>
          <div className='breadcrumb-section'>
            <div className='link'>
              <NavLink to="/" className="breadcrumb-link">Home</NavLink>
            </div>
            <div className='seperator'>/</div>
            <div className='link'>
              <div className="breadcrumb-link"><div dangerouslySetInnerHTML={{__html:bannerlist?.title}}/></div>
            </div>
          </div>
          <div className='page-title'>
            <h1 className='title'><div dangerouslySetInnerHTML={{__html:bannerlist?.title}}/></h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerCatering;