import React from 'react';
import FaqListItem from './FaqListItem';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchFaqs } from '../../../store/contact-detail/faq'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const FaqList = () => {
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState("");
    const {faqlist } = useSelector(state => state.faqs);

    const param = useParams();

    console.log(param);

    useEffect(() => {
      dispatch(fetchFaqs({ status: true, keyword: keyword, category: 'general' , sortByField: "ordering", sortByValue: 1}))
    }, [dispatch, keyword]);

    return (
      <>

          {faqlist.length > 0 ? faqlist.map((item, i)=> {

          return (
          <FaqListItem
          key={item._id}
          Question={item.question}
          Answer={item.answer}
          />

          )}) : <h2 className='title'>No Data</h2>}
      </>   
  );
};

export default FaqList;