import React from 'react';
//import React, { useState, useEffect } from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import BASE_URL from '../../../config/host';
import "react-toastify/dist/ReactToastify.css";

const EditAddress = ({data}) => {
    const [addressId, setAddressId] = useState(false)
    const [isRequestSending1, setRequestSending1] = useState(false)
    const [addrsTypeEdit, setAddrsTypeEdit] = useState('home')
    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [houseNo, setHouseNo] = useState('')

    useEffect(() => {
      setAddressId(data?._id)
      setAddrsTypeEdit(data?.address_type)
      setAddress(data?.address)
      setZip(data?.zipcode)
      setHouseNo(data?.house_no)
    },[data]);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setRequestSending1(true);
        const data = {
              id:addressId,
              address_type: addrsTypeEdit,
              address: address,
              zipcode: zip,
              house_no: houseNo,
              // city: "Kolkata",
              // state_code: "WB",
              // phone: "1234567890"
        }

        axios
          .post(`${BASE_URL}user-address/edit`, {
            ...data
          }, {headers: headers})
          .then(function (response) {
            if (response.data.status === 400) {
               toast.error(response.data.message, { theme: "colored" });
               setRequestSending1(false);
            }
            if (response.data.status === 200 || response.data.status === 201) {
              toast.success(response.data.message, { theme: "colored" });
              setTimeout(() => {
                window.location.reload();
              },'2000')
            }else{
              setRequestSending1(false);
            }
          })
          .catch(function (error) {
            toast.error(error.message, { theme: "colored" });
          });
      };

    return (
        <>
          <ToastContainer theme="colored" />
          <div className="modal modal-newaddress fade" id="editonsModal" data-backdrop="static" data-keyboard="false" >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Address</h5>
                </div>
                <div className="modal-body">
                  <div className='address-inner'>
                      <div className='address-option'>
                          <div className='address-item'>
                              <div className="custom-radio">
                                  <input type="radio"
                                  className="custom-radio-input"
                                  name="home1"
                                  defaultChecked
                                  value="home"
                                  checked={addrsTypeEdit == 'home'}
                                  onChange={(e) => setAddrsTypeEdit(e.target.value)}
                                  />
                                  <label className="custom-radio-label">
                                  <div className='text'>Home</div></label>
                              </div>
                          </div>
                          <div className='address-item'>
                              <div className="custom-radio">
                                  <input type="radio"
                                  className="custom-radio-input"
                                  name="home1" 
                                  value="office"
                                  checked={addrsTypeEdit == 'office'}
                                  onChange={(e) => setAddrsTypeEdit(e.target.value)}
                                  />
                                  <label className="custom-radio-label">
                                  <div className='text'>Office</div></label>
                              </div>
                          </div>
                          <div className='address-item'>
                              <div className="custom-radio">
                                  <input type="radio"
                                  className="custom-radio-input"
                                  name="home1"
                                  value="work"
                                  checked={addrsTypeEdit == 'work'}
                                  onChange={(e) => setAddrsTypeEdit(e.target.value)}
                                  />
                                  <label className="custom-radio-label">
                                  <div className='text'>Work</div></label>
                              </div>
                          </div>
                          <div className='address-item'>
                              <div className="custom-radio">
                                  <input type="radio"
                                  className="custom-radio-input"
                                  name="home1"
                                  value="other"
                                  checked={addrsTypeEdit == 'other'}
                                  onChange={(e) => setAddrsTypeEdit(e.target.value)}
                                  />
                                  <label className="custom-radio-label">
                                  <div className='text'>Other</div></label>
                              </div>
                          </div>
                      </div>
                      <div className='address-form'>
                          <form className='form-content' onSubmit={handleSubmit}>
                              <div className='form-row'>
                              <div className='col-md-12 mb-4'>
                                  <div className="input-style">
                                  <div className='form-label'>Address</div>
                                  <input type="text" className="form-control" placeholder="Home, Office, Apartment  etc" value={address} onChange={(e) => setAddress(e.target.value)}/>
                                  </div>
                              </div>    
                              <div className='col-md-6'>
                                  <div className="input-style">
                                  <div className='form-label'>Postal Code</div>
                                  <input type="text" className="form-control" placeholder="Postal Code" value={zip} onChange={(e) => setZip(e.target.value)}/>
                                  </div>
                              </div>
                              <div className='col-md-6'>
                                  <div className="input-style">
                                  <div className='form-label'>House No</div>
                                  <input type="text" className="form-control" placeholder='House No' value={houseNo} onChange={(e) => setHouseNo(e.target.value)}/>
                                  </div>
                              </div>
                              </div>
                              <div className="modal-footer">
                                <div className='save-action'>
                                    <button 
                                      className='save-btn' 
                                      type='submit'
                                      disabled = {isRequestSending1}
                                      >
                                      {isRequestSending1 && (
                                          <i className="fa-solid fa-circle-notch fa-spin mr-2 fa-1x"></i>
                                      )}
                                      Save Changes
                                      </button>
                                    <button type='button' className='cancel-btn' data-dismiss="modal" aria-label="close">Cancel</button>
                                </div>
                              </div>
                          </form>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
};

export default EditAddress;
