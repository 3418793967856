import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchJobs } from "../../../store/career/job";
import { useEffect } from "react";
import CareerJobListItem from "./CareerJobListItem";
import axios from "axios";
import BASE_URL from "../../../config/host";

const CareerJobList = () => {
  const [keyWord, setKeyWord] = useState("");
  const [tmpkeyWord, setTmpKeyWord] = useState("");
  const [jobState, setJobState] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const dispatch = useDispatch();
  const { joblist } = useSelector((state) => state.jobs);
  useEffect(() => {
    dispatch(
      fetchJobs({
        status: true,
        keyword: jobTitle,
        state: jobState,
        sortByField: "ordering",
        sortByValue: 1,
      })
    );
  }, [dispatch, keyWord, jobState, jobTitle]);



  const [joblistcopy, setjoblistcopy] = useState([])
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };

    axios
      .post(BASE_URL + "job/list", { status: true, sortByField: "ordering", sortByValue: 1, keyword: keyWord }, { headers: headers })
      .then(function (response) {
        setjoblistcopy(response.data.data);
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [keyWord]);

  //For unique value
  const uniqueState = joblistcopy ? [...new Set(joblistcopy?.map((item) => item.state))] : null;
  const uniqueTitle = joblistcopy
    ? [...new Set(joblistcopy?.map((item) => item.title))]
    : null;

  console.log(joblistcopy);

  // const [activeClass, setActiveClass] = useState(false);

  return (
    <>
      <div className="search-part-career">
        <div className="location-search">
          <div className="dropdown location-dropdown">
            <button
              className="dropdown-toggle"
              type="button"
              data-toggle="dropdown"
            >
              {jobState === "" ? "All" : jobState}
            </button>
            <div className="dropdown-menu">
              {/* <div className="dropdown-search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={jobState}
                  onChange={(e) => setJobState(e.target.value)}
                />
              </div> */}
              <div className="dropdown-scroll" id="search-location">
              <button
                type="button"
                className="dropdown-item"
                data-value=""
                data-name='all'
                onClick={() => setJobState("")}
              >All</button>
                {uniqueState?.length > 0 &&
                  uniqueState.map((item, i) => {
                    return (
                      <button
                        type="button"
                        className="dropdown-item"
                        data-value={item}
                        data-name={item}
                        key={item}
                        onClick={() => setJobState(item)}
                      >
                        {item}
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="job-search">
          <div className="dropdown job-dropdown">
            <button
              className="dropdown-toggle"
              type="button"
              data-toggle="dropdown"
            >
              {jobTitle === "" ? "All" : jobTitle}
            </button>
            <div className="dropdown-menu">
              <div className="dropdown-search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={tmpkeyWord || keyWord}
                  onChange={(e) => {setKeyWord(e.target.value); setTmpKeyWord(e.target.value)}}
                />
              </div>
              <div className="dropdown-scroll" id="search-job">
              <button
                type="button"
                className="dropdown-item"
                data-value=""
                data-name='all'
                onClick={() => setJobTitle("")}
              >All</button>
                {uniqueTitle?.length > 0 &&
                  uniqueTitle.map((item, i) => {
                    return (
                      <button
                        type="button"
                        className="dropdown-item"
                        data-value={item}
                        data-name={item}
                        key={item}
                        onClick={() => {setJobTitle(item); setTmpKeyWord(item)}}
                      >
                        {item}
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="career-list-part">
        {joblist.length > 0 &&
          joblist.map((item, i) => {
            return (
              <CareerJobListItem
                key={item._id}
                id={item._id}
                title={item.title}
                jobType={item.job_type}
                locationType={item.location_type}
                city={item.city}
                state={item.state}
                country={item.country}
                description={item.description}
                slug={item.slug}
              />
              //   <div className="careeritem">
              //     <div className='career-item-title'>
              //       <h6 className='text'>{item.title}</h6>
              //     </div>
              //     <div className='career-content'>
              //       <div className='content'>
              //         <div className='title-part'>
              //           <h6 className='content-title'>JOB TYPE</h6>
              //         </div>
              //         <div className='descriptions'>
              //           <p>{item.job_type}, {item.location_type}</p>
              //         </div>
              //         <div className='title-part'>
              //           <h6 className='content-title'>JOB LOCATION</h6>
              //         </div>
              //         <div className='descriptions'>
              //           <p>{item.city}, {item.state}, {item.country}</p>
              //         </div>

              //         <div className='title-part'>
              //           <h6 className='content-title'>DESCRIPTION</h6>
              //         </div>
              //         <div className='descriptions'>
              //           <div dangerouslySetInnerHTML={{__html: item?.description}}/>
              //         </div>
              //       <div className='apply-action'>
              //         <Link to='/career-apply' className='apply'>Apply Now</Link>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            );
          })}
      </div>
      {/* <div className='career-list-part'>
      <div className='careeritem '>
        <div className='career-item-title'>
          <h6 className='text'>Nullam quis justo id justo dapibus varius non eget libero.</h6>
        </div>
        <div className='career-content'>
          <div className='content'>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='description-list'>
              <ul className='points'>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
              </ul>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='apply-action'>
              <Link to='/career-apply' className='apply'>Apply Now</Link>
            </div>
          </div>
        </div>
      </div>
        <CareerJobListItem/>
        <div className='careeritem '>
        <div className='career-item-title'>
          <h6 className='text'>Nullam quis justo id justo dapibus varius non eget libero.</h6>
        </div>
        <div className='career-content'>
          <div className='content'>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='description-list'>
              <ul className='points'>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
              </ul>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='apply-action'>
              <Link to='/career-apply' className='apply'>Apply Now</Link>
            </div>
          </div>
        </div>
      </div>
      <div className='careeritem '>
        <div className='career-item-title'>
          <h6 className='text'>Nullam quis justo id justo dapibus varius non eget libero.</h6>
        </div>
        <div className='career-content'>
          <div className='content'>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='description-list'>
              <ul className='points'>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
              </ul>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='apply-action'>
              <Link to='/career-apply' className='apply'>Apply Now</Link>
            </div>
          </div>
        </div>
      </div>
      <div className='careeritem '>
        <div className='career-item-title'>
          <h6 className='text'>Nullam quis justo id justo dapibus varius non eget libero.</h6>
        </div>
        <div className='career-content'>
          <div className='content'>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='description-list'>
              <ul className='points'>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
                <li className='list-point'>Nullam quis justo id justo dapibus varius non eget libero.</li>
              </ul>
            </div>
            <div className='title-part'>
              <h6 className='content-title'>WHAT ARE YOU GOING TO DO?</h6>
            </div>
            <div className='descriptions'>
              <p>Nullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero. Etiam eu sagittis justoNullam quis justo id justo dapibus varius non eget libero.</p>
            </div>
            <div className='apply-action'>
              <Link to='/career-apply' className='apply'>Apply Now</Link>
            </div>
          </div>
        </div>
      </div>
      </div> */}
    </>
  );
};

export default CareerJobList;
