import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSettings } from "../store/shared/setting";
import { fetchOpeningHour } from "../store/shared/openinghour";
import { useEffect } from "react";
import Moment from "react-moment";

const DefaultFooter = () => {
  const dispatch = useDispatch();
  const { settinglist } = useSelector((state) => state.settings);
  const { openinghourlist } = useSelector((state) => state.openinghour);

  useEffect(() => {
    dispatch(fetchSettings({ id: "624c1d79ac364e5ef3796d76" }));
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(fetchOpeningHour({ sortByField: "ordering", sortByValue : 1}));
  }, [dispatch]);
  return (
    <footer>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-12">
                  <div className="footer-content">
                    <div className="brand">
                      <Link to="/" className="logo">
                        <img src={settinglist?.logo} alt="Logo" />
                      </Link>
                    </div>
                    <div className="footer-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                          settinglist?.description > 50 ? settinglist?.description?.slice(0, 165) + "...": settinglist?.description,
                        }}
                      />
                      {settinglist?.description && <Link to={"/about"} style={{ color: "#f14e2d" }}>
                        Learn More
                      </Link>}
                    </div>
                    <div className="footer-social">
                      {settinglist.facebook_link && <a
                        href={settinglist.facebook_link}
                        target="_blank"
                        className="social-item"
                        rel="noreferrer"
                      >
                        <i className="icon-facebook"></i>
                      </a>}
                      {settinglist.Linkedin_link && <a
                        href={settinglist.Linkedin_link}
                        target="_blank"
                        className="social-item"
                        rel="noreferrer"
                      >
                        <i className="icon-instagram"></i>
                      </a>}
                      {settinglist.Instagram_link && <a
                        href={settinglist.Instagram_link}
                        target="_blank"
                        className="social-item"
                        rel="noreferrer"
                      >
                        <i className="icon-instagram"></i>
                      </a>}
                      {settinglist.twitter_link && <a
                        href={settinglist.twitter_link}
                        target="_blank"
                        className="social-item"
                        rel="noreferrer"
                      >
                        <i className="icon-twitter"></i>
                      </a>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row">
                <div className="col-7 col-md-4">
                  <div className="footer-content">
                    <h2 className="footer-title">
                      Contact<span></span>
                    </h2>
                    <div className="footer-contact">
                      <a className="item">
                        <div className="icon">
                          <i className="icon-phone"></i>
                        </div>
                        <div className="title">{settinglist.phone}</div>
                      </a>
                      <a
                        href={`mailto:${settinglist.email}`}
                        className="item"
                      >
                        <div className="icon">
                          <i className="icon-email"></i>
                        </div>
                        <div className="title">{settinglist.email}</div>
                      </a>
                      <div className="item">
                        <div className="icon">
                          <i className="icon-map-marker"></i>
                        </div>
                        <div className="title">{settinglist.address}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-3">
                  <div className="footer-content">
                    <h2 className="footer-title">
                      Quick Links<span></span>
                    </h2>
                    <div className="footer-links">
                      <Link to="/career" className="link-item">
                        Career
                      </Link>
                      <Link to="/privacy-policy" className="link-item">
                        Privacy policy
                      </Link>
                      <Link to="/blog" className="link-item">
                        Blog
                      </Link>
                      <Link to="/about" className="link-item">
                        About us
                      </Link>
                      <Link to="/contact" className="link-item">
                        Contact us
                      </Link>
                      <Link to="/faqs" className="link-item">
                        FAQs
                      </Link>
                      <Link to="/terms-conditions" className="link-item">
                        Terms & Conditions
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-5">
                  <div className="footer-content">
                    <h2 className="footer-title">
                      Opening Hours<span></span>
                    </h2>
                    <div className="footer-time">
                      {openinghourlist.length > 0 &&
                        openinghourlist.map((item, index) => {
                        return (
                          <div className="item"  key={index}>
                            <span className="item-label text-capitalize">{item?.day_name}</span>
                            <span className="item-separator"></span>
                            {item?.is_closed ?
                              <span className="item-data text-highlight">Closing Day</span>
                            :
                              <span className="item-data">
                                <Moment format="h:mm A">
                                {item?.start_time}
                                </Moment>
                                -
                                <Moment format="h:mm A">
                                {item?.end_time}
                                </Moment>
                              </span>
                            }
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="copyright">{settinglist.copyright}</div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="links">
                <div className="link-item">
                  <Link to="/terms-conditions" className="link">
                    Terms & Conditions
                  </Link>
                </div>
                <div className="link-item">
                  <Link to="/privacy-policy" className="link">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default DefaultFooter;
