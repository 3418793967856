import React from 'react';

const ReferSection = () => {
  return (
    <>
      <section className='refer-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-6 col-md-6 col-sm-12'>
              <div className='refer-part'>
                <div className='text-refer'><span>Great Friendship</span> Comes With <span>Greate Rewards!</span></div>
                <div className='refer-form'>
                  <form className='form-content'>
                    <div className='form-row'>
                      <div className='col-md-6'>
                        <div className="input-style">
                          <div className='form-label'>Friend’s Name</div>
                          <input type="text" className="form-control" placeholder="Full Name" defaultValue='Mike' />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="input-style">
                          <div className='form-label'>Friend’s Phone </div>
                          <input type="text" className="form-control" placeholder="Phone Number" defaultValue='+91 123456789' />
                        </div>
                      </div>
                    </div>
                    <div className="input-style">
                      <div className='form-label'>Enter friend’s email address</div>
                      <input type="text" className="form-control" placeholder="Email" defaultValue='freed223@mail.com' />
                    </div>
                    <div className='form-action'>
                      <button className='btn-refer' type='button'>Refer A Friend</button>
                    </div>
                  </form>
                  <div className='or-text'>or</div>
                  <div className='link-share-section'>
                    <div className='link-copy'>
                      <div className='form-label'>Share Your Referal Link</div>
                      <div className='copy-part'>
                        <input type="text" className="form-control" placeholder="Refer Link" defaultValue='https://www.dutchdabbawala.nl/sjfhskasdha'/>
                        <div className="input-group-append">
                          <button type='button' className="input-group-text">Copy</button>
                        </div>
                      </div>
                    </div>
                    <div className='link-share-section'>
                      <button type='button' className='btn-share'>Share Via Whatsapp
                        <div className='icon'><img src='assets/img/whatsapp.svg' alt='' /></div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 col-md-6 col-sm-12'>
              <div className='refer-img'>
                <img src='assets/img/refer.svg' alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferSection;