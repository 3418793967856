import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BASE_URL from '../../config/host'
import BLogComment from './Components/BLogComment';
import BlogDetailsBanner from './Components/BlogDetailsBanner';
import BlogDetailsContent from './Components/BlogDetailsContent';
import Newsletter from '../../Components/Newsletter';
import './Css/BlogDetails.css'
import RelatedBlogs from './Components/RelatedBlogs';

const BlogDetails = () => {
  const [blogDetail, setBlogDetail] = useState(null)
  const { alias } = useParams()
  const [limit] = useState(2)

  useEffect(() => {
    const ac = new AbortController()

    const loadBlogDetail = async () => {
      try {
        const res = await fetch(`${BASE_URL}blog/details`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({ alias }),
          signal: ac.signal
        })

        const resData = await res.json()

        if (resData.status === 200) {
          setBlogDetail(resData.data)
        }
      } catch (err) {
        console.log(err.message)
      }
    }

    loadBlogDetail()

    return () => ac.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias])

  return (
    <>
      <BlogDetailsBanner />
      { blogDetail && <BlogDetailsContent detail={blogDetail && blogDetail} /> }
      <BLogComment blog_id={blogDetail && blogDetail?._id} limit={limit}/>
      <RelatedBlogs baseUrl={BASE_URL} detail={blogDetail && blogDetail} />
      <Newsletter />
    </>
  );
};

export default BlogDetails;