import React from 'react';
import { NavLink } from 'react-router-dom';

const BlogDetailsBanner = () => {
  return (
    <>
      <section className='page-banner-section blog-details'>
        <div className='container'>
          <div className='banner-section'>
            <div className='banner-back'>
              <NavLink to="/blog" className="back"><div className='icon'><i className="fa-solid fa-chevron-left"></i></div></NavLink>
            </div>
            <div className='breadcrumb-section'>
              <div className='link'>
                <NavLink to="/" className="breadcrumb-link">Home</NavLink>
              </div>
              <div className='seperator'>/</div>
              <div className='link'>
                <div className="breadcrumb-link">Blog</div>
              </div>
            </div>
            <div className='page-title'>
              <h1 className='title'>Blog</h1>
            </div>
          </div>
          <div className='row'>
              <div className='col-lg-12'>
                <div className='back-action'>
                  <NavLink to="/blog" className="back-btn"><div className='icon'><i className="fa-solid fa-chevron-left"></i> Back </div></NavLink>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetailsBanner;