
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";
import axios from "axios";
export const blogcommentSlice = createSlice({
    name: 'blogcomments',
    loading: false,
    initialState: {
        blogcommentlist: [],
        blogcommentCount: 0
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.blogcommentlist ='';
        },
        listReceived: (state, action)=>{
            state.loading = false;
            state.blogcommentlist = action.payload.data?action.payload.data:[];
            state.blogcommentCount = action.payload.count || 0
        }
    }
});


export const {listReceived,listRequested ,listRequestFailed } = blogcommentSlice.actions;
export const fetchBlogcomments = (body)=> apiCallBegan({
    url: '/blog_Comment/list',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type
 
});

const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  }


export const Createblogcomment = async(body)=> {
    const res = await axios.post(BASE_URL+'/blog_Comment/create', body, {headers: headers} )
     return res.data;
};
//profile-details
export const deleteBlogcomment = async(body)=> {
    const res = await axios.post(BASE_URL+'/blog_Comment/remove', body, {headers: headers} )
     return res.data;
};

export const profileData = async() => {
    const res = await axios.post(BASE_URL+'/profile-details', {}, {headers: headers} )
     return res.data;
};













