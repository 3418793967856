
// import { createSlice } from "@reduxjs/toolkit";
// import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";
import axios from "axios";

export const fetchPages = async(body)=> {
    const res = await axios.post(BASE_URL+'/page/details', body )
     return res.data.data;
  
};
















