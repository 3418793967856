import React from 'react';
import MilestoneBanner from './Components/MilestoneBanner';
import MilestoneSection from './Components/MilestoneSection';
import './Css/Milestone.css';
import Newsletter from '../../Components/Newsletter';
import ChallengesSection from './Components/ChallengesSection';
const Milestone = () => {
    return (
        <>
            <MilestoneBanner/>
            <MilestoneSection/>
            <ChallengesSection/>
            <Newsletter/>
        </>
    );
};

export default Milestone;