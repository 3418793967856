import React from 'react';
import { Link, NavLink } from 'react-router-dom';
const ContactDetailsBanner = () => {
    return (
        <>
    <section className='page-banner-section'>
        <div className='container'>
          <div className='banner-section' style={{ position: "relative"}}>
          
            <div className='banner-back'>
              <Link to="/faqs" className="back"><div className='icon'><i className="fa-solid fa-chevron-left"></i></div></Link>
            </div> 
            <div className='breadcrumb-section'>
              <div className='link'>
                <NavLink to="/" className="breadcrumb-link">Home</NavLink>
              </div>
              <div className='seperator'>/</div>
              <div className='link'>
                <div className="breadcrumb-link">Contact Us</div>
              </div>
            </div>
            <div className='page-title'>
              <h1 className='title'>Contact Us</h1>
            </div>
          </div>
        </div>
      </section>
        </>
    );
};

export default ContactDetailsBanner;