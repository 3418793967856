import React from 'react';

const Location = () => {
  return (
    <>
      <section className='location-section'>
        <div className='container'>
          <h2 className='location-title'>Our <span>Location</span></h2>
          <div className='location-part'>
            <div className='location-map'>
              <img src='assets/img/map.jpg' alt='' />
            </div>
            <div className='location-details'>
              <div className='location-box'>
                <div className='current-location'>
                  <div className='icon'><span></span>
                  </div>
                  <div className='text'>
                    My current location
                  </div>
                  <button type='button' className='cancel-btn'>
                    <i className="fa-light fa-fw fa-xmark"></i>
                  </button>
                </div>
                <div className='main-location'>
                  <div className='icon'><span></span>
                  </div>
                  <div className='text'>
                    Dabbawala
                  </div>
                  <button type='button' className='cancel-btn'>
                    <i className="fa-light fa-fw fa-arrow-up-arrow-down"></i>
                  </button>
                </div>
              </div>
              <div className='company-review'>
                <h5 className='title'>DABBA WALLA</h5>
                <h6 className='sub-title'>Food Center</h6>
                <div className='rating'>
                  <div className='rating-num'>4.8</div>
                  <div className="rating-star">
                    <div className="backstar">
                      <i className="fa-light fa-fw fa-star"></i>
                      <i className="fa-light fa-fw fa-star"></i>
                      <i className="fa-light fa-fw fa-star"></i>
                      <i className="fa-light fa-fw fa-star"></i>
                      <i className="fa-light fa-fw fa-star"></i>
                    </div>
                    <div className="frontstar" style={{ width: '70%' }}>
                      <i className="fa-light fa-fw fa-star"></i>
                      <i className="fa-light fa-fw fa-star"></i>
                      <i className="fa-light fa-fw fa-star"></i>
                      <i className="fa-light fa-fw fa-star"></i>
                      <i className="fa-light fa-fw fa-star"></i>
                    </div>
                  </div>
                  <div className='rating-text'>25k reviews</div>
                </div>
              </div>
              <div className='location-search-result'>
                <div className='search-item active'>
                  <div className='icon'><i className="fa-solid  fa-fw fa-person-walking"></i></div>
                  <div className='text'>2 hours 5 min</div>
                </div>
                <div className='search-item'>
                  <div className='icon'><i className="fa-solid fa-fw fa-person-biking"></i></div>
                  <div className='text'>1 hour 10 min</div>
                </div>
                <div className='search-item'>
                  <div className='icon'><i className="fa-solid fa-fw fa-car"></i></div>
                  <div className='text'>30 min</div>
                </div>
                <div className='search-item'>
                  <div className='icon'><i className="fa-solid fa-fw fa-bus-simple"></i></div>
                  <div className='text'>20 min</div>
                </div>
              </div>
              <div className='location-track'>
                <div className='logo'>
                  <img src='assets/img/logo.svg' alt='' />
                </div>
                <div className='text'>Dabba Walla</div>
                <div className='icon'>
                  <i className="fa-solid fa-fw fa-location-arrow"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;