import React from 'react';

const MilestoneSection = () => {
    return (
        <>
            <section className='sec-milestone'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='milestone-inner'>
                                <div className='milestone-item'>
                                    <div className='milestone-content'>
                                        <div className='milestone-title'>Milestone</div>
                                        <div className='milestone-text'>Discount is a long established fact that a reader will beDiscount is a long established fact that a reader will be</div>
                                        <div className='milestone-img'><img src='assets/img/milestone.png' alt='' /></div>
                                    </div>
                                    <div className='level-inner'>
                                        <div className='level-item'>
                                            <div className='level-content'>
                                                <div className='level-list'>
                                                    <div className='list-content'>
                                                            <div className='icon'><i className="fa-solid fa-check"></i></div>
                                                            <div className='text'>Discount Is A Long</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon'><i className="fa-solid fa-check"></i></div>
                                                            <div className='text'>Established Fact That A</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon'><i className="fa-solid fa-check"></i></div>
                                                            <div className='text'>Reader Will Be</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon'><i className="fa-solid fa-check"></i></div>
                                                            <div className='text'>Discount is a long</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon'><i className="fa-solid fa-check"></i></div>
                                                            <div className='text'>Established Fact That A</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon'><i className="fa-solid fa-check"></i></div>
                                                            <div className='text'>Reader Will Be</div>
                                                    </div>
                                                </div>
                                                <div className='level-title'>
                                                        Level <span>01</span>
                                                </div>
                                            </div>
                                            <div className='lock-icon'>
                                                    <img src='assets/img/lock.svg' alt='' />
                                            </div>
                                        </div>
                                        <div className='level-item lock-off'>
                                            <div className='level-content lock-off'>
                                                <div className='level-list'>
                                                    <div className='list-content'>
                                                            <div className='icon lock-off'><i className="fa-solid fa-check"></i></div>
                                                            <div className='text'>Discount Is A Long</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon lock-off'><i className="fa-solid fa-check"></i></div>
                                                            <div className='text'>Established Fact That A</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon lock-off'><i class="fa-regular fa-lock"></i></div>
                                                            <div className='text'>Reader Will Be</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon lock-off'><i class="fa-regular fa-lock"></i></div>
                                                            <div className='text'>Discount is a long</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon lock-off'><i class="fa-regular fa-lock"></i></div>
                                                            <div className='text'>Established Fact That A</div>
                                                    </div>
                                                    <div className='list-content'>
                                                            <div className='icon lock-off'><i class="fa-regular fa-lock"></i></div>
                                                            <div className='text'>Reader Will Be</div>
                                                    </div>
                                                </div>
                                                <div className='level-title'>
                                                        Level <span>02</span>
                                                </div>
                                            </div>
                                            <div className='lock-icon lock-off'>
                                                    <img src='assets/img/lock-2.svg' alt='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MilestoneSection;