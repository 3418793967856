
import { createSlice, createAction } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import  BASE_URL  from "../../config/host";


//export const updateCartList = createAction("updateCartList");
export const deliveryChargeSlice = createSlice({
    name: 'getDeliveryCharges',
    loading: false,
    initialState: {
        deliveryCharges: [],
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.isSetZipCode = false;
            state.deliveryCharges ='';
        },
        listReceived: (state, action)=>{
            state.isSetZipCode = action.payload.data?true:false;
            state.deliveryCharges = action.payload.data?action.payload.data:[];
        }
    },

    // extraReducers: (builder) => {
    // builder
    //     .addCase(updateCartList, (state, action) => {
    //         state.loading = false;
    //         state.cartlist = action.payload.data;
    //         state.cartlistCount = action.payload.count;
    //     })
    // },
});


export const {listReceived,listRequested ,listRequestFailed } = deliveryChargeSlice.actions;
//console.log(body);


export const fetchDeliveryCharges = (body,header)=> apiCallBegan({
    url: '/delivery-zipcode/check-status',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type,
    headers : header
});














