import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import BlogItem from '../../Blog/Components/BlogItem';

const RelatedBlogs = ({ baseUrl, detail }) => {
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  useEffect(() => {
    const ac = new AbortController();

    const loadRelatedBlogs = async (detail) => {
      try {
        const res = await fetch(`${baseUrl}blog/list`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            status: true,
            limit: 10,
            category_id: detail.category._id,
          }),
          signal: ac.signal,
        });

        const resData = await res.json();

        if (resData.status === 200) {
          setRelatedBlogs(resData.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (detail) {
      loadRelatedBlogs(detail)
    }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

 

  return (
    <section className='related-section'>
      <div className='container'>
        <div className='section-header'>
          <h2 className='section-title'>
            Related <span>Blogs</span>
          </h2>
        </div>
        <div className='related-blogs-slider'>
          <Swiper
            slidesPerView={1}
            spaceBetween={60}
            navigation
            modules={[Navigation, Pagination]}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              480: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              990: {
                slidesPerView: 3,
              },
              1460: {
                slidesPerView: 3,
              },
            }}
            className='related-slider'
          >
            {relatedBlogs.map(blog => {
            if(detail._id !== blog._id){
              return(
                <SwiperSlide key={blog._id}>
                  <BlogItem blog={blog} />
                </SwiperSlide>
              )
            }
          }) }
          </Swiper>
          {relatedBlogs.length === 1 && detail._id === relatedBlogs[0]._id && <div className='h3'>No Data</div>}
        </div>
      </div>
    </section>
  );
};

export default RelatedBlogs;
