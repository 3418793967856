import React from "react";
import { Link } from "react-router-dom";
const ErrorPage = () => {
  return (
    <>
      <section className="error-page-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="text responsive">Do Not Cry!</h2>
              <div className="error-image">
                <img src="assets/img/404-lady.svg" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="error-content">
                <div className="text-content">
                  <h2 className="text">Do Not Cry!</h2>
                  <div className="back-action">
                    <Link to="/" type="button" className="btn-back">
                      Back To Home
                    </Link>
                  </div>
                  <div className="description">
                    <p>This Page is doesnt exist or removed! We suggest you back to home</p>
                  </div>
                </div>

                <div className="image-part">
                  <img src="assets/img/404-text.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorPage;
