import axios from "axios";
import React, { useEffect, useState } from "react";
import BASE_URL from "../../../config/host";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const RewardsPointsConversion = () => {
  const [point, setPoint] = useState();
  const [name, setName] = useState();
  const [coupon, setCoupon] = useState();
  const [success, setSuccess] = useState(false);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  useEffect(() => {
    axios
      .post(BASE_URL + "profile-details", {}, { headers: headers })
      .then(function (response) {
        setName(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(BASE_URL + "total-point", {}, { headers: headers })
      .then(function (response) {
        setPoint(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function handleSubmit() {
    axios
      .post(
        BASE_URL + "point-to-coupon-convert",
        { point: point?.total_point },
        { headers: headers }
      )
      .then(function (response) {
        if (response.data.status === 400) {
          toast.error(response.data.message, { theme: "colored" });
        }
        if (response.data.status === 200) {
          toast.success(response.data.message, { theme: "colored" });
          setCoupon(response.data.data);
          setPoint(response.data.data);
          setSuccess(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleCopy() {
    navigator.clipboard.writeText(coupon?.coupon_code);
    toast.success("Copied to clipboard", { theme: "colored" });
  }
  console.log(point?.total_point);
  return (
    <>
      <ToastContainer theme="colored" />
      <section className="point-conversion-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="conversion-content">
                <h2 className="conversion-title">
                  Points <span>Conversion</span>
                </h2>
                <div className="profile-content">
                  <div className="intro">
                    Hi <span>{name?.name},</span> you have
                  </div>
                  <div className="points">
                    <span>{point?.total_point}</span> Points
                  </div>
                </div>
                <div className="link-copy-section">
                  {success && (
                    <div className="link-copy">
                      <div className="mb-3 font-weight-bold">
                        You have gote € {point?.coupon_amount} discount code.
                      </div>
                      <div className="form-label">Discount Code</div>
                      <div className="copy-part">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Refer Link"
                          value={coupon?.coupon_code}
                          readOnly={true}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() => handleCopy()}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {point?.total_point > 0 ? (
                    <div style={{ fontWeight: 400 }}>
                      Convert points to Dicount code by Clicking convert button.
                    </div>
                  ) : (
                    <div className="sub-text">
                      You Don't have enough points{" "}
                    </div>
                  )}
                  <div className="link-copy-section">
                    {point?.total_point > 0 && (
                      <button
                        type="button"
                        className="btn-copy"
                        onClick={handleSubmit}
                      >
                        Convert
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="conversion-image">
                <img src="assets/img/point-conversion.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RewardsPointsConversion;
